import styled from "../../../../providers/ThemeProvider";

export const TitleBoxContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  marginBottom: theme.spacing(4),
  boxSizing: "border-box"
}));

export const StyledTitle = styled.h4({
  fontWeight: 900,
  marginBottom: 0
});
