import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";
import { ChildPie } from "./ChildPie/ChildPie";
import { getOptions } from "./ChildStats.utils";
import React from "react";

export const ChildStats = () => {
  const {
    dataKey: [dataKey],
    timeSpan,
  } = useSlideContext();
  const data = useTimeSpanData(timeSpan, "ChildEnergy")?.[dataKey];
  const option = getOptions(dataKey);

  return data && <ChildPie data={data} height="100%" option={option} />;
};
