import { AverageSitesConsumption } from "../../components/Slides/AverageSitesConsumption/AverageSitesConsumption";
import { SubMeterBreakdown } from "../../components/Slides/SubMeterBreakdown/SubMeterBreakdown";
import { SiteLeaderboards } from "../../components/Slides/SideLeaderboards/SiteLeaderboards";
import { ChildStats } from "../../components/Slides/ChildStats/ChildStats";
import { StaticContent } from "../../components/Slides/StaticContent/StaticContent";
import { PlanetWatch } from "../../components/Slides/PlanetWatch/PlanetWatch";
import GenericGraph from "../../components/Slides/GenericGraph/GenericGraph";
import CO2StatImages from "../../components/Slides/StatImages/StatImages";
import SystemGraph from "../../components/Slides/SystemGraph/SystemGraph";
import FeedTotals from "../../components/_shared/FeedTotals/FeedTotals";
import { CO2Tower } from "../../components/Slides/CO2Tower/CO2Tower";
import { getComponentFromID } from "../SlideUtils/idManipulation";
import { ChartWrap, BottomWrap, Wrap } from "./InputJSON.styles";
import { EnergyFlow } from "../../components/Slides/EnergyFlow";
import HeatMap from "../../components/Slides/HeatMap/HeatMap";
import PVInfo from "../../components/Slides/PVInfo/PVInfo";
import { FactBox } from "../../components/_shared/FactBox";
import Pie from "../../components/Slides/Pie/Pie";
import React from "react";
import { SubMetersAnalysis } from "../../components/Slides/SubMeterAnalysis";
import { SiteEvent } from "../../components/Slides/SiteEvent";
import {
  CampsCarbonCommitment,
  CampsEnergySavingTips,
} from "../../components/Slides/CampsStatic";

const getComponentList = (resourceName, type, json, staticContent) => {
  let titlePart1 = resourceName;
  let componentList = [];

  for (let i = 0; i < json.slides.length; i++) {
    const component =
      json.slides[i].Component || getComponentFromID(json.slides[i].id);

    switch (component) {
      case "campsCarbonCommitment":
        componentList.push({
          id: json.slides[i].id,
          component: <CampsCarbonCommitment />,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "campsEnergySavingTips":
        componentList.push({
          id: json.slides[i].id,
          component: <CampsEnergySavingTips />,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "siteEvent":
        componentList.push({
          id: json.slides[i].id,
          component: <SiteEvent />,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "submetersanalysis":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <Wrap>
              <SubMetersAnalysis />
            </Wrap>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "siteleaderboards":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <Wrap>
              <SiteLeaderboards />
            </Wrap>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "submeters":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <Wrap>
              <SubMeterBreakdown />
            </Wrap>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "planetwatch":
        componentList.push({
          id: json.slides[i].id,
          component: <PlanetWatch />,
          fullWidth: true,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "heatmap":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <Wrap>
              <ChartWrap>
                <HeatMap />
              </ChartWrap>
              <BottomWrap>
                <FeedTotals />
              </BottomWrap>
            </Wrap>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "subschemastats":
        componentList.push({
          id: json.slides[i].id,
          component: <ChildStats />,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "siteStats":
        componentList.push({
          id: json.slides[i].id,
          component: <ChildStats />,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "avgsitesconsumption":
        componentList.push({
          id: json.slides[i].id,
          component: <AverageSitesConsumption />,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "line":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <Wrap>
              <ChartWrap>
                <GenericGraph />
              </ChartWrap>
              <BottomWrap>
                <FeedTotals />
              </BottomWrap>
            </Wrap>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "systemline":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <Wrap>
              <ChartWrap>
                <SystemGraph />
              </ChartWrap>
              <BottomWrap>
                <FeedTotals />
              </BottomWrap>
            </Wrap>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "bar":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <Wrap>
              <ChartWrap>
                <GenericGraph seriesType="bar" />
              </ChartWrap>
              <BottomWrap>
                <FeedTotals />
              </BottomWrap>
            </Wrap>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "piecomp":
        componentList.push({
          id: json.slides[i].id,
          component: <Pie />,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "balls":
        componentList.push({
          id: json.slides[i].id,
          component: <CO2Tower />,
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "statimg":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <Wrap>
              <ChartWrap>
                <CO2StatImages />
              </ChartWrap>
              <BottomWrap>
                <FactBox />
              </BottomWrap>
            </Wrap>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "pvinfo":
        if (type === "sites") {
          componentList.push({
            id: json.slides[i].id,
            component: <PVInfo />,
            title: json.slides[i].title,
            delay: json.slides[i].duration,
          });
        }
        break;
      case "flowgraph":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <div id="cbContainer" style={{ height: "100%", width: "100%" }}>
              <EnergyFlow />
              {/* <FlowGraphContainer /> */}
            </div>
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      case "TempStaticContent":
        componentList.push({
          id: json.slides[i].id,
          component: (
            <StaticContent content={staticContent} title={titlePart1} />
          ),
          title: json.slides[i].title,
          delay: json.slides[i].duration,
        });
        break;
      default: {
        componentList.push({
          id: json.slides[i].id,
          component: <div>Error</div>,
          title: "Error",
          delay: 3,
        });
        break;
      }
    }
  }
  return componentList;
};

export { getComponentList };
