import React, { FunctionComponent } from "react";
import {
  ErrorScreenContainer,
  ErrorScreenHeader,
  ErrorScreenSubHeader,
} from "./ErrorScreen.styles";
import { ThemeProvider } from "../../../providers/ThemeProvider";
import { getErrorContent } from "./ErrorScreen.utils";
import { ErrorScreenProps } from "./ErrorScreen.types";

const ErrorScreen: FunctionComponent<ErrorScreenProps> = ({
  themeName = "energyfootprint",
  code = 500,
}) => {
  return (
    <ThemeProvider>
      <ErrorScreenContainer themeName={themeName}>
        <ErrorScreenHeader variant="h2">Error {code}</ErrorScreenHeader>
        <ErrorScreenSubHeader variant="h6">Oopsie daisy!</ErrorScreenSubHeader>
        {getErrorContent(code)}
      </ErrorScreenContainer>
    </ThemeProvider>
  );
};

export default ErrorScreen;
