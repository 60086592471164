import { AuthenticatedClient } from "./api/ApiAuth/ApiAuth";
import { format, subDays, parse, startOfMonth } from "date-fns";
import {
  OrganisationsApi,
  PlanetWatchApi,
  SchemasApi,
  SitesApi,
  DataApi,
} from "@solarschools/api-sdk-typescript";
import { SlideConfig } from ".";

type RootResourceApi = SitesApi | OrganisationsApi | SchemasApi;

export const getResourceApi = (type: string, key: string) => {
  switch (type) {
    case "sites": {
      return new SitesApi(AuthenticatedClient(key));
    }
    case "organisations": {
      return new OrganisationsApi(AuthenticatedClient(key));
    }
    case "schemas": {
      return new SchemasApi(AuthenticatedClient(key));
    }
    case "data": {
      return new DataApi(AuthenticatedClient(key));
    }
    case "planetwatch": {
      return new PlanetWatchApi(AuthenticatedClient(key));
    }
    default: {
      return new SitesApi(AuthenticatedClient(key));
    }
  }
};

export const fetchSiteEventEnergyFeed = async (
  id: number,
  type: string,
  dataApi: DataApi,
  slideConfig?: SlideConfig
) => {
  if (type === "sites" && slideConfig) {
    const weather = true;
    const startDate = slideConfig.start;
    const endDate = slideConfig.end;
    const response = await dataApi.getEnergyFeed(
      type,
      id,
      startDate,
      endDate,
      undefined,
      weather
    );

    return {
      ...response.data,
      groupName: slideConfig.groupName,
      groupAttendance: slideConfig.groupAttendance,
      groupLogoUrl: slideConfig.groupLogoUrl,
    };
  }
};

export const fetchSiteSubMetersBreakdown = async (
  id: number,
  type: string,
  resourceApi: SitesApi,
  start: string,
  end: string,
  aggregate: string
) => {
  if (type === "sites") {
    const response = await resourceApi.getEnergyBreakdown(
      id,
      start,
      end,
      aggregate
    );

    return response.data;
  }
};

export const fetchPlanet = async (
  id: number,
  type: string,
  planetsApi: PlanetWatchApi
) => {
  if (type === "sites") {
    const regularResponse = await planetsApi.getPlanets("regular", [id]);
    const liveResponse = await planetsApi.getPlanets("live", [id]);
    //const planetConsumption = await planetsApi.getPlanetDailyAverageConsumption(id);

    const regular =
      (regularResponse?.data as any).length && regularResponse.data[0];
    const live = (liveResponse?.data as any).length && liveResponse.data[0];
    return { regular, live };
  }
};

export const fetchResourceRankings = async (
  id: number,
  type: string,
  dataApi: DataApi,
  order: string,
  averageByPopulation: boolean
) => {
  if (type !== "sites") {
    const response = await dataApi.getSitesEnergyRanking(
      type,
      id,
      order,
      averageByPopulation
    );
    return response.data;
  }
};

export const fetchResourceLeaderboards = async (
  id: number,
  type: string,
  dataApi: DataApi
) => {
  if (type !== "sites") {
    const consumption = await fetchResourceRankings(
      id,
      type,
      dataApi,
      "asc",
      false
    );
    const consumptionByPopulation = await fetchResourceRankings(
      id,
      type,
      dataApi,
      "asc",
      true
    );
    return {
      consumptionByPopulation,
      consumption,
    };
  }
};

export const fetchLifetimeEnergy = async (
  id: number,
  type: string,
  dataApi: DataApi
) => {
  const response = await dataApi.getEnergyStats(type, id);
  return response.data;
};

export const fetchResourceDetails = async (
  id: number,
  resourceApi: RootResourceApi
) => {
  const response = await resourceApi.get(id);
  (response.data as any).resourceName =
    (response.data as any).siteName ?? (response.data as any).name;
  return response.data;
};

export const fetchResourceSummary = async (
  id: number,
  resourceApi: RootResourceApi
) => {
  const result = await resourceApi.getSummary(id);
  return result.data;
};

export const fetchChildStats = async (
  id: number,
  type: string,
  dataApi: DataApi
) => {
  if (type === "schemas") {
    const response = await dataApi.getChildSolarStats(type, id);
    return response.data;
  }
};

export const fetchResourceSystems = async (
  id: number,
  type: string,
  resourceApi: SitesApi
) => {
  if (type === "sites") {
    const response = await resourceApi.getSystems(id);
    return response.data;
  }
};

export const fetchMeters = async (id: number, resourceApi: SitesApi) => {
  const response = await resourceApi.getMeters(id);
  return response.data;
};

export const fetchEnergyDataFeed = async (
  id: number,
  type: string,
  dataApi: DataApi,
  startDate: string,
  endDate: string,
  aggregation: string
) => {
  const weather = true;
  const response = await dataApi.getEnergyFeed(
    type,
    id,
    startDate,
    endDate,
    aggregation,
    weather
  );
  return response.data;
};

export const fetchSubMeterBreakdown = async (
  id: number,
  resourceApi: SitesApi,
  dataApi: DataApi,
  startDate: string,
  endDate: string,
  aggregation: string
) => {
  const metersResponse = await resourceApi.getMeters(id);
  const virtualSubMeters = (metersResponse.data as any).virtual?.filter(
    (vm) => vm?.typeId > 2 && vm?.status === 1
  );

  const siteData = await dataApi.getEnergyFeed(
    "sites",
    id,
    startDate,
    endDate,
    aggregation
  );

  const smData: any[] = [];

  await Promise.all(
    virtualSubMeters.map(async (subMeter) => {
      try {
        const response = await dataApi.getEnergyFeed(
          "virtualmeters",
          subMeter.id,
          startDate,
          endDate,
          aggregation
        );
        return { data: response.data, subMeter };
      } catch (err) {
        console.error(err);
      }
    })
  ).then((responses) => {
    responses.forEach(({ data, subMeter }) => {
      const dateString =
        data?.data?.[0]?.time?.length <= 10
          ? "yyyy-MM-dd"
          : "yyyy-MM-dd HH:mm:ss";

      if (data?.data && data?.totals?.consumedEnergy) {
        // If we're the first submeter, init the dataset with time values too
        if (!smData.length) {
          data.data.forEach((datum) => {
            smData.push({
              time: parse(datum.time, dateString, new Date()),
              [subMeter.name]: datum.consumedEnergy / 1000, // always kWh
            });
          });
        } else {
          // otherwise just edit datum with new vm data
          data.data.forEach((datum, index) => {
            const isUnique = Object.keys(smData).every(
              (key) => key !== subMeter.name
            );
            smData[index] = {
              ...smData[index],
              [isUnique ? subMeter.name : `${subMeter.name} (${subMeter.id})`]:
                datum.consumedEnergy / 1000,
            };
          });
        }
      }
    });
  });

  // calculate unknown consumption
  const output = smData.map((datum, index) => {
    // console.log(siteData?.data?.[index]);
    const netCon = siteData?.data?.[index]?.netConsumed / 1000;
    let datumTotal = 0;
    Object.values(datum).forEach((value) => {
      if (typeof value === "number") {
        datumTotal += value;
      }
    });
    const unknownConsumption = netCon - datumTotal;

    return {
      ...datum,
      "Unknown Consumption": unknownConsumption > 0 ? unknownConsumption : 0,
    };
  });

  return output;
};

export const fetchChildEnergy = async (
  id: number,
  type: string,
  dataApi: DataApi,
  startDate: string,
  endDate: string
) => {
  // fetch data based on the datakey and timekey
  const solarDataResponse = await dataApi.getChildEnergyBreakdown(
    type,
    id,
    "solar",
    startDate,
    endDate
  );
  const gridDataResponse = await dataApi.getChildEnergyBreakdown(
    type,
    id,
    "grid",
    startDate,
    endDate
  );

  return {
    solarGenerated: solarDataResponse.data,
    gridConsumed: gridDataResponse.data,
  };
};

export const fetchSystemEnergy = async (
  id: number,
  type: string,
  resourceApi: SitesApi,
  startDate: string,
  endDate: string
) => {
  if (type === "sites") {
    const response = await resourceApi.getSystemsDataFeed(
      id,
      startDate,
      endDate
    );
    return response.data;
  }
};

function getSiteEvent(configs: SlideConfig[]) {
  const eventConfig = configs.find(({ id }) => id.startsWith("siteEvent"));
  return eventConfig;
}

export const fetchNecessaryData = async (
  id: string,
  type: string,
  key: string,
  slideDataVars,
  slideConfigs: SlideConfig[]
) => {
  const resourceApi = getResourceApi(type, key);
  const dataApi = getResourceApi("data", key);
  const planetWatchApi = getResourceApi("planetwatch", key);
  const siteEventSlideConfig = getSiteEvent(slideConfigs);

  const today = format(new Date(), "yyyy-MM-dd");

  const thisDayLastWeek = format(subDays(new Date(), 7), "yyyy-MM-dd");

  const yesterday = format(
    new Date(new Date().setHours(23, 55, 30)),
    "yyyy-MM-dd"
  );
  const lastWeek = format(
    new Date(subDays(new Date(), 7)).setHours(0, 0, 30),
    "yyyy-MM-dd"
  );
  const lastMonth = format(
    new Date(subDays(new Date(), 30)).setHours(0, 0, 30),
    "yyyy-MM-dd"
  );

  const thisMonth = format(
    new Date(startOfMonth(new Date())).setHours(0, 0, 30),
    "yyyy-MM-dd"
  );

  const lastYear = format(
    new Date(subDays(new Date(), 365)).setHours(0, 0, 30),
    "yyyy-MM-dd"
  );

  // source of truth for possible data values and endpoints
  const endpoints = {
    scheduledSiteEvent: {
      func: fetchSiteEventEnergyFeed,
      params: [id, type, dataApi, siteEventSlideConfig],
    },

    dailySubMetersBreakdown: {
      func: fetchSiteSubMetersBreakdown,
      params: [id, type, resourceApi, today],
    },
    weeklySubMetersBreakdown: {
      func: fetchSiteSubMetersBreakdown,
      params: [id, type, resourceApi, lastWeek, yesterday],
    },
    monthlySubMetersBreakdown: {
      func: fetchSiteSubMetersBreakdown,
      params: [id, type, resourceApi, thisMonth, yesterday, "day"],
    },
    yearlySubMetersBreakdown: {
      func: fetchSiteSubMetersBreakdown,
      params: [id, type, resourceApi, lastYear, yesterday],
    },

    //************************************************* */

    planetWatch: {
      func: fetchPlanet,
      params: [id, type, planetWatchApi],
    },
    thisDayLastWeek: {
      func: fetchEnergyDataFeed,
      params: [id, type, dataApi, thisDayLastWeek],
    },
    dailyEnergy: {
      func: fetchEnergyDataFeed,
      params: [id, type, dataApi, today],
    },
    weeklyEnergy: {
      func: fetchEnergyDataFeed,
      params: [id, type, dataApi, lastWeek, yesterday, "day"],
    },
    monthlyEnergy: {
      func: fetchEnergyDataFeed,
      params: [id, type, dataApi, lastMonth, yesterday, "day"],
    },
    yearlyEnergy: {
      func: fetchEnergyDataFeed,
      params: [id, type, dataApi, lastYear, yesterday, "month"],
    },
    lifetimeEnergy: {
      func: fetchLifetimeEnergy,
      params: [id, type, dataApi],
    },
    energyDetails: {
      func: fetchResourceDetails,
      params: [id, resourceApi],
    },
    pvInfo: {
      func: fetchResourceSystems,
      params: [id, type, resourceApi],
    },
    siteRankings: {
      func: fetchResourceRankings,
      params: [id, type, dataApi, "asc", false],
    },
    siteLeaderboards: {
      func: fetchResourceLeaderboards,
      params: [id, type, dataApi],
    },
    summary: {
      func: fetchResourceSummary,
      params: [id, resourceApi],
    },
    childStats: {
      func: fetchChildStats,
      params: [id, type, dataApi],
    },
    meters: {
      func: fetchMeters,
      params: [id, resourceApi],
    },
    dailyChildEnergy: {
      func: fetchChildEnergy,
      params: [id, type, dataApi, today],
    },
    weeklyChildEnergy: {
      func: fetchChildEnergy,
      params: [id, type, dataApi, lastWeek, yesterday],
    },
    monthlyChildEnergy: {
      func: fetchChildEnergy,
      params: [id, type, dataApi, lastMonth, yesterday],
    },
    yearlyChildEnergy: {
      func: fetchChildEnergy,
      params: [id, type, dataApi, lastYear, yesterday],
    },
    dailySystemEnergy: {
      func: fetchSystemEnergy,
      params: [id, type, resourceApi, today],
    },
    weeklySystemEnergy: {
      func: fetchSystemEnergy,
      params: [id, type, resourceApi, lastWeek, yesterday],
    },
    monthlySystemEnergy: {
      func: fetchSystemEnergy,
      params: [id, type, resourceApi, lastMonth, yesterday],
    },
    dailySubMeterEnergy: {
      func: fetchSubMeterBreakdown,
      params: [id, resourceApi, dataApi, today],
    },
    weeklySubMeterEnergy: {
      func: fetchSubMeterBreakdown,
      params: [id, resourceApi, dataApi, lastWeek, yesterday],
    },
    monthlySubMeterEnergy: {
      func: fetchSubMeterBreakdown,
      params: [id, resourceApi, dataApi, lastMonth, yesterday],
    },
    yearlySubMeterEnergy: {
      func: fetchSubMeterBreakdown,
      params: [id, resourceApi, dataApi, lastYear, yesterday],
    },
  };

  const data = await Promise.all(
    slideDataVars.map(async (slideDataVar) => {
      const endpoint = endpoints?.[slideDataVar];
      if (endpoint) {
        try {
          let output = await endpoint.func.apply(null, endpoint.params);
          output = { [slideDataVar]: output };
          return output;
        } catch (error) {
          console.error(error);
        }
      }
    })
  );

  return data.reduce((result, current) => Object.assign(result, current), {});
};
