import React, { FunctionComponent } from "react";
import { parseSubscript2ToJSX } from "../../../../utilities/Parsing/ParseSubscript2ToJSX";
import {
  StatBoxContentContainer,
  StatBoxContainer,
  StatBoxIcon,
  StatBoxTitle,
  StatBoxStat,
} from "./StatBox.styles";
import { StatBoxProps } from "./StatBox.types";

const StatBox: FunctionComponent<StatBoxProps> = ({
  color = "#000000",
  title = "No data",
  stat = null,
  icon = null,
}) => {
  return (
    <StatBoxContainer bgColor={color}>
      <StatBoxContentContainer>
        <StatBoxTitle variant="caption">
          {parseSubscript2ToJSX(title.toUpperCase())}
        </StatBoxTitle>
        <StatBoxStat variant="h6">{stat}</StatBoxStat>
      </StatBoxContentContainer>
      {icon && <StatBoxIcon alt={icon} src={icon} />}
    </StatBoxContainer>
  );
};

export default StatBox;
