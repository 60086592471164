import React from "react";

export const parseSubscript2ToJSX = (text: string) =>
  text?.includes && text.includes("₂") ? (
    <span>
      {text.substr(0, text.indexOf("₂"))}
      <sub>2</sub>
      {text.substr(text.indexOf("₂") + 1, text.length)}
    </span>
  ) : (
    text
  );
