import { TimestreamQuery } from "aws-sdk";
import React, { useContext, useMemo } from "react";
import { DisplayBoardContext } from "../../../Context";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { PieChart } from "./PieChart";
import { StackedXYChart } from "./StackedXYChart";
import {
  LegendContainer,
  PieChartContainer,
  StackedXYChartContainer,
  SubMeterAnalysisContainer,
} from "./SubMetersAnalysis.styles";
import { SubMetersAnalysisProps } from "./SubMetersAnalysis.types";
import {
  mapSubMeterDataToStackedXYChart,
  mapSubMeterTotalsToPieChartSeries,
} from "./SubMetersAnalysis.utils";

export function SubMetersAnalysis(props: SubMetersAnalysisProps) {
  const { data } = useContext(DisplayBoardContext);
  const { dailySubMetersBreakdown, monthlySubMetersBreakdown } = data;
  const { timeSpan } = useSlideContext();

  const subMeterData = useMemo(() => {
    switch (timeSpan) {
      case "daily":
        return dailySubMetersBreakdown;
      case "monthly":
        return monthlySubMetersBreakdown;
      default:
        return dailySubMetersBreakdown;
    }
  }, [timeSpan, dailySubMetersBreakdown, monthlySubMetersBreakdown]);

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <SubMeterAnalysisContainer>
        <StackedXYChartContainer>
          <StackedXYChart
            {...mapSubMeterDataToStackedXYChart(
              subMeterData,
              "consumed",
              timeSpan
            )}
          />
        </StackedXYChartContainer>
        <PieChartContainer>
          <div style={{ height: "80%" }}>
            <PieChart
              {...mapSubMeterTotalsToPieChartSeries(
                subMeterData,
                "consumed",
                timeSpan
              )}
            />
          </div>
        </PieChartContainer>
      </SubMeterAnalysisContainer>
    </div>
  );
}

export default SubMetersAnalysis;
