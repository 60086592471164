import { getDataTypeName } from "../../../../utilities/Parsing/dataKeys";
import { DataType } from "../../../../types/dataKeys";
import { convertToUnitFromLowest } from "../../../../utilities/DataUtil/old/convertToUnitFromLowest";
import { getUnitAndValue } from "../../../../utilities/DataUtil/getUnitAndValue";
import { getUnitTypeFromDataType } from "../../../../utilities/DataUtil/old/getUnitTypeFromDataType";
import { parseSubscript2ToJSX } from "../../../../utilities/Parsing/ParseSubscript2ToJSX";
import { DataTypes } from "../../../../types/dataKeys";
import { getFullUnitFromShorthand } from "../../../../utilities/DataUtil/getFullUnitFromShorthand";
import { Theme } from "@material-ui/core";
import { UnitShorthand } from "../../../../types/unitShorthand";
import { UnitType } from "../../../../utilities/DataUtil/unitConstants";

export const getBarComparisonConfig = (
  theme: Theme,
  data: any,
  dataKeys: DataTypes
) => {
  const unitType = getUnitTypeFromDataType(dataKeys[2]);
  const isSameUnit =
    getUnitTypeFromDataType(dataKeys[2]) ===
    getUnitTypeFromDataType(dataKeys[3]);
  const barData = [data?.totals[dataKeys[2]], data?.totals[dataKeys[3]]];
  const sum = barData[0] + barData[1];
  let barHeights = isSameUnit
    ? [`${(barData[0] / sum) * 100}%`, `${(barData[1] / sum) * 100}%`]
    : ["50%", "50%"];
  if (barHeights[0] === "NaN%" && barHeights[1] === "NaN%") {
    barHeights = ["50%", "50%"];
  }
  const average = sum / 2;
  const [, unit] = getUnitAndValue(average, unitType as UnitType);

  // FORMAT BAR TITLE
  let barTitle;
  if (isSameUnit && unitType) {
    if (unitType === "mass") {
      barTitle = parseSubscript2ToJSX(
        `CO₂e (${getFullUnitFromShorthand(
          unit as UnitShorthand
        ).toUpperCase()})`
      );
    } else {
      barTitle = parseSubscript2ToJSX(
        `Energy (${getFullUnitFromShorthand(
          unit as UnitShorthand
        ).toUpperCase()})`
      );
    }
  } else {
    barTitle = "N/A (DIFFERENT UNITS)";
  }

  return [
    barData as any,
    barTitle as string,
    barHeights as string[],
    unit as string,
    isSameUnit,
  ];
};

export const getBarText = (value: number, unit: UnitShorthand) => {
  const newValue = convertToUnitFromLowest(value, unit);
  // return `${newValue.toFixed(2).replace(/[.,]00$/, "")} ${unit}`;
  return `${Math.round(newValue)}${unit}`;
};

export const getLegendText = (dataKey: DataType) => {
  const isEnergy = getUnitTypeFromDataType(dataKey) === "energy";
  const text = getDataTypeName(dataKey)[0]?.split(" ")[isEnergy ? 0 : 1];
  const needsClarification = text === "Solar";
  const clarification = needsClarification
    ? ` (${getDataTypeName(dataKey)[0]
        ?.split(" ")[1]
        .substr(0, 3)})`
    : "";
  return text + clarification;
};

// Always show lowest split at the bottom to help with overlapping text
export const getIndexes = (barData: number[]) => {
  if (barData[1] < barData[0]) {
    return [0, 1];
  } else {
    return [1, 0];
  }
};
