import React, { FunctionComponent } from "react";
import parse from "html-react-parser";
import { StaticContentContainer } from "./StaticContent.styles";
import TitleBox from "../../_shared/TitleBox/TitleBox";

export const StaticContent: FunctionComponent<{
  content?: any;
  title: string;
}> = ({ content = null, title = "" }) => {
  let subTitle = "Sustainability Features";
  const parsedContent = content ? parse(content) : "";

  return (
    <div className="h-100">
      <TitleBox titlePart1={title} subTitle={subTitle} />
      <div
        className="mx-5"
        style={{
          height: "83%"
        }}
      >
        <StaticContentContainer>{parsedContent}</StaticContentContainer>
      </div>
    </div>
  );
};

export default StaticContent;
