import styled from "../../../providers/ThemeProvider";

export const SmallBar = styled.div<{ barHeight: string; barColor: string }>(
  ({ theme, barHeight, barColor }) => ({
    width: "3rem",
    height: barHeight,
    backgroundColor: barColor,
    border: "1px solid darkgrey",
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    // opacity: 0.9,
  })
);

export const SmallBarContainer = styled.div(({ theme }) => ({
  height: "12rem",
  display: "flex",
  width: "100%",
  fontSize: "12px",
  alignItems: "flex-end",
  justifyContent: "space-around",
  textAlign: "center",
  position: "relative",
}));

export const SmallBarValueLabel = styled.div<{ barHeight: string }>(
  ({ barHeight }) => ({
    position: "absolute",
    width: "4rem",
    color: "white",
    bottom: `calc(${barHeight} + 0.5rem)`,
  })
);

export const SmallBarComparisonWrap = styled.div({
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
  alignItems: "flex-end",
});

export const SmallBarTextContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

export const SmallBarText = styled.div(({ theme }) => ({
  width: "50%",
  textAlign: "center",
  color: theme.palette.common.white,
}));

export const SmallBarTitle = styled.div(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.common.white,
  width: "100%",
  marginTop: theme.spacing(1),
}));
