import React, { FunctionComponent } from "react";
import CO2StackInfo from "../../_shared/CO2StackInfo";
import CO2Stack from "../../_shared/CO2Stack";
import CO2StatBox from "../../_shared/CO2StatBox";
import {
  CO2TowerContainer,
  CO2StackInfoContainer,
  CO2StackContainer,
  CO2StatBoxContainer,
  CO2TowerContent,
} from "./CO2Tower.styles";
// import { CO2TowerProps } from "./CO2Tower.types";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";

// TODO refactor sub components into abstractions with styled-components/typescript/hooks/utilities
export const CO2Tower: FunctionComponent<{}> = () => {
  const {
    timeSpan,
    dataKey: [dataKey],
  } = useSlideContext();
  const rawData = useTimeSpanData(timeSpan);

  return (
    <CO2TowerContainer>
      <CO2TowerContent>
        <CO2StackInfoContainer>
          <CO2StackInfo co2={rawData.totals[dataKey]} dataKey={dataKey} />
        </CO2StackInfoContainer>
        <CO2StackContainer>
          <CO2Stack
            optimize={true}
            superOptimize={false}
            co2={rawData.totals[dataKey]}
            dataKey={dataKey}
          />
        </CO2StackContainer>
      </CO2TowerContent>
      <CO2StatBoxContainer>
        <CO2StatBox co2={rawData.totals[dataKey]} dataKey={dataKey} />
      </CO2StatBoxContainer>
    </CO2TowerContainer>
  );
};
