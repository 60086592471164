import styled from "../../../providers/ThemeProvider";

export const RegularPlanetContainer = styled.div({
  height: "100%",
  width: "70%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

export const StatsContainer = styled.div(({ theme }) => ({
  width: "30%",
  height: "100%",
  color: "#808080",
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  fontSize: 17,
  fontFamily: "Courier New Courier, monospace",
  justifyContent: "center",
  paddingRight: theme.spacing(5.5),
}));

export const StatsBox = styled.div<{ noBorder?: number }>(({ noBorder }) => ({
  padding: "10px",
  paddingLeft: "20px",
  paddingRight: "20px",
  ...(!noBorder && {
    background: `
    linear-gradient(to right, #262626 4px, transparent 4px) 0 0,
    linear-gradient(to right, #262626 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #262626 4px, transparent 4px) 100% 0,
    linear-gradient(to left, #262626 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, #262626 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, #262626 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #262626 4px, transparent 4px) 0 100%,
    linear-gradient(to top, #262626 4px, transparent 4px) 100% 100%`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px 20px",
  }),
  // height: "calc(75% - 100px)",
}));

export const PlanetImage = styled.img({
  height: "75%",
});

export const SmallPlanetImage = styled.img({
  height: "100%",
});

export const PlanetWatchContainer = styled.div({
  color: "#FFF",
  position: "relative",
  display: "flex",
  width: "100%",
  height: "100%",
});

export const PlanetWatchLogo = styled.img(({ theme }) => ({
  position: "absolute",
  height: "100px",
  top: theme.spacing(4),
  right: theme.spacing(5.5),
}));

export const LivePlanetContainer = styled.div({
  height: "200px",
});
