import clear_day from "./clear-day.svg";
import clear_night from "./clear-night.svg";
import cloudy from "./cloudy.svg";
import partly_cloudy_day from "./partly-cloudy-day.svg";
import partly_cloudy_night from "./partly-cloudy-night.svg";
import rain from "./rain.svg";
import snow from "./snow.svg";
import thunder_storm from "./thunder-storm.svg";
import wind from "./wind.svg";

const weatherIcons = {
  clear_day,
  clear_night,
  cloudy,
  partly_cloudy_day,
  partly_cloudy_night,
  rain,
  snow,
  thunder_storm,
  wind
};

export default weatherIcons;
