import React, { FunctionComponent, useEffect } from "react";
// import InfoContainer from "../InfoContainer/InfoContainer";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ContentContainer, TempTransitionContainer } from "./Content.styles";
import "./Transitions.scss";
import { useVariableDelayInterval } from "./Content.hooks";
import { ContentProps, Slide } from "./Content.types";
import SlideWrapper from "./SlideWrapper/SlideWrapper";

const Content: FunctionComponent<ContentProps> = ({ componentList }) => {
  const counter = useVariableDelayInterval(
    componentList.map((component: Slide) => component.delay)
  );

  return (
    <ContentContainer>
      {/* todo - phase out */}
      <TransitionGroup>
        <CSSTransition key={counter} timeout={2200} classNames="fade">
          <TempTransitionContainer>
            {componentList[counter].title ? (
              // TODO -- remove when everything is supported
              <SlideWrapper
                slide={componentList[counter]}
                fullWidth={componentList[counter]?.fullWidth}
              >
                {componentList[counter].component}
              </SlideWrapper>
            ) : (
              componentList[counter].component
            )}
          </TempTransitionContainer>
        </CSSTransition>
      </TransitionGroup>
    </ContentContainer>
  );
};

export default Content;
