import React, { useMemo } from "react";
import { SiteEventProps } from "./SiteEvent.types";
import {
  BottomWrap,
  ChartWrap,
  Wrap,
} from "../../../utilities/InputJSON/InputJSON.styles";
import FeedTotals from "../../_shared/FeedTotals/FeedTotals";
import GenericGraph from "../GenericGraph/GenericGraph";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";
import { TimeSpan } from "../../../types/dataKeys";
import { EventInfo } from "./EventInfo";
import { EnergyUse } from "./EnergyUse";
import { OffsetEquivilent } from "./OffsetEquivilent";
import {
  Overlay,
  Underlay,
  WidgetContent,
  WidgetHeader,
  WidgetHeading,
  WidgetWrapper,
} from "./SiteEvent.styles";
import { differenceInDays, format, parse, isFuture } from "date-fns";

export function SiteEvent(props: SiteEventProps) {
  const { timeSpan } = useSlideContext();
  const data = useTimeSpanData(timeSpan as TimeSpan, "SiteEvent");

  const initialDateFormat = "yyyy-MM-dd HH:mm:ss";
  const readableDateFormat = "do LLL, y";

  const dateRange = useMemo(() => {
    const parsedStart = parse(data.startTime, initialDateFormat, new Date());
    const parsedEnd = parse(data.endTime, initialDateFormat, new Date());
    const start = format(parsedStart, readableDateFormat);
    const end = format(parsedEnd, readableDateFormat);
    const duration = differenceInDays(parsedEnd, parsedStart);
    return {
      start,
      end,
      duration,
    };
  }, [data.startTime, data.endTime]);

  const isYetToStart = useMemo(() => {
    const parsedStart = parse(data.startTime, initialDateFormat, new Date());
    return isFuture(parsedStart);
  }, [data.startTime]);

  return (
    <Wrap>
      {isYetToStart && (
        <Underlay>
          <Overlay>
            <p style={{ margin: 0, fontSize: "1.2rem", fontWeight: "bold" }}>
              This event will commence on {dateRange.start}
            </p>
          </Overlay>
        </Underlay>
      )}
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gap: "1rem",
          marginBottom: "2rem",
        }}
      >
        <EventInfo
          arrivalDate={dateRange.start}
          departureDate={dateRange.end}
          groupName={data.groupName}
          groupLogoUrl={data.groupLogoUrl}
          totalGuests={data.groupAttendance ?? 0}
          duration={dateRange.duration}
        />
        <EnergyUse
          co2Emitted={data.totals.co2ProducedLifecycle}
          totalGuests={data.groupAttendance ?? 0}
        />
        <OffsetEquivilent
          co2Emitted={data.totals.co2ProducedLifecycle}
          totalGuests={data.groupAttendance ?? 0}
          daysDuration={dateRange.duration}
        />
      </div>
      <WidgetWrapper>
        <WidgetHeader>
          <WidgetHeading>Energy Use During Your Stay</WidgetHeading>
        </WidgetHeader>
        <WidgetContent>
          <GenericGraph seriesType="bar" suffix="SiteEvent" />
          <FeedTotals suffix="SiteEvent" />
        </WidgetContent>
      </WidgetWrapper>
    </Wrap>
  );
}

export default SiteEvent;
