import React, { useState, useEffect, useContext } from "react";
import "./SolarEfficiencyGauge.scss";
import { DisplayBoardContext } from "../../../Context";
import { format, parse } from "date-fns";
import { autoFormatToWatt } from "./SolarEfficiencyGauge.utils";
import { Typography, useTheme } from "@material-ui/core";
import { GaugeContainer } from "./SolarEfficiencyGauge.styled";

const SolarEfficiencyGauge = ({ id }) => {
  const [solarEfficiency, setSolarEfficiency] = useState(false);
  const [solarGenerated, setSolarGenerated] = useState(false);
  const [timestamp, setTimestamp] = useState(false);
  const [counter, setCounter] = useState(0);
  const [fetchContext, setFetchContext] = useState(true);
  const ctx = useContext(DisplayBoardContext);
  const type = ctx.type;
  const energyDetails = ctx.data.energyDetails;
  const theme = useTheme();
  const color = theme.palette?.sideBarText?.main || theme.palette.common.white;

  useEffect(() => {
    if (fetchContext && type !== "schemas") {
      const {
        lastCompleteEnergyGenerated,
        lastCompleteEnergyGeneratedEfficiency,
        lastCompleteEnergyGeneratedTime,
      } = energyDetails;
      setSolarEfficiency(
        lastCompleteEnergyGeneratedEfficiency > 1
          ? 1
          : lastCompleteEnergyGeneratedEfficiency
      );
      setSolarGenerated(lastCompleteEnergyGenerated);
      setTimestamp(
        format(
          parse(
            lastCompleteEnergyGeneratedTime,
            "yyyy-MM-dd HH:mm:ss",
            new Date()
          ),
          "h:mm a"
        )
      );
      setFetchContext(false);
    }
  }, [fetchContext]);

  useEffect(() => {
    if (solarEfficiency && solarGenerated && timestamp) {
      const timer = setInterval(async () => {
        setFetchContext(true);
      }, 300000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [solarEfficiency, solarGenerated, timestamp]);

  useEffect(() => {
    if (solarEfficiency) {
      const timer = setInterval(() => {
        setCounter((prevTime) => setDirection(prevTime));
      }, 60);
      return () => {
        clearInterval(timer);
      };
    }
  }, [solarEfficiency]);

  const setDirection = (prevTime) => {
    const percentage = Math.ceil(solarEfficiency * 100);
    if (percentage - prevTime < 5) {
      prevTime = percentage;
    }
    if (prevTime > percentage) return (prevTime += 6);
    if (prevTime < percentage) return (prevTime += 6);
    return prevTime;
  };

  const inBracket = (value, base, ceil) => {
    const offset = 5;
    return value > base && value <= ceil + offset;
  };

  return (
    <GaugeContainer>
      <div className="d-flex flex-column w-100">
        <div className="mb-3">
          <svg id="dial" viewBox="0 0 1000 1000" className="w-100">
            <title>Dial Outline</title>
            <defs>
              <clipPath id="clipPath">
                <path
                  d="
            M297.4,874.4c-26.5-15.9-51.3-34.9-73.9-57.1l-69.9,69.9c28.7,28.2,60.3,52.5,94.4,72.7L297.4,874.4z
            M146.2,879.9l69.9-69.9c-22-22.7-41-47.5-56.8-74.1l-85.6,49.4C93.9,819.6,118.1,851.2,146.2,879.9z
            M154.2,726.9c-15.2-27.3-27.1-56.2-35.4-86.3l-95.5,25.6c5.7,20.6,12.7,40.9,21.1,60.7c7.2,17,15.3,33.5,24.2,49.4L154.2,726.9z
            M116.1,630.6c-7.4-29.9-11.4-60.9-11.7-92.4H5.5c0.3,40.2,5.3,79.7,15.1,118L116.1,630.6z
            M5.5,527.8h98.9c0.5-31.5,4.7-62.4,12.3-92.2l-95.5-25.6C11.3,448.2,6,487.6,5.5,527.8z
            M119.5,425.5c8.5-29.9,20.6-58.7,35.9-85.8l-85.6-49.4C60.4,307,51.9,324.2,44.4,342c-8,19-14.8,38.3-20.4,58L119.5,425.5z
            M160.7,330.8c15.9-26.3,34.9-50.9,56.9-73.4l-69.9-69.9C119.6,216,95.3,247.4,75,281.3L160.7,330.8z
            M155,180.1l69.9,69.9c22.6-21.8,47.3-40.7,73.7-56.4l-49.4-85.6C215.2,128.2,183.6,152.3,155,180.1z
            M307.6,188.6c27.1-15.1,55.8-26.9,85.7-35.3l-25.6-95.5c-20.5,5.7-40.6,12.7-60.3,21C290.6,86,274.1,94,258.2,103L307.6,188.6z
            M403.4,150.6c29.7-7.4,60.5-11.4,91.9-11.8V40c-40,0.4-79.3,5.4-117.5,15.2L403.4,150.6z
            M505.7,40v98.9c31.4,0.4,62.1,4.5,91.8,12l25.6-95.5C584.9,45.6,545.7,40.4,505.7,40z
            M607.5,153.6c29.8,8.4,58.5,20.3,85.6,35.4l49.4-85.6c-16.1-9.1-32.8-17.3-50-24.6c-19.4-8.2-39.2-15.1-59.4-20.8L607.5,153.6z
            M702.1,194.2c26.3,15.7,51,34.6,73.4,56.4l69.9-69.9c-28.6-27.9-60-52-94-72.1L702.1,194.2z
            M852.8,188l-69.9,69.9c21.9,22.4,40.9,47,56.7,73.3l85.6-49.4C905,247.9,880.8,216.5,852.8,188z
            M844.8,340.2c15.3,27,27.3,55.7,35.7,85.6l95.5-25.6c-5.6-19.7-12.4-39.2-20.4-58.2c-7.5-17.6-15.9-34.7-25.2-51.2L844.8,340.2z
            M883.3,435.8c7.6,29.7,11.8,60.6,12.3,92h98.9c-0.5-40.1-5.8-79.4-15.7-117.6L883.3,435.8z
            M994.5,538.2h-98.9c-0.3,31.5-4.2,62.4-11.6,92.2l95.5,25.6C989.2,617.7,994.2,578.3,994.5,538.2z
            M881.4,640.4c-8.3,30-20.1,58.9-35.3,86.1l85.6,49.4c8.8-15.8,16.8-32.1,24-49c8.4-19.9,15.5-40.3,21.2-61L881.4,640.4z
            M840.9,735.5c-15.7,26.5-34.6,51.3-56.5,74l69.9,69.9c28-28.7,52.1-60.3,72.2-94.5L840.9,735.5z
          "
                />
              </clipPath>
            </defs>
            <g>
              <path
                className={`segment-${
                  inBracket(counter, 0, 100) ? "full" : "empty"
                }`} // 1
                d="M297.4,874.4c-26.5-15.9-51.3-34.9-73.9-57.1l-69.9,69.9c28.7,28.2,60.3,52.5,94.4,72.7L297.4,874.4z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 5, 100) ? "full" : "empty"
                }`} // 2
                d="M146.2,879.9l69.9-69.9c-22-22.7-41-47.5-56.8-74.1l-85.6,49.4C93.9,819.6,118.1,851.2,146.2,879.9z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 10, 100) ? "full" : "empty"
                }`} // 3
                d="M154.2,726.9c-15.2-27.3-27.1-56.2-35.4-86.3l-95.5,25.6c5.7,20.6,12.7,40.9,21.1,60.7
      c7.2,17,15.3,33.5,24.2,49.4L154.2,726.9z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 15, 100) ? "full" : "empty"
                }`} // 4
                d="M116.1,630.6c-7.4-29.9-11.4-60.9-11.7-92.4H5.5c0.3,40.2,5.3,79.7,15.1,118L116.1,630.6z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 20, 100) ? "full" : "empty"
                }`} // 5
                d="M5.5,527.8h98.9c0.5-31.5,4.7-62.4,12.3-92.2l-95.5-25.6C11.3,448.2,6,487.6,5.5,527.8z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 25, 100) ? "full" : "empty"
                }`} // 6
                d="M119.5,425.5c8.5-29.9,20.6-58.7,35.9-85.8l-85.6-49.4C60.4,307,51.9,324.2,44.4,342c-8,19-14.8,38.3-20.4,58
      L119.5,425.5z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 30, 100) ? "full" : "empty"
                }`} // 7
                d="M160.7,330.8c15.9-26.3,34.9-50.9,56.9-73.4l-69.9-69.9C119.6,216,95.3,247.4,75,281.3L160.7,330.8z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 35, 100) ? "full" : "empty"
                }`} // 8
                d="M155,180.1l69.9,69.9c22.6-21.8,47.3-40.7,73.7-56.4l-49.4-85.6C215.2,128.2,183.6,152.3,155,180.1z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 40, 100) ? "full" : "empty"
                }`} // 9
                d="M307.6,188.6c27.1-15.1,55.8-26.9,85.7-35.3l-25.6-95.5c-20.5,5.7-40.6,12.7-60.3,21
      C290.6,86,274.1,94,258.2,103L307.6,188.6z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 45, 100) ? "full" : "empty"
                }`} // 10
                d="M403.4,150.6c29.7-7.4,60.5-11.4,91.9-11.8V40c-40,0.4-79.3,5.4-117.5,15.2L403.4,150.6z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 50, 100) ? "full" : "empty"
                }`} // 11
                d="M505.7,40v98.9c31.4,0.4,62.1,4.5,91.8,12l25.6-95.5C584.9,45.6,545.7,40.4,505.7,40z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 55, 100) ? "full" : "empty"
                }`} // 12
                d="M607.5,153.6c29.8,8.4,58.5,20.3,85.6,35.4l49.4-85.6c-16.1-9.1-32.8-17.3-50-24.6
      c-19.4-8.2-39.2-15.1-59.4-20.8L607.5,153.6z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 60, 100) ? "full" : "empty"
                }`} // 13
                d="M702.1,194.2c26.3,15.7,51,34.6,73.4,56.4l69.9-69.9c-28.6-27.9-60-52-94-72.1L702.1,194.2z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 65, 100) ? "full" : "empty"
                }`} // 14
                d="M852.8,188l-69.9,69.9c21.9,22.4,40.9,47,56.7,73.3l85.6-49.4C905,247.9,880.8,216.5,852.8,188z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 70, 100) ? "full" : "empty"
                }`} // 15
                d="M844.8,340.2c15.3,27,27.3,55.7,35.7,85.6l95.5-25.6c-5.6-19.7-12.4-39.2-20.4-58.2
      c-7.5-17.6-15.9-34.7-25.2-51.2L844.8,340.2z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 75, 100) ? "full" : "empty"
                }`} // 16
                d="M883.3,435.8c7.6,29.7,11.8,60.6,12.3,92h98.9c-0.5-40.1-5.8-79.4-15.7-117.6L883.3,435.8z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 80, 100) ? "full" : "empty"
                }`} // 17
                d="M994.5,538.2h-98.9c-0.3,31.5-4.2,62.4-11.6,92.2l95.5,25.6C989.2,617.7,994.2,578.3,994.5,538.2z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 85, 100) ? "full" : "empty"
                }`} // 18
                d="M881.4,640.4c-8.3,30-20.1,58.9-35.3,86.1l85.6,49.4c8.8-15.8,16.8-32.1,24-49c8.4-19.9,15.5-40.3,21.2-61
      L881.4,640.4z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 90, 100) ? "full" : "empty"
                }`} // 19
                d="M840.9,735.5c-15.7,26.5-34.6,51.3-56.5,74l69.9,69.9c28-28.7,52.1-60.3,72.2-94.5L840.9,735.5z"
              />
              <path
                className={`segment-${
                  inBracket(counter, 95, 100) ? "full" : "empty"
                }`} // 20
                d="M847,886.8L777,816.9c-22.5,22.1-47.3,41.2-73.7,57.1l49.4,85.6C786.9,939.3,818.4,915,847,886.8z"
              />
            </g>
            <text
              fontSize="5.2rem"
              fill={color}
              textAnchor="middle"
              x="500"
              y="500"
            >
              Current System
            </text>
            <text
              fontSize="5.2rem"
              fill={color}
              textAnchor="middle"
              x="500"
              y="600"
            >
              Performance
            </text>
            <text
              fontSize="10rem"
              fill={color}
              textAnchor="middle"
              x="500"
              y="800"
            >
              {counter}%
            </text>
          </svg>
        </div>
        <Typography
          align="center"
          color="textSecondary"
          variant="h6"
          color="inherit"
        >
          Generating
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          variant="h5"
          color="inherit"
        >
          {solarGenerated ? autoFormatToWatt(solarGenerated) : "0.00 W"}
        </Typography>
        <Typography
          variant="body2"
          color="inherit"
          align="center"
          style={{ marginTop: "0.3rem", marginBottom: "1rem" }}
        >
          As at {timestamp}
        </Typography>
      </div>
    </GaugeContainer>
  );
};

export default SolarEfficiencyGauge;
