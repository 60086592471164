import styled from "../../../providers/ThemeProvider";

export const CO2TowerContainer = styled.div({
  width: "100%",
  height: "100%",
});

export const CO2StackInfoContainer = styled.div({
  display: "flex",
  alignItems: "flex-end",
  width: "50%",
  height: "100%",
});

export const CO2StackContainer = styled.div(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: "50%",
  height: "100%",
  justifyContent: "space-around",
  paddingRight: theme.spacing(5),
}));

export const CO2TowerContent = styled.div({
  display: "flex",
  height: "80%",
  justifyContent: "center",
});

export const CO2StatBoxContainer = styled.div({
  height: "20%",
});

export const CO2TowerStoriesContainer = styled.div({
  height: 800,
  width: 1400,
});
