import { VirtualMeterDataEnergyFeed } from "../../../../types/Meters";
import { FlowLink } from "../EnergyFlow.styled";
import { settings } from "../EnergyFlow.consts";
import { lighten } from "@material-ui/core";
import React, { Fragment } from "react";

type Props = {
  paths?: Array<string> | null;
  color: string;
  data?: Array<VirtualMeterDataEnergyFeed>;
};

export const SubElementLinks = ({ paths, color, data }: Props) => {
  return paths ? (
    <Fragment>
      {paths.map((path, index) => (
        <FlowLink d={path} key={index} stroke={lighten(color, 0.2)} />
      ))}
    </Fragment>
  ) : null;
};
