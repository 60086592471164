import { to2FixedIfNeeded } from "./to2FixedIfNeeded";
import { UnitType } from "./unitConstants";

export const getUnitAndValue = (
  value: number,
  unitType: UnitType,
  isWatt?: boolean
) => {
  const len = Math.ceil(Math.log10(value + 1));
  const isEnergy = unitType === "energy";
  const isMass = unitType === "mass";
  if (!isEnergy && !isMass) {
    return [NaN, false];
  }
  if (len < 4) {
    return [to2FixedIfNeeded(value), isEnergy ? (isWatt ? "W" : "Wh") : "g"];
  } else if (len < 7) {
    return [
      to2FixedIfNeeded(value / 1e3),
      isEnergy ? (isWatt ? "kW" : "kWh") : "kg",
    ];
  } else if (len < 10) {
    return [
      to2FixedIfNeeded(value / 1e6),
      isEnergy ? (isWatt ? "MW" : "MWh") : "t",
    ];
  } else {
    return [
      to2FixedIfNeeded(value / 1e9),
      isEnergy ? (isWatt ? "GW" : "GWh") : "kt",
    ];
  }
};
