import { VirtualMeterDataEnergyFeed } from "../../../../types/Meters";
import { SubElementCircle } from "../EnergyFlow.styled";
import { settings } from "../EnergyFlow.consts";
import { Position } from "../EnergyFlow.types";
import { lighten } from "@material-ui/core";
import React, { Fragment } from "react";

type Props = {
  subElementIndex: number;
  color: string;
  positions?: Array<Position>;
  data?: Array<VirtualMeterDataEnergyFeed>;
};

export const SubElements = ({
  subElementIndex,
  positions,
  color,
  data,
}: Props) => {
  return positions ? (
    <Fragment>
      {positions.map((position, index) => {
        return (
          <Fragment key={index}>
            <SubElementCircle
              cx={position.x}
              cy={position.y}
              fill={lighten(color, 0.9)}
              style={{
                filter: `url(#shadow${subElementIndex})`,
              }}
              stroke={color}
            />
            <use
              href={"/icons/Metric/meter.svg#meter"}
              x={position.x - settings.subElementWidth / 3}
              y={position.y - settings.subElementWidth / 3}
              fill={lighten(color, 0.2)}
              width={settings.subElementWidth / 1.5}
              height={settings.subElementWidth / 1.5}
            />
            {/* <ElementValueLabel
              x={position.x}
              y={position.y + settings.subElementWidth / 1.2}
              fill={"#808080"}
            >
              TEST
            </ElementValueLabel> */}
          </Fragment>
        );
      })}
    </Fragment>
  ) : null;
};
