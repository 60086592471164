import React from "react";
import {
  ColumnContentContainer,
  ColumnContentItem,
} from "./CampsStatic.styles";

export function CampsEnergySavingTips() {
  return (
    <ColumnContentContainer>
      <ColumnContentItem
        style={{
          backgroundImage: "url('/background-images/energy-saving-02.webp')",
          backgroundSize: "cover",
          backgroundPositionX: "50%",
        }}
      >
        <div>
          <h2
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              textAlign: "center",
              textShadow: "2px 2px 3px rgba(0, 0, 0, 1)",
            }}
          >
            Hot water is responsible for around 25% of the average household
            energy use
          </h2>
        </div>

        <div>
          <p
            style={{
              fontSize: "1.75rem",
              fontWeight: "bold",
              textAlign: "center",
              textShadow: "2px 2px 3px rgba(0, 0, 0, 1)",
            }}
          >
            You can help reduce this by having shorter showers and save lots of
            water too!
          </p>
        </div>
      </ColumnContentItem>

      <ColumnContentItem
        style={{
          backgroundImage: "url('/background-images/energy-saving-01.webp')",
          backgroundSize: "cover",
          backgroundPositionX: "50%",
        }}
      >
        <div>
          <h2
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              textAlign: "center",
              textShadow: "2px 2px 3px rgba(0, 0, 0, 1)",
            }}
          >
            If you turn off lights during the day, and open the blinds, you can
            reduce the energy usage for your building
          </h2>
        </div>

        <div>
          <p
            style={{
              fontSize: "1.75rem",
              fontWeight: "bold",
              textAlign: "center",
              textShadow: "2px 2px 3px rgba(0, 0, 0, 1)",
            }}
          >
            How much do you think we can save by tomorrow?
          </p>
        </div>
      </ColumnContentItem>
    </ColumnContentContainer>
  );
}

export default CampsEnergySavingTips;
