import {
  getElementPositions,
  getLinkPaths,
  getSubElementPathsAndPositions,
} from "./EnergyFlow.utils";
import { EnergyElements } from "./components/EnergyElements";
import { EnergyFlowContainer } from "./EnergyFlow.styled";
import { EnergyLinks } from "./components/EnergyLinks";
import { EnergyFlowDatum } from "./EnergyFlow.types";
import { EnergyDots } from "./components/EnergyDots";
import { SiteMeter } from "../../../types/Meters";
import { settings } from "./EnergyFlow.consts";
import { useTheme } from "@material-ui/core";
import React from "react";
import { SubElementLinks } from "./components/SubElementLinks";
import { SubElementDots } from "./components/SubElementDots";
import { SubElements } from "./components/SubElements";

type Props = {
  data?: EnergyFlowDatum;
  dimensions: {
    width: number;
    height: number;
  };
  subMeters: Array<SiteMeter>;
};

export const EnergyFlowComponent = ({ data, dimensions, subMeters }: Props) => {
  const theme = useTheme();
  const elementPos = getElementPositions(dimensions.width, dimensions.height);
  const elementConfigs = settings.elementConfigs;
  const paths = getLinkPaths(elementConfigs, elementPos, data);

  // sub-elements
  const subElementIndex = 2;
  const subElementInfo = getSubElementPathsAndPositions(
    elementPos[subElementIndex],
    subMeters?.length || 0
  );
  const subElementColor =
    theme.palette?.dataKeys?.[elementConfigs?.[subElementIndex]?.dataKey] ||
    "#a6a6a6";

  return (
    <EnergyFlowContainer width={dimensions.width} height={dimensions.height}>
      <EnergyLinks
        elementConfigs={elementConfigs}
        paths={paths}
        theme={theme}
      />
      <EnergyDots elementConfigs={elementConfigs} paths={paths} theme={theme} />
      <SubElementLinks paths={subElementInfo?.paths} color={subElementColor} />
      <SubElementDots paths={subElementInfo?.paths} color={subElementColor} />
      <SubElements
        subElementIndex={subElementIndex}
        positions={subElementInfo?.positions}
        color={subElementColor}
      />
      <EnergyElements
        data={data}
        elementPos={elementPos}
        elementConfigs={elementConfigs}
        theme={theme}
      />
    </EnergyFlowContainer>
  );
};
