import { Configuration } from "@solarschools/api-sdk-typescript";
import { fetchEnv } from "../../utilities/FetchEnv";

/**
 * Launch instance to SolarSchools API client
 */
// const Client = () => {
//   const client = SolarSchools.ApiClient.instance;
//   client.defaultHeaders = {};
//   const apiURI = fetchEnv({ key: "API_URI", defaultValue: "default" });
//   client.basePath = apiURI.replace(/\/+$/, "");
//   return client;
// };

/**
 * Validate bearer token and create client instance
 */
const AuthenticatedClient = (token) => {
  // const ApiClient = Client();
  // const ApiAuthorizer = ApiClient.authentications["ApiAuthorizer"];
  // ApiAuthorizer.apiKey = "Key " + token;

  const basePath = fetchEnv({ key: "API_URI", defaultValue: "default" });
  const config = new Configuration({
    baseOptions: {
      headers: {
        Authorization: `Key ${token}`,
      },
    },
    basePath: basePath.replace(/\/+$/, ""),
  });
  return config;
};

export { AuthenticatedClient };
