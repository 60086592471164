import { DataType, DataTypes } from "../../../types/dataKeys";
import { UnitType } from "../unitConstants";
const energyTypes = [
  "solarGenerated",
  "gridConsumed",
  "netConsumed",
  "solarConsumed",
  "solarFeedIn",
];
const massTypes = ["co2Saved", "co2Produced"];

export const getUnitTypeFromDataType = (dataKey: DataType) => {
  if (energyTypes.includes(dataKey)) {
    return "energy";
  } else if (massTypes.includes(dataKey)) {
    return "mass";
  } else {
    return false;
  }
};

export const getAllUnitTypes = (dataKeys: DataTypes) =>
  dataKeys.map((dataKey) => getUnitTypeFromDataType(dataKey));

export const getDataTypesFromUnitType = (unitType: UnitType) => {
  switch (unitType) {
    case "energy":
      return energyTypes;
    case "mass":
      return massTypes;
  }
};
