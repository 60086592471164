import React from "react";
import { RowContentContainer } from "./CampsStatic.styles";

export function CampsCarbonCommitment() {
  return (
    <RowContentContainer
      style={{
        backgroundImage: "url('/background-images/recycle-forest.webp')",
        backgroundSize: "cover",
        backgroundPositionX: "50%",
      }}
    >
      <div>
        <h2
          style={{
            fontSize: "3rem",
            fontWeight: "bolder",
            textAlign: "center",
            textShadow: "2px 2px 3px rgba(0, 0, 0, 1)",
          }}
        >
          Our Carbon Commitment Statement
        </h2>
      </div>

      <div>
        <p
          style={{
            fontSize: "3rem",
            textAlign: "center",
            textShadow: "2px 2px 3px rgba(0, 0, 0, 1)",
          }}
        >
          Y Discovery Camps will reduce our emissions by at least{" "}
          <span style={{ fontWeight: "bolder" }}>30%</span> before 2025
        </p>
      </div>

      <div>
        <p
          style={{
            fontSize: "3rem",
            textAlign: "center",
            textShadow: "2px 2px 3px rgba(0, 0, 0, 1)",
          }}
        >
          Aim for <span style={{ fontWeight: "bolder" }}>100%</span> emissions
          reduction towards net zero by 2030
        </p>
      </div>
    </RowContentContainer>
  );
}

export default CampsCarbonCommitment;
