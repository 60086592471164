import { useEffect } from "react";
import { useState } from "react";

export const useVariableDelayInterval = (delayArray: number[]) => {
  const [delay, setDelay] = useState(delayArray[0]);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    setDelay(delayArray[counter]);
    const interval = setTimeout(() => {
      if (counter === delayArray.length - 1) {
        setCounter(0);
      } else {
        setCounter(counter + 1);
      }
    }, delay);

    return () => clearTimeout(interval); // Clears the interval otherwise React will keep creating a new interval
  });

  return counter;
};
