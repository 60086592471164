import React from "react";
import { getStarryBackgroundSketch } from "./StarryBackground.sketch";
import { useP5 } from "./useP5";

export const StarryBackground = () => {
  const sketch = getStarryBackgroundSketch();
  const { ref } = useP5(sketch);

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        zIndex: -1,
        height: "100%",
        width: "100%",
      }}
    />
  );
};
