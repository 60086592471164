import React, { FunctionComponent } from "react";
import { FeedTotalsContainer } from "./FeedTotals.styles";
import StatBox from "./StatBox/StatBox";
import { useTheme } from "../../../hooks/Context Hooks/theming/useTheme";
import { getUnitAndValue } from "../../../utilities/DataUtil/getUnitAndValue";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";
import { TimeSpan } from "../../../types/dataKeys";

// Takes an array of components in an array and renders them in a row
const FeedTotals: FunctionComponent<{ suffix?: string }> = ({ suffix }) => {
  const { timeSpan } = useSlideContext();
  const data = useTimeSpanData(timeSpan, suffix);
  const theme = useTheme();
  const [gridValue, gridUnit] = getUnitAndValue(
    data.totals.gridConsumed,
    "energy"
  );
  const [netValue, netUnit] = getUnitAndValue(
    data.totals.netConsumed,
    "energy"
  );
  const [solarValue, solarUnit] = getUnitAndValue(
    data.totals.solarGenerated,
    "energy"
  );
  const [co2SavedValue, co2SavedUnit] = getUnitAndValue(
    data.totals.co2Saved,
    "mass"
  );

  const timeSpanLabelMap: Record<TimeSpan, string> = {
    daily: "Today's",
    weekly: "Last 7 days'",
    monthly: "Last 30 days'",
    yearly: "Last 12 months'",
    lifetime: "Lifetime",
    scheduled: "Scheduled",
  };

  const timeSpanLabel = timeSpanLabelMap[timeSpan];

  return (
    <FeedTotalsContainer>
      <StatBox
        title={`${timeSpanLabel} net consumption`}
        stat={`${netValue} ${netUnit}`}
        color={theme.palette.dataKeys.netConsumed}
        icon={"metric-icons/Net Consumption.svg"}
      />
      <StatBox
        title={`${timeSpanLabel} grid consumption`}
        stat={`${gridValue} ${gridUnit}`}
        color={theme.palette.dataKeys.gridConsumed}
        icon={"metric-icons/Grid Consumption.svg"}
      />
      <StatBox
        title={`${timeSpanLabel} solar generation`}
        stat={`${solarValue} ${solarUnit}`}
        color={theme.palette.dataKeys.solarGenerated}
        icon={"metric-icons/Solar Generation.svg"}
      />
      <StatBox
        title={`${timeSpanLabel} co₂ avoided`}
        stat={`${co2SavedValue} ${co2SavedUnit}`}
        color={theme.palette.dataKeys.co2Saved}
        icon={"metric-icons/CO2 Saved.svg"}
      />
    </FeedTotalsContainer>
  );
};

export default FeedTotals;
