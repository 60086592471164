import React, { FunctionComponent, Fragment } from "react";
import { StyledThemeProvider } from "./ThemeProvider.core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import { useThemeName } from "../../hooks/Context Hooks/theming/useThemeName";
import { ThemedGlobalStyles } from "./globalStyles";
import { getTheme } from "../../utilities/Theming/getTheme";

export const ThemeProvider: FunctionComponent = ({ children }) => {
  const themeName = useThemeName();
  const theme = getTheme(themeName);

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={{ ...theme }}>
          <Fragment>
            <ThemedGlobalStyles theme={theme} />
            <CssBaseline />
            {children}
          </Fragment>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
