import { EnergyFootprintTheme } from "../../providers/ThemeProvider/themes/EnergyFootprint";
import { SolarSchoolsTheme } from "../../providers/ThemeProvider/themes/SolarSchools";
import { ZeroPositiveTheme } from "../../providers/ThemeProvider/themes/ZeroPositive";

export const getTheme = (themeName: string) => {
  switch (themeName) {
    case "solarschools":
      return SolarSchoolsTheme;
    case "zeropositive":
      return ZeroPositiveTheme;
    case "energyfootprint":
      return EnergyFootprintTheme;
    default:
      return EnergyFootprintTheme;
  }
};
