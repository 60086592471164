import styled from "../../../../providers/ThemeProvider/ThemeProvider.core";

export const OffsetEquivilentContent = styled.div({
  background: "#F1F5F9",
  display: "grid",
  gridTemplateColumns: "1fr 2fr",
  padding: "1rem",
  gap: "1rem",
  width: "100%",
  height: "100%",
});
