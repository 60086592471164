import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import React, { useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import { PieChartProps } from "./PieChart.types";
am4core.useTheme(am4themes_animated);

export function PieChart(props: PieChartProps) {
  const chartRef = useRef<am4charts.PieChart | null>(null);
  const chartId = `piechart-${props.id}`;

  useLayoutEffect(() => {
    const chart = am4core.create(chartId, am4charts.PieChart);

    chart.data = props.seriesData.map((datum) => {
      return {
        [props.categoryKey]: datum.category,
        [props.valueKey]: datum.value,
      };
    });

    chart.innerRadius = am4core.percent(40);

    const pieSeries = chart.series.push(new am4charts.PieSeries());

    pieSeries.dataFields.value = props.valueKey;
    pieSeries.dataFields.category = props.categoryKey;
    pieSeries.labels.template.disabled = true;
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, []);

  return <div id={chartId} style={{ width: "100%", height: "100%" }} />;
}

export default PieChart;
