import { FlowLink } from "../EnergyFlow.styled";
import { ElementConfig, Path } from "../EnergyFlow.types";
import { Theme } from "@material-ui/core";
import React, { Fragment } from "react";

type Props = {
  paths: Array<Path>;
  elementConfigs: Array<ElementConfig>;
  theme: Theme;
};

export const EnergyLinks = ({ paths, elementConfigs, theme }: Props) => {
  return (
    <Fragment>
      {paths.map(({ path: pathString, fromIndex, value, dataKey }, index) => (
        <FlowLink
          d={pathString}
          strokeDasharray={value ? 0 : 4} // dashed stroke for inactive links
          key={index}
          stroke={theme.palette?.dataKeys?.[dataKey] || "#a6a6a6"}
        />
      ))}
    </Fragment>
  );
};
