import { FlowDot, OuterFlowDot } from "../EnergyFlow.styled";
import {
  getAnimateAtrributes,
} from "../EnergyFlow.utils";
import { lighten } from "@material-ui/core";
import React, { Fragment } from "react";
import { settings } from "../EnergyFlow.consts";

type Props = {
  paths?: Array<string>;
  color: string;
};

export const SubElementDots = ({ paths, color}: Props) => paths ? (
    <Fragment>
      {paths.map((path, index) => {
        const duration = settings.subElementDotDuration;
        return (
          <Fragment key={index}>
            <OuterFlowDot stroke={lighten(color, 0.4)}>
              <animateMotion
                path={path}
                {...getAnimateAtrributes(duration, false)}
              />
            </OuterFlowDot>
            <FlowDot fill={lighten(color, 0.4)}>
              <animateMotion
                path={path}
                {...getAnimateAtrributes(duration, false)}
              />
            </FlowDot>
          </Fragment>
        );
      })}
    </Fragment>
  ) : null;
