import React, { FunctionComponent } from "react";
import {
  PanelInfoBoxContainer,
  PanelTitle,
  PanelData
} from "./PanelInfoBox.styles";
import { PanelInfoBoxProps } from "./PanelInfoBox.types";

const PanelInfoBox: FunctionComponent<PanelInfoBoxProps> = ({
  title,
  info
}) => {
  return (
    <PanelInfoBoxContainer>
      <PanelTitle variant="h6">{title}</PanelTitle>
      <PanelData variant="h5">{info || "N/A"}</PanelData>
    </PanelInfoBoxContainer>
  );
};

export default PanelInfoBox;
