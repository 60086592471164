export const noDataColor = "#5E5D63";
export const rangeColors = [
  "#19d228",
  "#b4dd1e",
  "#f4fb16",
  "#fb7116",
  "#CC1000",
];

/*
 * Returns the gradient color for a given normalized value
 */
export const getGradientColor = (
  value: number,
  reversed?: boolean,
  forceColors?: string[]
) => {
  const _colors = forceColors ? forceColors.slice(0) : rangeColors.slice(0); // creates a copy of the colors rather than a reference -- so it can be reversed without causing issues
  if (reversed) {
    _colors.reverse();
  }

  if (value) {
    // hex to rgb converter
    const hexToRgb = (hex: string) => {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
          ]
        : null;
    };

    // get the surrounding _colors
    const n = _colors.length - 1;
    const threshold = 1 / n;
    const pivot = value / threshold;
    const minColor = hexToRgb(_colors[Math.floor(pivot)]);
    const maxColor = hexToRgb(_colors[Math.ceil(pivot)]);
    const normalizedValueBetweenColors =
      Math.round((value % threshold) * 100) / 100 / threshold;

    // get the weighted colour from the normalized value and surrounding colours
    var weight = 1 - normalizedValueBetweenColors;
    var rgb = maxColor &&
      minColor && [
        Math.round(
          maxColor[0] * normalizedValueBetweenColors + minColor[0] * weight
        ),
        Math.round(
          maxColor[1] * normalizedValueBetweenColors + minColor[1] * weight
        ),
        Math.round(
          maxColor[2] * normalizedValueBetweenColors + minColor[2] * weight
        ),
      ];

    // console.log(`%c value: ${value} ---- ${rgb}`, `background: rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}); color: #fff`);
    return rgb ? `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})` : "";
  } else {
    return noDataColor;
  }
};

/**
 * Retreives the planet image from a given percentile
 */
export const getPlanetImagePath = (co2Coeff: number) => {
  if (co2Coeff < 20) {
    return "planets/0.png";
  } else if (co2Coeff < 40) {
    return "planets/1.png";
  } else if (co2Coeff < 60) {
    return "planets/2.png";
  } else if (co2Coeff < 80) {
    return "planets/3.png";
  } else {
    return "planets/4.png";
  }
};
