import p5 from "p5";
import { Star } from "./Star";

export const getStarryBackgroundSketch = () => {
  return (p: p5) => {
    const stars: Array<Star> = [];
    const starFrequency = 2;
    let starIdIncrement = 4;

    const drawBackground = () => {
      p.background("#121212");
    };

    const handleStars = () => {
      // see if star will be created (1 out of starFrequency)
      if (Math.round(p.random(1, starFrequency)) === starFrequency) {
        const x = p.random(0, p.width);
        const y = p.random(0, p.height);
        stars.push(
          new Star({
            p,
            id: starIdIncrement,
            x,
            y,
            onDeath: (id) =>
              stars.splice(
                stars.findIndex((star) => star.id === id),
                1
              ),
          })
        );
        starIdIncrement++;
      }

      // draw current stars
      stars.forEach((star) => star.draw());
    };

    p.setup = () => {
      p.createCanvas(p.windowWidth, p.windowHeight);

      // init some stars
      Array(150)
        .fill(0)
        .forEach(() => {
          const x = p.random(0, p.width);
          const y = p.random(0, p.height);
          stars.push(
            new Star({
              p,
              id: starIdIncrement,
              lifeTick: p.random(25, 100),
              x,
              y,
              onDeath: (id) =>
                stars.splice(
                  stars.findIndex((star) => star.id === id),
                  1
                ),
            })
          );
          starIdIncrement++;
        });

      p.smooth();
      p.noStroke();
      p.frameRate(50);
      drawBackground();
    };

    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
      drawBackground();
    };

    p.draw = () => {
      drawBackground();
      handleStars();
    };
  };
};
