import { FlowDot, OuterFlowDot } from "../EnergyFlow.styled";
import { ElementConfig, Path } from "../EnergyFlow.types";
import { lighten, Theme } from "@material-ui/core";
import React, { Fragment } from "react";
import {
  getDurationFromRelativeDifference,
  getAnimateAtrributes,
} from "../EnergyFlow.utils";

type Props = {
  paths: Array<Path>;
  elementConfigs: Array<ElementConfig>;
  theme: Theme;
};

export const EnergyDots = ({ paths, elementConfigs, theme }: Props) => {
  const highestValue = Math.max.apply(
    Math,
    paths.map(function(o) {
      return isNaN(o.value) ? 0 : o.value;
    })
  );

  return (
    <Fragment>
      {paths.map(
        ({ path: pathString, reversed, fromIndex, value, dataKey }, index) => {
          const relativeDifference = value ? highestValue / value : NaN;
          const duration = getDurationFromRelativeDifference(
            relativeDifference
          );
          return (
            <Fragment key={index}>
              <OuterFlowDot
                stroke={lighten(
                  theme.palette?.dataKeys?.[dataKey] || "#a6a6a6",
                  0.4
                )}
              >
                <animateMotion
                  path={pathString}
                  {...getAnimateAtrributes(duration, !!reversed)}
                />
              </OuterFlowDot>
              <FlowDot
                fill={lighten(
                  theme.palette?.dataKeys?.[dataKey] || "#a6a6a6",
                  0.4
                )}
              >
                <animateMotion
                  path={pathString}
                  {...getAnimateAtrributes(duration, !!reversed)}
                />
              </FlowDot>
            </Fragment>
          );
        }
      )}
    </Fragment>
  );
};
