import { UnitShorthand } from "../../../types/unitShorthand";
import { areSameUnitType } from "../../../utilities/DataUtil/old/areSameUnitType";
import { getUnitTypeFromDataType } from "../../../utilities/DataUtil/old/getUnitTypeFromDataType";
import { to2FixedIfNeeded } from "../../../utilities/DataUtil/to2FixedIfNeeded";
import { convertHex } from "../../../utilities/Theming/convertHex";
import { getUnitAndValue } from "../../../utilities/DataUtil/getUnitAndValue";
import { getDataTypeName } from "../../../utilities/Parsing/dataKeys";
import { Theme } from "@material-ui/core";
import { DataTypes } from "../../../types/dataKeys";
import { convertToUnitFromLowest } from "../../../utilities/DataUtil/old/convertToUnitFromLowest";
import { UnitType } from "../../../utilities/DataUtil/unitConstants";

export const getPieOptions = (
  data: any,
  dataKeys: DataTypes,
  theme: Theme,
  resourceName: string
) => {
  // Titles/Data/Colors from datatypes
  const chartTitles = [
    getDataTypeName(dataKeys[0]),
    getDataTypeName(dataKeys[1]),
  ];
  const chartData = [data?.totals[dataKeys[0]], data?.totals[dataKeys[1]]];
  const chartColors = [
    theme.palette.dataKeys[dataKeys[0] as string],
    theme.palette.dataKeys[dataKeys[1] as string],
  ];

  // Calculate the percentage values for each datum
  const sum = chartData[0] + chartData[1];
  const percents = [
    Math.round((chartData[0] / sum) * 100),
    Math.round((chartData[1] / sum) * 100),
  ];

  // Get the unit from the average between the values
  const isSameUnit = areSameUnitType([dataKeys[0], dataKeys[1]]);
  const [, unit] = isSameUnit
    ? getUnitAndValue(sum / 2, getUnitTypeFromDataType(dataKeys[0]) as UnitType)
    : [NaN, "N/A"];

  // Get the values from converting to the average unit
  const value0 =
    unit && convertToUnitFromLowest(chartData[0], unit as UnitShorthand);
  const value1 =
    unit && convertToUnitFromLowest(chartData[1], unit as UnitShorthand);

  // Get a suitable pie chart label line
  const getLabelLine = () => {
    const labelLineWidth1 = window.innerWidth * 0.08;
    const labelLineWidth2 = window.innerHeight * 0.06;

    return {
      normal: {
        show: true,
        length: labelLineWidth1,
        length2: labelLineWidth2,
        lineStyle: {
          width: 2,
        },
      },
    };
  };

  const getLabel = (data: any, percent: number, color: string) => ({
    normal: {
      show: true,
      position: "outside",
      formatter: [
        `{title|${resourceName.toUpperCase()}}`,
        "{title|{b}}",
        `{value|${to2FixedIfNeeded(data)} ${unit}}`,
        "{linebreak|}",
        "{stat|" + percent + "%}",
      ].join("\n"),
      rich: {
        title: {
          lineHeight: 13,
          fontSize: 10,
          fontWeight: 900,
        },
        value: {
          padding: [0, 0, 6, 0],
          margin: [0, 0, 6, 0],
          fontSize: 18,
        },
        linebreak: {
          display: "block",
        },
        stat: {
          fontSize: 52,
          fontWeight: "bold",
          padding: [0, 0, 0, 0],
          color: convertHex(color, 40),
        },
      },
    },
  });

  return {
    aspectRatio: 0,
    series: {
      aspectRatio: 0,
      type: "pie",
      radius: ["45%", "25%"],
      center: ["50%", "50%"],
      data: [
        {
          value: chartData[0],
          name: chartTitles[0].toString(),
          itemStyle: {
            color: chartColors[0],
          },
          label: getLabel(value0, percents[0], chartColors[0]),
          labelLine: getLabelLine(),
        },
        {
          value: chartData[1],
          name: chartTitles[1].toString(),
          itemStyle: { color: chartColors[1] },
          label: getLabel(value1, percents[1], chartColors[1]),
          labelLine: getLabelLine(),
        },
      ],
      animation: false,
      startAngle: 70,
    },
  };
};
