export const NoosaStaticContent = () => `
    <div class="wrapper">
      <div class="half-width">
        <ul>
          <li>
            Natural light, solar hot water heating, and a below ground water
            tank.
          </li>
          <li class="pt">
            Timers on air conditioning and lights all contribute to reduced
            running costs and emissions.
          </li>
          <li class="pt">
            Mixed mode ventilation system. The building can operate on natural or
            sub floor ventilation allowing cool air from the floor to a height of
            two meters.
          </li>
        </ul>
      </div>
      <div class="half-width">
        <ul>
          <li>
            Grassed rooftop controls the variation of internal temperatures
            between day and night.
          </li>
          <img src="static-content-images/cooroy-library.png" style="width: 100%; margin-top: 3rem" ></img>
        </ul>
      </div>
    </div>
    <style>
      .fullsize {
        width: 100%;
        height: 100%
      }
      .half-width {
        width: 45%;
      }
      .wrapper {
        height: 90%;
        display: flex;
        align-items; center;
        justify-content: space-between;
        font-size: 36px;
      }
      ul {
        list-style: none;
        height: 100%;
      }
      li {
        margin: 1,3rem 0;
        position: relative;
        padding: 8px;
      }
      .pt {
        margin-top: 2rem;
      }
      li:before {
        content: " ";
        background-size: cover;
        background-image: url("static-content-images/ef_bullet.svg");
        width: 1.4rem;
        position: absolute;
        left: -2rem;
        margin-top: 8px;
        height: 1.5rem;
        margin-right: 10px;
      }
    </style>
`;
