import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { getGraphColors } from "../Charts/Graph/Graph.utils";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Box, useTheme } from "@material-ui/core";
import {
  styleZoomButtonBG,
  styleValueAxis,
  styleDateAxis,
  styleLegend,
} from "../../../utilities/Charts/styles";

am4core.useTheme(am4themes_animated);

type Props = {
  height?: number | string;
  hideScroll?: boolean;
  hideLegend?: boolean;
  data: Array<object>;
  graphId?: string;
  isLine?: boolean;
};

export const SubMeterBreakdownChart: FunctionComponent<Props> = ({
  height = 500,
  hideScroll,
  hideLegend,
  graphId,
  isLine,
  data,
}) => {
  const theme = useTheme();
  const chart = useRef<am4charts.XYChart | null>(null);
  const autoSeriesColorIndex = useRef(0);

  useEffect(() => {
    chart.current = am4core.create(`chart${graphId}`, am4charts.XYChart);
    return () => chart?.current?.dispose();
  }, [graphId]);

  // Process
  useEffect(() => {
    autoSeriesColorIndex.current = 0; // reset default colors index

    if (chart?.current) {
      chart.current.dispose();
      chart.current = am4core.create(`chart${graphId}`, am4charts.XYChart);

      if (data) {
        // Initial styles
        chart.current.paddingTop = 20; // add padding if theres weather icons
        chart.current.data = data;
        chart.current.numberFormatter.numberFormat = "#.##s";
        chart.current.paddingRight = 50;
        styleZoomButtonBG(chart.current.zoomOutButton.background, theme);

        // Axes =====
        let dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
        styleDateAxis(theme, dateAxis);

        let valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
        styleValueAxis(theme, valueAxis);
        valueAxis.title.text = `[bold]Consumed Energy (kWh)[/]`;
        if (valueAxis?.tooltip) {
          valueAxis.tooltip.disabled = true;
        }

        // Scroll =====
        let scrollbarX: am4charts.XYChartScrollbar;
        if (!hideScroll) {
          scrollbarX = new am4charts.XYChartScrollbar();
          chart.current.scrollbarX = scrollbarX;
        }

        if (data?.[0]) {
          const vmKeys = Object.keys(data[0]).filter((key) => key !== "time");
          vmKeys.forEach((dataKey: string) => {
            if (chart.current) {
              const series = chart.current.series.push(
                isLine
                  ? new am4charts.LineSeries()
                  : new am4charts.ColumnSeries()
              );

              series.dataFields.dateX = "time";
              series.dataFields.valueY = dataKey;
              series.name = dataKey;
              series.stacked = true;
              series.showOnInit = true;
              series.yAxis = valueAxis;
              series.strokeWidth = 1;
              series.fillOpacity = 0.85;
              if (isLine) {
                (series as am4charts.LineSeries).tensionX = 0.77;
              }
              autoSeriesColorIndex.current++;
              const colors = getGraphColors();
              series.fill = am4core.color(
                colors[autoSeriesColorIndex.current % 9]
              );
              series.stroke = am4core.color(
                colors[autoSeriesColorIndex.current % 9]
              );

              if (!hideScroll) {
                scrollbarX.series.push(series);
              }
            }
          });
        }

        // Legends
        if (!hideLegend) {
          chart.current.legend = new am4charts.Legend();
          chart.current.legend.labels.template.truncate = false;
          styleLegend(chart.current.legend);
          chart.current.legend.labels.template.fontSize = 14;
        }
      }
    }
  }, [hideScroll, hideLegend, graphId, theme, data]);

  return <Box width="100%" height={height} id={`chart${graphId}`} />;
};

export default SubMeterBreakdownChart;
