import { SmallBarComparison } from "../../_shared/SmallBarComparison/SmallBarComparison";
import { DisplayBoardContext } from "../../../Context";
import { useTheme, darken } from "@material-ui/core";
import { DataType } from "../../../types/dataKeys";
import React, { useContext } from "react";

export const SolarVsGridBars = () => {
  const ctx = useContext(DisplayBoardContext);
  const theme = useTheme();

  const item1 = {
    value: ctx?.data?.dailyEnergy?.totals?.solarGenerated || 0,
    name: "Solar",
    // color: theme.palette.dataKeys?.solarGenerated || "#24A21A",
    color: theme.palette.common.white,
    dataKey: "solarGenerated" as DataType,
  };

  const item2 = {
    value: ctx?.data?.dailyEnergy?.totals?.gridConsumed || 0,
    name: "Grid",
    // color: theme.palette.dataKeys?.gridConsumed || "#808080",
    color: darken(theme.palette.common.white, 0.2),
    dataKey: "gridConsumed" as DataType,
  };

  return <SmallBarComparison item1={item1} item2={item2} />;
};
