import styled from "../../../providers/ThemeProvider";

export const RowContentContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateRows: "1fr 1fr 1fr",
  placeItems: "center",
  gap: "2rem",
  padding: "2rem",
  borderRadius: "12px",
  overflow: "hidden",
  color: "white",
});

export const ColumnContentContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "2rem",
});

export const ColumnContentItem = styled.div({
  color: "white",
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateRows: "1fr 1fr",
  placeItems: "center",
  padding: "2rem",
  borderRadius: "12px",
  overflow: "hidden",
});
