import { UnitType } from "./unitConstants";
import { getUnitTypeFromDataKey } from "./getUnitType";

/** Splits the datakeys into two arrays of different unit types */
export const splitDataKeysByUnitType = (dataKeys: string[]) => {
  let primaryUnitType: UnitType;
  let secondaryUnitType: UnitType;
  let primary: string[] = [];
  let secondary: string[] = [];
  let outliers: object[] = []; // no use but maybe for debugging

  dataKeys.forEach((dataKey) => {
    const unitType = getUnitTypeFromDataKey(dataKey);

    if (primaryUnitType) {
      if (unitType === primaryUnitType) {
        primary.push(dataKey);
      } else {
        if (secondaryUnitType) {
          if (unitType === secondaryUnitType) {
            secondary.push(dataKey);
          } else {
            outliers.push({ dataKey, unitType });
          }
        } else {
          secondaryUnitType = unitType;
          secondary.push(dataKey);
        }
      }
    } else {
      primaryUnitType = unitType;
      primary.push(dataKey);
    }
  });
  return { primary, secondary, outliers };
};
