import { SideBarContainerProps } from "./SideBar.types";
import styled from "../../providers/ThemeProvider";

export const SideBarContainer = styled.div<SideBarContainerProps>(
  ({ backgroundImage, themeName, theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    zIndex: theme.zIndex.appBar,
    height: "100%",
    width: "300px",
    backgroundImage: backgroundImage
      ? `url(background-images/${backgroundImage}.png)`
      : `url(theme-images/${themeName}/sidebar.jpg)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  })
);

export const SideBarContentContainer = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(4, 0, 4, 0),
  alignItems: "center",
}));

export const TopLogoContainer = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

export const TopLogoDefault = styled.h1({
  color: "white",
  width: "80%",
  fontWeight: "bold",
  textAlign: "center",
  margin: "0 auto",
  paddingTop: "2rem",
});

export const TopLogo = styled.img<{ disableFilter?: boolean }>(
  ({ theme, disableFilter }) => ({
    margin: theme.spacing(4, 0, 4, 0),
    width: "auto",
    maxWidth: "80%",
    height: "auto",
    maxHeight: "100px",
    display: "block",

    ...(theme.palette.lightSidebarBg &&
      !disableFilter && {
        filter: "brightness(0.125)",
      }),
  })
);

export const WeatherDateContainer = styled.div({
  width: "80%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const SolarEfficiencyGaugeContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "60%",
});

export const BottomLogoContainer = styled.div({
  display: "flex",
  marginTop: "auto",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

export const BottomLogo = styled.img(({ theme }) => ({
  maxWidth: "80%",
  marginBottom: "1rem",
  height: "auto",
  display: "block",
  ...(theme.palette.lightSidebarBg && {
    filter: "brightness(0.125)",
  }),
}));
