import React, { FunctionComponent } from "react";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";
import { EnergyHeatmap } from "../Charts/EnergyHeatmap/EnergyHeatmap";
import { TimeSpan } from "../../../types/dataKeys";

const HeatMap: FunctionComponent<{}> = () => {
  const {
    timeSpan,
    dataKey: [dataKey],
  } = useSlideContext();
  const data = useTimeSpanData(timeSpan as TimeSpan);

  return <EnergyHeatmap data={data} dataKey={dataKey} height="100%" />;
};

export default HeatMap;
