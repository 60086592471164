import React, { useEffect, useState, FunctionComponent } from "react";
import { DateTimeBoxProps } from "./DateTimeBox.types";
import { getCurrentDateTime } from "./DateTimeBox.utils";
import { TextContainer, DateTimeBoxContainer } from "./DateTimeBox.styles";

export const DateTimeBox: FunctionComponent<DateTimeBoxProps> = ({
  delay = 1000
}) => {
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  const updateTime = () => {
    setCurrentDateTime(getCurrentDateTime());
  };

  useEffect(() => {
    updateTime();
    const dateData = setInterval(updateTime, delay);
    return () => clearInterval(dateData);
  }, []);

  return (
    <DateTimeBoxContainer>
      <TextContainer>
        {currentDateTime.time}
        <b> {currentDateTime.day}</b>
      </TextContainer>
      <TextContainer>{currentDateTime.date}</TextContainer>
    </DateTimeBoxContainer>
  );
};

export default DateTimeBox;
