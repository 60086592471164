import {
  LeaderboardEnergyStat,
  LeaderboardSiteContainer,
} from "./LeaderboardSite.styled";
import { SiteRank } from "../SiteLeaderboards.types";
import { HexagonIcon } from "../Icons/HexagonIcon";
import React, { Fragment, useMemo } from "react";
import { TrophyIcon } from "../Icons/TrophyIcon";
import { Box } from "@material-ui/core";
import { autoFormatValue } from "../../../../utilities/DataUtil/convertUnit";

type Props = {
  index: number;
  site: SiteRank;
};

export const LeaderboardSite = ({ site, index }: Props) => {
  const trophyColorLight = useMemo(() => {
    if (index === 0) {
      return "#C9B037";
    }
    if (index === 1) {
      return "#D7D7D7";
    }
    if (index === 2) {
      return "#AD8A56";
    }

    return null;
  }, [index]);

  const trophyColorDark = useMemo(() => {
    if (index === 0) {
      return "#Af9500";
    }
    if (index === 1) {
      return "#B4B4B4";
    }
    if (index === 2) {
      return "#6A3805";
    }

    return null;
  }, [index]);

  return (
    <LeaderboardSiteContainer first={index === 0}>
      <Box>
        {site.siteName}
        <LeaderboardEnergyStat>
          {autoFormatValue(
            site.netConsumed || site.netConsumedAvg || 0,
            "netConsumed"
          )}
        </LeaderboardEnergyStat>
      </Box>
      {trophyColorLight && trophyColorDark && (
        <Fragment>
          <TrophyIcon
            fill="#FCFCFC"
            style={{
              position: "absolute",
              right: "18px",
              height: "16px",
              width: "16px",
            }}
          />
          <HexagonIcon
            stroke={trophyColorLight}
            fill={trophyColorDark}
            style={{ marginRight: "8px", width: "36px", height: "36px" }}
          />
        </Fragment>
      )}
    </LeaderboardSiteContainer>
  );
};
