export const getMagnitude = (value: number) => {
  return Math.ceil(Math.log10(Math.abs(value) + 1)) - 1;
};

export const getUnitIndexFromMagnitude = (magnitude: number) => {
  if (isNaN(magnitude)) {
    return 0;
  } else if (magnitude < 3) {
    return 0;
  } else if (magnitude < 6) {
    return 1;
  } else if (magnitude < 9) {
    return 2;
  } else if (magnitude < 12) {
    return 3;
  } else {
    return 4;
  }
};

export const getUnitDivisorFromMagnitude = (magnitude: number) => {
  if (magnitude < 3) {
    return 1;
  } else if (magnitude < 6) {
    return 1e3;
  } else if (magnitude < 9) {
    return 1e6;
  } else if (magnitude < 12) {
    return 1e9;
  } else {
    return 1e12;
  }
};
