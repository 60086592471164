import React, { useMemo } from "react";
import { OffsetEquivilentProps } from "./OffsetEquivilent.types";
import { OffsetEquivilentContent } from "./OffsetEquivilent.styles";
import {
  WidgetHeader,
  WidgetHeading,
  WidgetImage,
  WidgetWrapper,
} from "../SiteEvent.styles";

export function OffsetEquivilent(props: OffsetEquivilentProps) {
  const TREE_YEARLY_SEQUESTRATION_GRAMS = 21770;

  const treeCountAlt = useMemo(() => {
    if (props.totalGuests <= 0) {
      return 0;
    }

    return Math.ceil(
      (props.co2Emitted / TREE_YEARLY_SEQUESTRATION_GRAMS / props.totalGuests) *
        props.totalGuests
    );
  }, []);

  const treeCount = useMemo(() => {
    if (props.daysDuration <= 0) {
      return 0;
    }

    const count = Math.ceil(
      props.co2Emitted /
        ((props.daysDuration / 365) * TREE_YEARLY_SEQUESTRATION_GRAMS)
    );

    return count;
  }, []);

  const perGuestTrees = props.totalGuests
    ? Math.ceil(treeCount / props.totalGuests)
    : 0;

  return (
    <WidgetWrapper>
      <WidgetHeader>
        <WidgetHeading>Offset Equivalent</WidgetHeading>
      </WidgetHeader>
      <OffsetEquivilentContent>
        <div style={{ width: "100%" }}>
          <WidgetImage
            src={`/miscellaneous/ER_CO2_Tree.png`}
            alt="Infographic of a tree"
          />
        </div>

        <div style={{ position: "relative" }}>
          <p
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              margin: 0,
              color: "#22c55e",
            }}
          >
            {treeCountAlt} <span style={{ fontSize: "1rem" }}>trees</span>
          </p>
          <p style={{ margin: 0, marginBottom: "1rem" }}>
            need planting to offset the CO₂e emitted
          </p>
          <div style={{ position: "absolute", bottom: "2rem", width: "100%" }}>
            <p style={{ fontWeight: "bold", margin: 0, textAlign: "right" }}>
              Per Group ({props.totalGuests})
            </p>
          </div>
        </div>
      </OffsetEquivilentContent>
    </WidgetWrapper>
  );
}

export default OffsetEquivilent;
