import { EnergyFlowSettings } from "./EnergyFlow.types";

// TODO replace realPower with battery stufff if its ever made
export const settings: EnergyFlowSettings = {
  elementWidth: 100,
  linkOffsetLength: 10,
  elementLabelFontSize: 11,
  topPadding: 36,
  fastestDotDuration: 0.75, // seconds for fastest dot to complete cycle (used as reference to slow other dots)
  subElementBranchPadding: 50,
  subElementWidth: 40,
  subElementBranchLength: 70,
  subElementDotDuration: 2,
  dotWidth: 15,
  elementConfigs: [
    {
      name: "Grid", // display name
      linksTo: [
        {
          index: 1,
          reversed: true,
          fromSlot: 0,
          toSlot: 0,
          curved: true,
          dataKey: "solarFeedIn",
        },
        {
          index: 3,
          fromSlot: 2,
          toSlot: 0,
          curved: true,
          dataKey: "gridStored",
        },
        { index: 2, fromSlot: 1, toSlot: 1, dataKey: "gridConsumed" },
      ],
      orientation: "horizontal",
      dataKey: "gridConsumed",
      icon: "/icons/Metric/grid.svg#grid",
    },
    {
      name: "Solar",
      linksTo: [{ index: 3, fromSlot: 1, toSlot: 1, dataKey: "solarStored" }],
      orientation: "vertical",
      dataKey: "solarGenerated",
      icon: "/icons/Metric/solar.svg#solar",
    },
    {
      name: "Consumption",
      linksTo: [
        {
          index: 1,
          reversed: true,
          fromSlot: 0,
          toSlot: 2,
          curved: true,
          dataKey: "solarConsumed",
        },
        {
          index: 3,
          reversed: true,
          fromSlot: 2,
          toSlot: 2,
          curved: true,
          dataKey: "batteryFeedIn",
        },
      ],
      orientation: "horizontal",
      dataKey: "netConsumed",
      icon: "/icons/Metric/net.svg#net",
    },
    {
      name: "Battery",
      linksTo: [],
      orientation: "vertical",
      dataKey: "batteryStored",
      icon: "/icons/Metric/battery.svg#battery",
    },
  ],
};
