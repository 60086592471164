import styled from "../../providers/ThemeProvider";

export const ContentContainer = styled.div(({ theme }) => ({
  overflow: "hidden",
  height: "100%",
}));

export const TempTransitionContainer = styled.div(({ theme }) => ({
  height: "100%",
  width: "80%",
  position: "absolute",
}));
