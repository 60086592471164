import { UnitList, UnitValue } from "./unit-converter.types";

/**
 * Unit conversion utility for converting metric units of measure
 */
export abstract class IUnitConverter<T extends string> {
  public readonly unitList: UnitList<T>;

  constructor(unitList: UnitList<T>) {
    this.unitList = unitList.sort((a, b) => a.order - b.order);
  }

  /**
   * Converts a unit value. If no target unit value is provided it
   * will attempt to derive a best fit where the value is
   * between 1 and 1000. If a best fit calculation fails and no target
   * is provided, it will revert back to the initial unit value.
   *
   * **NOTE** Its crucial that the order of units in the instantiated converter
   * are correct as their order is used in the calculation to derive the set of
   * converted values.
   *
   * @param from Current unit value to convert from
   * @param to [Optional] Target unit value to convert to
   * @returns Newly converted unit value
   */
  convert(from: UnitValue<T>, to?: T) {
    const fromIndex = this.unitList.findIndex((x) => x.unit === from.unit);
    const conversionList = this.unitList.map((datum, index) => {
      return {
        unit: datum.unit,
        value: from.value / 1000 ** (index - fromIndex),
      };
    });

    if (to) {
      const target = conversionList.find((x) => x.unit === to);

      if (typeof target === "undefined") {
        throw Error(`Invalid unit type '${to}' used during conversion.`);
      }

      return target;
    }

    return (
      conversionList.find(({ value }) => value > 1 && value < 1000) ?? from
    );
  }

  /**
   * Convert an array of unit values
   *
   * @param from Current unit value to convert from
   * @param to Target unit value to convert to
   * @returns New list of converted unit values
   */
  convertBulk(from: UnitValue<T>[], to: T) {
    return from.map((x) => this.convert(x, to));
  }
}
