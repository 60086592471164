import React from "react";
import { parseSubscript2ToJSX } from "../../../utilities/Parsing/ParseSubscript2ToJSX";

const ImageStatBox = props => {
  return (
    <div style={{ maxWidth: "45%" }} className="position-relative">
      <img
        className="rounded-massive img-fluid"
        alt="stat"
        src={props.imagePath}
      ></img>
      <div
        className="stat-image-text white-text text-center w-100 position-absolute"
        style={{ top: "calc(50% - 3rem)" }}
      >
        <div className="h3">{parseSubscript2ToJSX(props.statTitle)}</div>
        <div className="text-shadow chart-title font-weight-bold">
          {props.stat}
        </div>
      </div>
    </div>
  );
};

export default ImageStatBox;
