import React from "react";
import { Box } from "@material-ui/core";
import {
  SmallBar,
  SmallBarComparisonWrap,
  SmallBarContainer,
  SmallBarText,
  SmallBarValueLabel,
} from "./SmallBarComparison.styled";
import { autoFormatValue } from "../../../utilities/DataUtil/convertUnit";
import { DataType } from "../../../types/dataKeys";

type Item = {
  value: number;
  name: string;
  color: string;
  dataKey: DataType;
};

type Props = {
  item1: Item;
  item2: Item;
};

export const SmallBarComparison = (props: Props) => {
  const sum = props.item1.value + props.item2.value;
  const biggerKey = props.item1.value >= props.item2.value ? "item1" : "item2";
  const smallerKey = biggerKey === "item1" ? "item2" : "item1";
  const bar1Data = {
    height: "100%",
    name: props[biggerKey].name,
    color: props[biggerKey].color,
    value: autoFormatValue(props[biggerKey].value, props[biggerKey].dataKey),
    percent: `${Math.round((props[biggerKey].value / sum) * 100)}%`,
  };
  const bar2Data = {
    name: props[smallerKey].name,
    height: `${Math.round(
      (props[smallerKey].value / props[biggerKey].value) * 100
    )}%`,
    value: autoFormatValue(props[smallerKey].value, props[smallerKey].dataKey),
    color: props[smallerKey].color,
    percent: `${Math.round((props[smallerKey].value / sum) * 100)}%`,
  };

  return (
    <Box width="13rem" marginTop="3rem">
      <SmallBarComparisonWrap>
        <SmallBarContainer>
          <SmallBar barHeight={bar1Data.height} barColor={bar1Data.color} />
          <SmallBarValueLabel barHeight={bar1Data.height}>
            {bar1Data.value}
          </SmallBarValueLabel>
        </SmallBarContainer>
        <SmallBarContainer>
          <SmallBar barHeight={bar2Data.height} barColor={bar2Data.color} />
          <SmallBarValueLabel barHeight={bar2Data.height}>
            {bar2Data.value}
          </SmallBarValueLabel>
        </SmallBarContainer>
      </SmallBarComparisonWrap>
      <Box display="flex" justifyContent="space-around">
        <SmallBarText>{`${bar1Data.name} (${bar1Data.percent})`}</SmallBarText>
        <SmallBarText>{`${bar2Data.name} (${bar2Data.percent})`}</SmallBarText>
      </Box>
    </Box>
  );
};
