import React from "react";
import { EventInfoProps } from "./EventInfo.types";
import { EventGroupLogo, EventInfoContent } from "./EventInfo.styles";
import {
  WidgetHeader,
  WidgetHeading,
  WidgetWrapper,
} from "../SiteEvent.styles";

export function EventInfo(props: EventInfoProps) {
  const fallbackGroupLogo = "/miscellaneous/group-placeholder.png";

  return (
    <WidgetWrapper>
      <WidgetHeader>
        <WidgetHeading>Group Information</WidgetHeading>
      </WidgetHeader>
      <EventInfoContent>
        <div style={{ width: "100%" }}>
          <EventGroupLogo
            src={props.groupLogoUrl || fallbackGroupLogo}
            alt={`Logo for the group ${props.groupName}`}
          />
        </div>
        <dl
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            fontSize: "14px",
          }}
        >
          <dt style={{ fontWeight: "bold" }}>Group Name</dt>
          <dd>{props.groupName}</dd>

          <dt style={{ fontWeight: "bold" }}>Total Guests</dt>
          <dd>{props.totalGuests}</dd>

          <dt style={{ fontWeight: "bold" }}>Arrival Date</dt>
          <dd>{props.arrivalDate}</dd>

          <dt style={{ fontWeight: "bold" }}>Departure Date</dt>
          <dd>{props.departureDate}</dd>

          <dt style={{ fontWeight: "bold" }}>Duration of Stay</dt>
          <dd>{props.duration} days</dd>
        </dl>
      </EventInfoContent>
    </WidgetWrapper>
  );
}

export default EventInfo;
