import styled from "../../../providers/ThemeProvider";

export const GaugeContainer = styled.div(({ theme }) => ({
  "& .segment-full": {
    fill: theme.palette?.sideBarText?.main || theme.palette.common.white,
  },
  "& .segment-empty": {
    stroke: theme.palette?.sideBarText?.main || theme.palette.common.white,
  },
  color: `${theme.palette?.sideBarText?.main ||
    theme.palette.common.white} !important`,
}));
