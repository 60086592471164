import React from "react";
import * as THREE from "three";
import { autoFormatMassValue } from "@solarschools/component-lib/src/utilities/MassUnits";
import { useTheme } from "../../../hooks/Context Hooks/theming/useTheme";

const CO2StackInfo = ({ dataKey, co2 }) => {
  const { useRef, useEffect, useState } = React;
  const mount = useRef(null);
  const [isAnimating, setAnimating] = useState(true);
  const controls = useRef(null);
  const theme = useTheme();
  const color = { color: theme?.palette.dataKeys[dataKey] || "#5E5D63" };

  // get ballcount
  const gh = 0.1007;
  let volumeScale;

  // if g sphere
  if (co2 < 1e3) {
    volumeScale = 1;
    // if kg sphere
  } else if (co2 < 1e6) {
    volumeScale = 10;
    // if tonne sphere
  } else if (co2 < 1e9) {
    volumeScale = 100;
    // if kilotonne sphere
  } else if (co2 < 1e12) {
    volumeScale = 1e3;
    // if megatonne spheret
  } else {
    volumeScale = 1e4;
  }

  useEffect(() => {
    // Setup ===
    let viewAngle = 45;
    let nearClipping = 0.1;
    let farClipping = 1000;
    let scene = new THREE.Scene();
    let width = mount.current.clientWidth;
    let height = mount.current.clientHeight;
    let frameId;

    // Camera ===
    let camera = new THREE.PerspectiveCamera(
      viewAngle,
      width / height,
      nearClipping,
      farClipping
    );
    let renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    let sphereRadius = 1;
    camera.position.x = 0;
    camera.position.y = 0;
    renderer.setSize(width, height);
    document.body.appendChild(renderer.domElement);

    // Balls ===
    let sphereGeometry = new THREE.SphereGeometry(sphereRadius, 16, 16);
    let sphereMaterial = new THREE.MeshLambertMaterial(color);

    let sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.position.z = -3;
    sphere.position.x = 0;
    sphere.position.y = 0;
    scene.add(sphere);

    // Light ===
    let light = new THREE.PointLight(0xffffff);
    light.position.x = 0;
    light.position.y = 25;
    light.position.z = 10;
    scene.add(light);
    // let lightAngle = 0;

    const renderScene = () => {
      renderer.render(scene, camera);
    };

    const handleResize = () => {
      width = mount.current.clientWidth;
      height = mount.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderScene();
    };

    const animate = () => {
      renderScene();
      frameId = window.requestAnimationFrame(animate);
    };

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate);
      }
    };

    const stop = () => {
      cancelAnimationFrame(frameId);
      frameId = null;
    };

    mount.current.appendChild(renderer.domElement);
    window.addEventListener("resize", handleResize);
    start();

    controls.current = { start, stop };

    // cleanup
    return () => {
      stop();
      window.removeEventListener("resize", handleResize);
      mount.current.removeChild(renderer.domElement);

      for (var i = scene.children.length - 1; i >= 0; i--) {
        scene.remove(scene.children[i]);
      }
      sphereGeometry.dispose();
      sphereMaterial.dispose();
      scene.dispose();
      renderer.dispose();
      renderer.forceContextLoss();
      renderer.context = null;
      renderer.domElement = null;
      renderer = null;
    };
  }, []);

  useEffect(() => {
    if (isAnimating) {
      controls.current.start();
    } else {
      controls.current.stop();
    }
  }, [isAnimating]);

  let volume = (co2 / 1000 / 1.87).toFixed(1);
  let unit = "m³";
  if (volume > 1000) {
    volume = (volume / 1000).toFixed(1);
    unit = "km³";
  }

  return (
    <div className="d-flex flex-column w-100 h-100 justify-content-end">
      <div className="">
        <div className="d-flex h3 justify-content-start align-items-center py-3">
          <div>{gh * volumeScale}m</div>
          <img
            alt="span"
            src="miscellaneous/span.svg"
            className="pl-3"
            height="40px"
          />
          <div
            style={{ width: "40px", height: "40px" }}
            ref={mount}
            onClick={() => setAnimating(!isAnimating)}
          />
        </div>
        <div className="h3">
          <div className="py-3">
            <b>
              CO<sub>2</sub>e {dataKey === "co2Saved" ? "Avoided" : "Emitted"}:{" "}
            </b>
            {autoFormatMassValue(co2 ?? 0)}
            <br />
            <b>Total Volume: </b>
            {volume} {unit} <br />
          </div>
          <div className="py-3">
            <i>Calculated given 15°C and standard pressure</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CO2StackInfo;
