import styled from "../../../providers/ThemeProvider";

export const PVInfoContainer = styled.div(({ theme }) => ({
  height: "70%",
  overflow: "hidden",
  width: "100%",
  padding: theme.spacing(5),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.grey[200]
}));

export const PVInfoContent = styled.div({
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center"
});

export const PVImage = styled.img({
  maxWidth: "100%",
  height: "65%",
  width: "auto"
});

export const InfoColumn = styled.div(({ theme }) => ({
  height: "65%",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  marginLeft: theme.spacing(8)
}));

export const InfoRow = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around"
}));

export const PVInfoStoriesContainer = styled.div(({ theme }) => ({
  padding: theme.spacing(6),
  height: "700px",
  justifyContent: "space-between"
}));
