import { autoFormatValue } from "../../../utilities/DataUtil/convertUnit";
import { getPlanetImagePath } from "./PlanetWatch.utils";
import { DisplayBoardContext } from "../../../Context";
import { PlanetWatchRange } from "./PlanetWatchRange";
import { StarryBackground } from "./StarryBackground";
import React, { Fragment, useContext, useMemo } from "react";
import {
  RegularPlanetContainer,
  PlanetWatchContainer,
  LivePlanetContainer,
  SmallPlanetImage,
  PlanetWatchLogo,
  StatsContainer,
  PlanetImage,
  StatsBox,
} from "./PlanetWatch.styles";
import { Box } from "@material-ui/core";

export const PlanetWatch = () => {
  const ctx = useContext(DisplayBoardContext);
  const regularData = ctx.data?.planetWatch?.regular;
  const liveData = ctx.data?.planetWatch?.live;
  const regularScore =
    regularData && regularData?.normalisedConsumptionPercentile
      ? 100 - regularData.normalisedConsumptionPercentile
      : null;

  const liveScore =
    liveData && liveData?.normalisedConsumptionPercentile
      ? 100 - liveData.normalisedConsumptionPercentile
      : null;

  // stops it from dying if it happens to refresh data during the slide
  const starryBG = useMemo(() => <StarryBackground />, []);

  return (
    <PlanetWatchContainer>
      {regularScore !== null && liveScore !== null ? (
        <Fragment>
          <PlanetWatchLogo src="logos/planetwatch.png" />
          <RegularPlanetContainer>
            <PlanetImage src={getPlanetImagePath(liveScore)} />
          </RegularPlanetContainer>
          {starryBG}
          <StatsContainer>
            <StatsBox noBorder={1}>
              <b>DETAILS</b>
              <div>
                Location: {regularData.city}, {regularData.country}
              </div>
              <div>Time: {liveData.localTime}</div>
              <div>Students: {liveData.personCount}</div>
              <br />
              <b>PAST 5 MINUTES</b>
              <div>
                Avg. Daily Student Consumption:{" "}
                <b>
                  {autoFormatValue(
                    (liveData.netEnergyConsumed * 12 * 24) /
                      liveData.personCount,
                    "netConsumed"
                  )}
                </b>
              </div>
            </StatsBox>
            <br />
            <br />
            <StatsBox>
              <b>PAST 2 WEEKS</b>
              <br />
              <div>
                Avg. Daily Student Consumption:{" "}
                <b>
                  {autoFormatValue(
                    regularData.netEnergyConsumed / regularData.personCount,
                    "netConsumed"
                  )}
                </b>
              </div>
              <br />
              <br />
              <LivePlanetContainer>
                <SmallPlanetImage src={getPlanetImagePath(regularScore)} />
              </LivePlanetContainer>
            </StatsBox>
          </StatsContainer>
          <PlanetWatchRange value={liveScore} />
        </Fragment>
      ) : (
        <Box
          display="flex"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ color: "#808080" }}>
            This school does not have Planet Watch data
          </div>
        </Box>
      )}
    </PlanetWatchContainer>
  );
};
