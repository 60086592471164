import { getTimeSpanFromID } from "./utilities/Parsing/dataKeys";
import { getComponentFromID } from "./utilities/SlideUtils/idManipulation";

export const slideComponents = {};

export type SlideComponentID =
  | "siteEvent"
  | "campsCarbonCommitment"
  | "campsEnergySavingTips"
  | "line"
  | "heatmap"
  | "bar"
  | "piecomp"
  | "balls"
  | "statimg"
  | "flowgraph"
  | "pvinfo"
  | "subschemastats"
  | "siteStats"
  | "systemline"
  | "planetwatch"
  | "submetersanalysis";

export const slideDataSuffix = {
  siteEvent: "SiteEvent",
  campsCarbonCommitment: "CampsCarbonCommitment",
  campsEnergySavingTips: "CampsEnergySavingTips",
  line: "Energy",
  heatmap: "Energy",
  bar: "Energy",
  piecomp: "Energy",
  balls: "Energy",
  statimg: "Energy",
  flowgraph: "Energy",
  pvinfo: "pvInfo",
  avgsitesconsumption: "siteRankings",
  planetwatch: "planetWatch",
  subschemastats: "ChildEnergy",
  systemline: "SystemEnergy",
  siteStats: "ChildEnergy",
  submeters: "SubMeterEnergy",
  submetersanalysis: "SubMetersBreakdown",
};

/**
 * Returns the necessary keys for the context values depending on what data is needed from the slides
 * @param slides
 * @param type
 */
export const getNecessarySlideDataVars = (
  slides: any[],
  type: "sites" | "schemas"
) => {
  // initial mandatory vars
  const slideDataVars = ["energyDetails", "siteRankings"];
  // type specific mandatory vars
  if (type === "schemas") {
    slideDataVars.push("summary");
  } else if (type === "sites") {
    slideDataVars.push("meters");
  } else if (type === "organisations") {
    slideDataVars.push("thisDayLastWeek");
  }

  // loop each slide
  slides?.forEach((slide) => {
    const timeSpan = getTimeSpanFromID(slide.id);
    const component = getComponentFromID(slide.id) as SlideComponentID;
    const slideDataVar = `${timeSpan ? timeSpan : ""}${
      slideDataSuffix?.[component]
    }`;

    // add to list if it doesn't already exist
    if (
      !slideDataVars.find(
        (slideDataVarComp) => slideDataVarComp === slideDataVar
      )
    ) {
      slideDataVars.push(slideDataVar);
    }
  });

  return slideDataVars;
};
