import { UnitShorthand } from "../../types/unitShorthand";

export const getFullUnitFromShorthand = (unit: UnitShorthand) => {
  switch (unit) {
    case "Wh":
      return "Watt hours";
    case "kWh":
      return "Kilowatt hours";
    case "MWh":
      return "Megawatt hours";
    case "GWh":
      return "Gigawatt hours";
    case "W":
      return "Watts";
    case "kW":
      return "Kilowatts";
    case "MW":
      return "Megawatts";
    case "GW":
      return "Gigawatts";
    case "g":
      return "Grams";
    case "kg":
      return "Kilograms";
    case "t":
      return "Tonnes";
    case "kt":
      return "Kilotonnes";
    default:
      return "Invalid unit";
  }
};
