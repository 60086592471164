import React from "react";

const StatueOfLiberty = props => {
  let { className, position, ...otherProps } = props;
  let classNames = ["", className].join(" ");

  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 115 400" xmlSpace="preserve"
      {...otherProps}
      className={classNames}
      style={Object.assign({minWidth: "3px", minHeight: "3px", fill: props.color}, position)}
      >
    <g>
      <path d="M27.38,86a80.07,80.07,0,0,1-4-30.31s-2-2.73-.5-4.22c0,0-3-.25-1.49-5L19.91,41l1.24-2.48-2-1.74L20.66,35l-1.74-.75,2-2s-3.48-2.73-3-5.71c0,0-4.73-1-2.74-5.22s1.74-3.23,0-3.23c0,0,1.24-3.23,6.22-2.73,0,0-3.24-3.48,1.24-8.2S28.87,2.73,29.12,0c0,0,3.73,9.44,0,14.41,0,0,0,1.24,4.23,2.24s.25,4.22.25,4.22a4.54,4.54,0,0,1-.75,6.21c-3.24,3-5.23,4-3.73,7.2a20.81,20.81,0,0,1,2,14.16s5.24,19.25,6.1,30.87c.08,1,.12,2,.12,2.92,0,0,1.49.5,3.24,4.47,0,0,2.24,0,1.24,4.47,0,0,.5,3.73,2.74,5.47l2.74-4s-1.74-1.49.5-4.22L46,84.72l.25-4.22L39.08,80l-.25-2,8.21.5a5.09,5.09,0,0,1,2.74-2.73l-9.21-9.94L52,74.53A6.25,6.25,0,0,1,56.75,73l1-12.42,2.49,12.67,4.23,1,10.45-8.94L66.46,76a3.27,3.27,0,0,1,3,2.48l14.44-4.22L69.95,80.5s1,.25.75,2L86.13,84,70.19,85a4.61,4.61,0,0,1,0,3.48s2.49,4.22-.25,3.23c0,0,3.73,9.19.5,10.19l2.74,9.19,2.74,3.48s3.24-1,4.48,3.23c0,0,2.74.25,6.47,16.65,0,0,6.22,2.48,6.72,5l3-6.46,2.24,1,1.24-3,13.94,7.7-1.49,2.48,1.74,1.24L110,146.58l2.49,2.73s-5,14.91-11.7,13.91c0,0-2.24,23.6-7.72,18.39,0,0-1.24,2.48-3,2.24l3.48,35.53-1.74,1,.25,6.71-2.24,1.74,1.49,2.24-2,2,1.74,13.66-3,3.23,2,25.34,2.74,3.23,5.23-.25-1,6.21,2.49-.25-.25,16.15,4.23-.25-.5,9.19h10.21L113,322.48l-6.47.5.25,66.09h4l-.25,5.71L115,395v5H0v-5.47H4.23v-5H8.71V322.48H1.49V308.32h9v-7.2h3V284.22h5v-6.46H23.9s-1-3,2.74-6.71a28.76,28.76,0,0,0,5.73-9.19l-1.49-5s-.25-2.73.5-3.48a4.27,4.27,0,0,0,.75-2.48l-.5-4.22-1-6.71s-1.49-3.73,1.49-9.69-.5-7.45-.5-7.45-2-2.73.25-4.47,1-21.37,1-21.37a9.73,9.73,0,0,1-2-6.21s-4.23-6-.5-11.43-1.49-11.68-1.49-11.68-3.73-2-.25-12.42c0,0,5.23-9.44,5.23-14.41s-8-8.2-6-10.93l2-2.73-1-2.24.75-8s-6.72,2.48-4.73-2.48,2.24-4.72-1.24-4.72S18.17,97.39,27.38,86Z"/>
    </g>
    </svg>
  );
};

export default StatueOfLiberty;
