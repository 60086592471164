import { createMuiTheme, Theme, darken } from "@material-ui/core/styles";
import { getTintPalette } from "../../ThemeProvider.utilities";

// Energy footprint colours
const orange = "#f6871f";
const grey = "#808080";
const blue = "#2aade3";
const green = "#5cbd79";
const limeGreen = "#2CAC7F";
const vividOrange = "#FFAC28";
const brown = "#9D4D24";
const blueGreen = "#0dbab1";
const darkBlue = "#233e99";
const red = "#FF4B19";
const dark = "#231F20";

export const ZeroPositiveTheme: Theme = createMuiTheme({
  palette: {
    lightSidebarBg: true,
    sideBarText: getTintPalette(dark),
    colors: {
      orange: getTintPalette(orange),
      grey: getTintPalette(grey),
      blue: getTintPalette(blue),
      green: getTintPalette(green),
      limeGreen: getTintPalette(limeGreen),
      vividOrange: getTintPalette(vividOrange),
      brown: getTintPalette(brown),
      darkBlue: getTintPalette(darkBlue),
      red: getTintPalette(red),
    },
    dataKeys: {
      solarGenerated: green,
      gridConsumed: darken(grey, 0.2),
      solarConsumed: vividOrange,
      netConsumed: "#000",
      solarFeedIn: red,
      co2Saved: limeGreen,
      co2Produced: darken(grey, 0.4),
      batteryStored: blue,
      batteryFeedIn: darken(blue, 0.35),
      gridStored: darkBlue,
      solarStored: blueGreen,
    },
    primary: {
      main: orange,
    },
    secondary: {
      main: "#808080",
    },
    error: {
      main: "#CC1C00",
    },
    text: {
      secondary: "#FFFFFF",
      primary: "#808080",
    },
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
    fontWeightRegular: 400,
    fontWeightBold: 900,
  },
});
