import { differenceInCalendarDays, parse } from "date-fns";

export const getAggregation = (startDate: Date, endDate: Date) => {
  const dayDiff = differenceInCalendarDays(
    endDate || new Date(),
    startDate || new Date()
  );
  const hourLimit = 1;
  const dayLimit = 33; // min 3 months

  if (dayDiff <= hourLimit) {
    // return null; // Let auto aggregate to 5/30 minute
    return undefined; // Rob wants 30 minute aggregs
  } else if (dayDiff < dayLimit) {
    return "day";
  } else {
    return "month";
  }
};

// Format generic data
export const formatData = (
  data: any[],
  keyOptions: any,
  primaryDataKeys: string[],
  secondaryDataKeys: string[],
  divisor: number,
  secondaryDivisor: number,
  isBar: boolean,
  secondaryExists: boolean
) => {
  const isStandardArray = Array.isArray(data); // some endpoints return object arrays with time as an attribute of an element, others return an object with time as the key itself
  let totals: any = {};

  const formatDatum = (datum: any, index: number) => {
    let valArray = [] as any[];

    const formatVal = (key: string, axisType: string) => {
      const val = keyOptions[key]?.secondaryDataKey
        ? datum[key][keyOptions[key].secondaryDataKey]
        : datum[key];

      if (totals[key]) {
        totals[key] += val;
      } else {
        totals[key] = val;
      }

      valArray.push({
        [key]:
          val !== null
            ? `${keyOptions[key]?.negative && !secondaryExists ? "-" : ""}${(
                val / (axisType === "primary" ? divisor : secondaryDivisor)
              ).toFixed(2)}`
            : null,
      });
    };
    primaryDataKeys.forEach((key) => formatVal(key, "primary"));
    secondaryDataKeys.forEach((key) => formatVal(key, "secondary"));

    const weatherIcon = datum?.weatherIcon || datum?.icon;
    // const dateString = isBar ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm:ss";
    const timeKey = datum?.time
      ? "time"
      : datum?.localDate
      ? "localDate"
      : "localTime";
    const dateString =
      datum[timeKey].length <= 10 ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm:ss";
    const obj = {
      time: parse(
        datum[timeKey].substr(0, datum[timeKey].length <= 10 ? 10 : 20),
        dateString,
        new Date()
      ),
      weatherIcon: weatherIcon && `${weatherIcon} (${index})`,
    };
    return Object.assign({}, obj, ...valArray);
  };

  if (isStandardArray) {
    return {
      values: (data as any[]).map((datum, index) => formatDatum(datum, index)),
      totals,
    };
  } else {
    return {
      values: Object.entries(data as any).map(([time, datum], index) => {
        (datum as any).time = time;
        return formatDatum(datum, index);
      }),
      totals,
    };
  }
};
export const getGraphColors = () => [
  "#6E0E0A",
  "#D74E09",
  "#F2BB05",
  "#72B01D",
  "#3F7D20",
  "#119DA4",
  "#00B4DD",
  "#124E78",
  "#454955",
  "#262528",
];

export const getDayDiff = (startDate: Date, endDate: Date) => {
  // Day diff
  return differenceInCalendarDays(
    endDate || new Date(),
    startDate || new Date()
  );
};
