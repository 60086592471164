import React, { useRef, useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { SchemaPieData, PieChartOption } from "./ChildPie.types";
import { formatData, getPieColors } from "./ChildPie.utils";
import { useTheme } from "@material-ui/core";
import styled from "../../../../providers/ThemeProvider";
am4core.useTheme(am4themes_animated);

export const Chart = styled.div<{ height?: number | string }>(({ height }) => ({
  width: "100%",
  height: height ? height : "400px",
}));

type ChildPieProps = {
  option: PieChartOption;
  data: SchemaPieData;
  height?: number | string;
};

export const ChildPie: React.FC<ChildPieProps> = ({ option, data, height }) => {
  const theme = useTheme();
  const [otherCategory, setOtherCategory] = useState<any[]>();
  const prevStates = useRef<any[]>([]);
  const pieColors = useRef(getPieColors(theme));
  const chart = useRef<am4charts.PieChart | null>(null);

  // Init graph
  useEffect(() => {
    chart.current = am4core.create("piechart", am4charts.PieChart);
    return () => chart?.current?.dispose();
  }, []);

  // Update data
  useEffect(() => {
    prevStates.current = [];
    if (chart.current) {
      chart.current.data = formatData(
        data,
        option,
        pieColors.current,
        theme,
        setOtherCategory
      );
    }
  }, [theme, data, option]);

  // Style graph
  useEffect(() => {
    if (chart?.current) {
      // Chart
      chart.current.hiddenState.properties.opacity = 0;
      chart.current.radius = am4core.percent(70);
      chart.current.innerRadius = am4core.percent(35);
      chart.current.padding(25, 25, 25, 25);
      chart.current.numberFormatter.numberFormat = "#.##";

      // Series
      chart.current.series.clear();
      var series = chart.current.series.push(new am4charts.PieSeries());
      series.dataFields.value = "value";
      series.dataFields.category = "name";

      series.slices.template.propertyFields.fill = "color";
      series.slices.template.cornerRadius = 0;
      series.slices.template.innerCornerRadius = 0;
      series.slices.template.stroke = am4core.color("#FFFFFF");
      series.slices.template.strokeWidth = 2;
      series.slices.template.clickable = false;
      series.slices.template.inert = true;
      series.slices.template.cornerRadius = 15;
      series.slices.template.tooltipText = "{name}";
      series.labels.template.html = ` <div style="color: {color}; font-size: 20px">
                                        <div style="font-weight: bold; font-size: 24px">{name}</div>
                                        <div style="font-weight: bold; font-size: 24px">{value.percent}%</div>
                                        <div>{value} {unit}</div>
                                        <div>{secondary}</div>
                                      </div>
      `;

      series.alignLabels = true;
      series.labels.template.radius = am4core.percent(15);
      series.hiddenState.properties.startAngle = 90;
      series.hiddenState.properties.endAngle = 90;

      if (series?.tooltip) {
        series.tooltip.disabled = true;
      }

      // Disabled States
      const as = series.slices.template.states.getKey("active");
      if (as) {
        as.properties.shiftRadius = 0;
      }

      // // HOVER
      // var shadow = series.slices.template.filters.push(
      //   new am4core.DropShadowFilter()
      // );
      // shadow.opacity = 0;
      // var hoverState = series.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists
      // if (hoverState) {
      //   var hoverShadow = hoverState.filters.push(
      //     new am4core.DropShadowFilter()
      //   );
      //   hoverShadow.opacity = 0.7;
      //   hoverShadow.blur = 5;
      // }

      // Legend -- disabled... for some reason removing the legend completely causing rendering issues (try going back and forth between other and the previous) ??
      if (!chart.current.legend) {
        chart.current.legend = new am4charts.Legend();
        chart.current.legend.valueLabels.template.text = "";
        // chart.current.legend.labels.template.fontSize = 20;
        // chart.current.legend.markers.template.width = 24;
        // chart.current.legend.markers.template.height = 12;
        chart.current.legend.valueLabels.template.textDecoration = "none";
        const marker = chart.current.legend.markers.template.children.getIndex(
          0
        );
        if (marker) {
          (marker as any).cornerRadius(6, 6, 6, 6);
        }
        // yep tried everything else
        // chart.current.legend.opacity = 0;
        chart.current.legend.valueLabels.template.disabled = true;
        chart.current.legend.labels.template.disabled = true;
        chart.current.legend.markers.template.width = 0;
        chart.current.legend.markers.template.height = 0;
      }
    }
  }, [theme, otherCategory, pieColors, option]);

  return <Chart height={height} id={"piechart"} />;
};
