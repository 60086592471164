import React, { FunctionComponent } from "react";
import { FactBoxContainer, ContentContainer } from "./FactBox.styles";
import {
  WhaleSlide,
  TreeSlide,
  TrainSlide,
  FridgeSlide,
  ElephantSlide,
  DinoSlide,
  CoalSlide,
  CarSlide,
} from "./Variations";
import ErrorScreen from "../ErrorScreen";
import { useThemeName } from "../../../hooks/Context Hooks/theming/useThemeName";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";

// TODO refactor with context and remove prop drilling
const FactBox: FunctionComponent<{}> = () => {
  const context = useSlideContext();

  // const {
  //   firstDate: startTime,
  //   totals: { solarGenerated },
  // } = useTimeSpanData(context.timeSpan);

  const themeName = useThemeName();
  const data = useTimeSpanData(context.timeSpan);
  const startTime = data.firstDate;
  const solarGenerated = data.totals.solarGenerated;

  if (!data.totals.solarGenerated) {
    return null;
  }

  const co2Saved = 0.707 * solarGenerated;

  if (
    !startTime ||
    co2Saved === null ||
    solarGenerated === null ||
    !context.timeSpan
  ) {
    return <ErrorScreen code={500} themeName={themeName} />;
  }

  // TODO move to application state when rewriting
  let component;
  if (!localStorage.getItem(`${context.timeSpan}-factBoxSlide`)) {
    localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "0");
  }

  switch (localStorage.getItem(`${context.timeSpan}-factBoxSlide`)) {
    case "0":
      co2Saved / 1000 > 140000
        ? localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "1")
        : localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "2");
      component = (
        <TreeSlide
          startTime={startTime}
          co2Saved={co2Saved}
          timeSpan={context.timeSpan}
        />
      );
      break;
    case "1":
      localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "2");
      component = (
        <WhaleSlide
          startTime={startTime}
          co2Saved={co2Saved}
          timeSpan={context.timeSpan}
        />
      );
      break;
    case "2":
      localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "3");
      component = (
        <FridgeSlide
          startTime={startTime}
          solarGenerated={solarGenerated}
          timeSpan={context.timeSpan}
        />
      );
      break;
    case "3":
      localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "4");
      component = (
        <ElephantSlide
          startTime={startTime}
          co2Saved={co2Saved}
          timeSpan={context.timeSpan}
        />
      );
      break;
    case "4":
      localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "5");
      component = (
        <DinoSlide
          startTime={startTime}
          co2Saved={co2Saved}
          timeSpan={context.timeSpan}
        />
      );
      break;
    case "5":
      localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "6");
      component = (
        <CoalSlide
          startTime={startTime}
          solarGenerated={solarGenerated}
          timeSpan={context.timeSpan}
        />
      );
      break;
    case "6":
      localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "7");
      component = (
        <CarSlide
          startTime={startTime}
          co2Saved={co2Saved}
          timeSpan={context.timeSpan}
        />
      );
      break;
    case "7":
      localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "0");
      component = (
        <TrainSlide
          startTime={startTime}
          solarGenerated={solarGenerated}
          timeSpan={context.timeSpan}
        />
      );
      break;
    default:
      localStorage.setItem(`${context.timeSpan}-factBoxSlide`, "0");
      break;
  }

  return (
    <FactBoxContainer>
      <ContentContainer>{component}</ContentContainer>
    </FactBoxContainer>
  );
};

export default FactBox;
