import { ErrorScreenContainerParams } from "./ErrorScreen.types";
import styled from "../../../providers/ThemeProvider";
import { Typography } from "@material-ui/core";

export const ErrorScreenContainer = styled.div<ErrorScreenContainerParams>(
  ({ theme, themeName }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "55%",
    flex: 1,
    height: "100%",
    width: "100%",
    backgroundImage: `url(theme-images/${themeName}/error-bg.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#FFFFFF",
  })
);

export const ErrorScreenHeader = styled(Typography)({
  fontWeight: 800,
});

export const ErrorScreenSubHeader = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 0),
}));

export const ErrorScreenStoriesContainer = styled.div({
  height: "450px",
  width: "850px",
});
