import React, { FunctionComponent } from "react";
import { BarComparisonProps } from "./BarComparison.types";
import {
  BarComparisonContainer,
  BarComparisonTitle,
  BarsContainer,
  Bar,
  BarText,
  LegendContainer,
  LegendElement,
  LegendColour,
  LegendText,
} from "./BarComparison.styles";
import {
  getBarComparisonConfig,
  getBarText,
  getLegendText,
  getIndexes,
} from "./BarComparison.utils";
import { useTheme } from "../../../../hooks/Context Hooks/theming/useTheme";
import { useSlideContext } from "../../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../../hooks/Context Hooks/data/useData";
import { DataTypes } from "../../../../types/dataKeys";

export const BarComparison: FunctionComponent<{}> = ({}) => {
  const { timeSpan, dataKey } = useSlideContext();
  const data = useTimeSpanData(timeSpan);
  const theme = useTheme();
  const [barData, barTitle, barHeights, unit, valid] = getBarComparisonConfig(
    theme,
    data,
    dataKey as DataTypes
  );
  const [firstIndex, secondIndex] = getIndexes(barData);

  return (
    <BarComparisonContainer>
      <BarComparisonTitle>{barTitle}</BarComparisonTitle>
      <BarsContainer>
        <Bar
          dataKey={dataKey[firstIndex + 2]}
          barHeight={barHeights[firstIndex]}
          valid={valid ? 1 : 0}
        >
          <BarText
            textcolor={theme.palette.getContrastText(
              theme.palette.dataKeys[dataKey[firstIndex]]
            )}
            valid={valid ? 1 : 0}
          >
            {getBarText(barData[firstIndex], unit)}
          </BarText>
        </Bar>
        <Bar
          dataKey={dataKey[secondIndex + 2]}
          barHeight={barHeights[secondIndex]}
          valid={valid ? 1 : 0}
        >
          <BarText
            textcolor={theme.palette.getContrastText(
              theme.palette.dataKeys[dataKey[secondIndex]]
            )}
            valid={valid ? 1 : 0}
          >
            {getBarText(barData[secondIndex], unit)}
          </BarText>
        </Bar>
      </BarsContainer>
      <LegendContainer>
        <LegendElement>
          <LegendColour
            dataKey={dataKey[firstIndex + 2]}
            valid={valid ? 1 : 0}
          />
          <LegendText>{getLegendText(dataKey[firstIndex + 2])}</LegendText>
        </LegendElement>
        <LegendElement>
          <LegendColour
            dataKey={dataKey[secondIndex + 2]}
            valid={valid ? 1 : 0}
          />
          <LegendText>{getLegendText(dataKey[secondIndex + 2])}</LegendText>
        </LegendElement>
      </LegendContainer>
    </BarComparisonContainer>
  );
};

export default BarComparison;
