import React, { useContext } from "react";
import { DisplayBoardContext } from "../../../Context";
import { SiteRankingPie } from "../Charts/SiteRankingPie/SiteRankingPie";

export const AverageSitesConsumption = () => {
  const ctx = useContext(DisplayBoardContext);
  const siteRankings = ctx?.data?.siteRankings;

  return siteRankings && <SiteRankingPie data={siteRankings} />;
};
