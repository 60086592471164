import React from "react";
import { EnergyUseProps } from "./EnergyUse.types";
import { EnergyUseContent } from "./EnergyUse.styles";
import {
  WidgetHeader,
  WidgetHeading,
  WidgetImage,
  WidgetWrapper,
} from "../SiteEvent.styles";
import { massConverter } from "../../../../utilities/unit-converter";

export function EnergyUse(props: EnergyUseProps) {
  const emission = massConverter.convert({
    unit: "g",
    value: props.totalGuests ? props.co2Emitted / props.totalGuests : 0,
  });

  return (
    <WidgetWrapper>
      <WidgetHeader>
        <WidgetHeading>Energy Emissions</WidgetHeading>
      </WidgetHeader>
      <EnergyUseContent>
        <div style={{ width: "100%" }}>
          <WidgetImage
            src={`/miscellaneous/ER_CO2_Coal.png`}
            alt="Infographic of a lump of coal"
          />
        </div>

        <div style={{ position: "relative" }}>
          <p
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              margin: 0,
              color: "#ef4444",
            }}
          >
            {Math.round(emission.value)}{" "}
            <span style={{ fontSize: "1rem" }}>{emission.unit}</span>
          </p>
          <p style={{ margin: 0 }}>
            CO₂e emitted from electricity used on site
          </p>
          <div style={{ position: "absolute", bottom: "2rem", width: "100%" }}>
            <p style={{ fontWeight: "bold", margin: 0, textAlign: "right" }}>
              Per Guest
            </p>
          </div>
        </div>
      </EnergyUseContent>
    </WidgetWrapper>
  );
}

export default EnergyUse;
