import styled from "../../providers/ThemeProvider";

export const ChartWrap = styled.div({
  flexGrow: 1,
  flex: 1,
  flexShrink: 0,
  // height: "80%",
  width: "100%",
});

export const BottomWrap = styled.div({
  marginTop: "2rem",
  display: "flex",
  flexShrink: 0,
  // height: "20%",
  alignItems: "flex-end",
  width: "100%",
});

export const Wrap = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
});
