import { useContext } from "react";
import { DisplayBoardContext } from "../../../Context";
import { ThemeName } from "../../../types/theme";

export const useThemeName = () => {
  const ctx = useContext(DisplayBoardContext) as any;
  return (ctx?.config?.theme
    ? ctx.config.theme.replace("-", "")
    : "energyfootprint") as ThemeName;
};
