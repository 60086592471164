import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";
import { EnergyFlowComponent } from "./EnergyFlow";
import { TimeSpan } from "../../../types/dataKeys";
import { EnergyFlowWrapper } from "./EnergyFlow.styled";
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { DisplayBoardContext } from "../../../Context";
import { VirtualMeter } from "../../../types/Meters";

export const EnergyFlow = () => {
  const { timeSpan } = useSlideContext();
  const data = useTimeSpanData(timeSpan as TimeSpan)?.totals;
  const {
    data: { meters },
  } = useContext(DisplayBoardContext);

  // necessary evil
  const [ref, setRef] = useState<HTMLDivElement>();
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const handleResize = () => {
      if (ref) {
        const dims = ref.getBoundingClientRect();
        setDimensions({
          width: dims.width,
          height: dims.height,
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
  }, [ref]);

  const subMeters = useMemo(
    () => meters?.virtual?.filter((meter: VirtualMeter) => meter.type === 5),
    [meters]
  );

  return (
    <EnergyFlowWrapper ref={(newRef: HTMLDivElement) => setRef(newRef)}>
      <Fragment>
        {dimensions.width && dimensions.height && (
          <EnergyFlowComponent
            data={data}
            dimensions={dimensions}
            subMeters={subMeters}
          />
        )}
      </Fragment>
    </EnergyFlowWrapper>
  );
};
