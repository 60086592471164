import styled from "../../../../providers/ThemeProvider";

export const LeaderboardWrapperContainer = styled.div(({ theme }) => ({
  width: `calc(50% - ${theme.spacing(2.5)}px)`,
  border: "1px solid lightgray",
  backgroundColor: "#f9f9f9",
  boxShadow: theme.shadows[2],
  height: "100%",
}));

export const LeaderboardWrapperTitle = styled.div(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  color: theme.palette.getContrastText(theme.palette.primary.main),
  width: "100%",
}));
