import {
  DateAxis,
  ValueAxis,
  Legend,
  DateAxisDataItem,
} from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Theme } from "@material-ui/core";

export const styleDateAxis = (theme: Theme, dateAxis: DateAxis) => {
  const grey = theme?.palette?.colors?.grey?.dark || "#808080";
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.title.text = "[bold]Time[/]";
  dateAxis.dateFormats.setKey("hour", "h a");
  dateAxis.periodChangeDateFormats.setKey("hour", "h a");
  dateAxis.dateFormats.setKey("minute", "h:mm a");
  dateAxis.periodChangeDateFormats.setKey("minute", "h:mm a");
  dateAxis.fill = am4core.color(grey);
  dateAxis.title.fill = am4core.color(grey);
  dateAxis.renderer.labels.template.fill = am4core.color(grey);
  dateAxis.renderer.minGridDistance = 60;
  dateAxis.renderer.labels.template.fontSize = 16;
  dateAxis.title.fontSize = 20;
};

export const styleValueAxis = (theme: Theme, valueAxis: ValueAxis) => {
  const grey = theme?.palette?.colors?.grey?.dark || "#808080";
  valueAxis.renderer.minWidth = 35;
  valueAxis.title.fill = am4core.color(grey);
  valueAxis.renderer.labels.template.fill = am4core.color(grey);
  valueAxis.renderer.labels.template.fontSize = 16;
  valueAxis.title.fontSize = 20;
};

export const styleLegend = (legend: Legend) => {
  legend.paddingLeft = 40;
  legend.paddingTop = 15;
  legend.labels.template.text = "[{color}]{name}";
  legend.labels.template.textDecoration = "none";
  legend.labels.template.fontSize = 16;
  legend.markers.template.width = 16;
  legend.markers.template.height = 16;
  legend.valueLabels.template.textDecoration = "none";
};

export const stylePolarRange = (range: DateAxisDataItem, color: string) => {
  range.axisFill.fill = am4core.color(color);
  range.axisFill.fillOpacity = 1;
  range.grid.strokeOpacity = 0;
  range.grid.disabled = true;
  const fill = range.axisFill;
  (fill as any).innerRadius = -0.001;
  (fill as any).radius = -15;
};

export const createImage = (href: string) => {
  var image = new am4core.Image();
  image.width = 50;
  image.height = 50;
  image.dy = -25;
  image.horizontalCenter = "middle";
  image.verticalCenter = "middle";
  image.href = href;
};

export const styleImage = (image: am4core.Image) => {
  image.width = 50;
  image.height = 50;
  image.dy = -25;
  image.horizontalCenter = "middle";
  image.verticalCenter = "middle";
};

export const styleZoomButtonBG = (
  zoomButtonBG: am4core.RoundedRectangle,
  theme: Theme
) => {
  zoomButtonBG.fill = am4core.color(theme.palette.secondary.main);
  if (zoomButtonBG.states.getKey("hover")?.properties?.fill) {
    (zoomButtonBG.states.getKey(
      "hover"
    ) as any).properties.fill = am4core.color(theme.palette.secondary.dark);
  }
  if (zoomButtonBG.states.getKey("down")?.properties?.fill) {
    (zoomButtonBG.states.getKey("down") as any).properties.fill = am4core.color(
      theme.palette.secondary.main
    );
  }
};
