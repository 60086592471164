import styled from "../../../providers/ThemeProvider";

export const StaticContentContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

export const StaticContentStoriesContainer = styled.div({
  width: "800px",
  height: "500px"
});
