import * as am4core from "@amcharts/amcharts4/core";
import { Theme } from "@material-ui/core";
import {
  SchemaPieMultiple,
  PieChartOption,
  SchemaPieData,
  FormattedData,
} from "./ChildPie.types";
import { DataType } from "../../../../types/dataKeys";
import {
  getValueUnitDivisor,
  autoFormatValue,
} from "../../../../utilities/DataUtil/convertUnit";
import { getUnitTypeFromDataKey } from "../../../../utilities/DataUtil/getUnitType";
import { ProfileGraphTotals } from "../../../../types/EnergyProfile";
import { getDataKeyName } from "../../../../utilities/DataUtil/getDataKeyName";
import { format } from "date-fns";

const maxSlices = 6;

/**
 *  Formats the desired data in a sorted top 8 list, with the rest aggregated to an "others" category
 */
export const formatData = (
  data: SchemaPieData,
  option: PieChartOption,
  colors: string[],
  theme: Theme,
  setOtherCategory: Function
) => {
  let results = [] as FormattedData;
  // use totals
  let firstValue;
  if (isParent(data)) {
    firstValue = getValue(data.children[0], option.primaryKey[0] as DataType);
  } else if (isSingle(data)) {
    firstValue = getValue(data, option.primaryKey[0] as DataType);
  }

  const { unit, divisor } = getValueUnitDivisor(
    firstValue,
    getUnitTypeFromDataKey(option.primaryKey[0] as DataType)
  );

  if (isParent(data) && !isSingle(data)) {
    // multi schema
    data.children.forEach((datum: any) => {
      const value = getValue(datum, option.primaryKey[0] as DataType);

      const secondaryValue =
        option?.secondaryKey?.[0] &&
        getValue(datum, option.secondaryKey[0] as DataType);

      const secondaryValueFormatted = secondaryValue
        ? option?.secondaryKey?.[0] &&
          autoFormatValue(secondaryValue, option.secondaryKey[0] as DataType)
        : "";
      const output = {
        name: datum.resourceName,
        value: value / divisor,
        unit: unit,
        secondary: secondaryValueFormatted,
        secondaryValue: secondaryValue,
      };
      results.push(output);
    });

    results.sort((a, b) => (a.value < b.value ? 1 : -1));

    results = formatResults(results, option, theme, colors, setOtherCategory);
  } else if (isSingle(data)) {
    // single schema stuff
    option.primaryKey.forEach((dataKey, index) => {
      const value = getValue(data, option.primaryKey[index] as DataType);
      const secondaryValue =
        option?.secondaryKey?.[0] &&
        getValue(data, option.secondaryKey[index] as DataType);
      const secondaryValueFormatted =
        option?.secondaryKey?.[0] && secondaryValue
          ? autoFormatValue(
              secondaryValue,
              option.secondaryKey[index] as DataType
            )
          : "";

      results.push({
        name: getDataKeyName(dataKey)[0] as string,
        value: value / divisor,
        unit: unit,
        color: am4core.color(
          theme.palette.dataKeys?.[option.primaryKey[index]] || colors[index]
        ),
        secondary: secondaryValueFormatted,
      });
    });
    setOtherCategory([]);
  }
  return results;
};

/**
 * Gets the value from using a contextual data key or a generic one, given what kind of endpoint it is
 */
export const getValue = (datum: any, key: DataType) => {
  const hasDataKey = datum?.[key];
  return datum?.[hasDataKey ? key : getContextualDataKey(key)];
};

export const getPieColors = (theme: Theme) => [
  "#6E0E0A",
  "#D74E09",
  "#F2BB05",
  "#72B01D",
  "#3F7D20",
  "#119DA4",
  "#00B4DD",
  "#124E78",
  "#454955",
  "#262528",
];

export const formatResults = (
  input: FormattedData,
  option: PieChartOption,
  theme: Theme,
  colors: string[],
  setOtherCategory: Function
) => {
  let results = [] as FormattedData;
  let otherCategory = [] as FormattedData;
  const other = {
    name: "Other",
    value: 0,
    unit: "",
    color: am4core.color("#262528"),
    secondaryValue: 0,
    secondary: "", //calculate after the fact (needs to increment )
  };

  // Get other category
  if (input.length > maxSlices) {
    // Split the arrays
    otherCategory = input.slice(maxSlices - 1);
    results = input.slice(0, maxSlices - 1);

    // Increment the total
    otherCategory.forEach((el) => {
      other.value += el.value;
      if (el?.secondaryValue) {
        other.secondaryValue += el.secondaryValue;
      }
    });

    // Formats
    other.name = `Other (${otherCategory.length} more)`;
    other.unit = results[0].unit; //temp
    other.secondary =
      other.secondaryValue && option?.secondaryKey?.[0]
        ? autoFormatValue(
            other.secondaryValue,
            option.secondaryKey[0] as DataType
          )
        : "";
    results.push(other);
  } else {
    results = input;
  }

  // Add colors after the fact
  results.forEach(
    (el, i) =>
      i !== results.length - (otherCategory.length ? 1 : 0) &&
      (el.color = am4core.color(colors[i]))
  );

  setOtherCategory(otherCategory);
  return results;
};

export const isParent = (data: SchemaPieData): data is SchemaPieMultiple =>
  (data as SchemaPieMultiple).children !== undefined;

export const isSingle = (data: SchemaPieData): data is ProfileGraphTotals =>
  (data as ProfileGraphTotals).gridConsumed !== undefined;

/**
 * Returns the datakey used in the new contextual endpoints with generic names depending on what type they are
 * @param key Data key with context
 */
export const getContextualDataKey = (key: DataType) => {
  switch (key) {
    case "solarGenerated":
      return "generatedEnergy";
    case "solarGeneratedValue":
      return "generatedEnergyValue";
    case "solarFeedIn":
      return "feedInEnergy";
    case "solarFeedInValue":
      return "feedInEnergyValue";
    case "gridConsumed":
      return "usedEnergy";
    case "gridConsumedValue":
      return "usedEnergyValue";
    case "solarConsumed":
      return "usedEnergy";
    case "solarConsumedValue":
      return "usedEnergyValue";
    default:
      return key;
  }
};

export const formatDate = (date: Date) => format(date, "yyyy-MM-dd HH:mm:ss");
