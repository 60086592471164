export type KeyOption = {
  filled: boolean;
  negative: boolean;
  stacked: boolean;
  zIndex: number;
  secondaryDataKey?: string;
  barZIndex?: number;
  forceLine?: boolean;
  dashedStroke?: boolean;
  opacity?: number;
};

export type KeyOptions = { [key: string]: KeyOption };

export const getLineXYChartKeyOptions = () =>
  ({
    solarGenerated: {
      filled: true,
      negative: false,
      stacked: false,
      forceLine: true,
      // opacity: 0.6,
      zIndex: 4,
    },
    solarFeedIn: {
      filled: true,
      negative: true,
      stacked: false,
      forceLine: true,
      opacity: 0.7,
      zIndex: 10,
    },
    solarReturned: {
      filled: true,
      negative: true,
      stacked: false,
      forceLine: true,
      opacity: 0.7,
      zIndex: 10,
    },
    gridConsumed: {
      filled: true,
      negative: false,
      stacked: false,
      forceLine: true,
      zIndex: 2,
      barZIndex: 2,
    },
    solarConsumed: {
      filled: true,
      negative: false,
      stacked: false,
      forceLine: true,
      zIndex: 3,
      barZIndex: 1,
    },
    netConsumed: {
      filled: false,
      negative: false,
      stacked: false,
      forceLine: true,
      opacity: 0.6,
      zIndex: 4,
    },
    co2Saved: {
      filled: true,
      negative: false,
      stacked: false,
      forceLine: true,
      zIndex: 3,
      barZIndex: 1,
    },
    co2Produced: {
      filled: true,
      negative: false,
      stacked: false,
      forceLine: true,
      opacity: 0.6,
      zIndex: 4,
    },
  } as { [key: string]: KeyOption });

export const getBarXYChartOptions = () =>
  ({
    solarGenerated: {
      filled: true,
      negative: false,
      stacked: false,
      // opacity: 0.6,
      zIndex: 4,
    },
    solarFeedIn: {
      filled: true,
      negative: true,
      stacked: true,
      zIndex: -4,
    },
    solarReturned: {
      filled: true,
      negative: true,
      stacked: true,
      zIndex: -4,
    },
    gridConsumed: {
      filled: true,
      negative: false,
      stacked: true,
      zIndex: 2,
      barZIndex: 2,
    },
    solarConsumed: {
      filled: true,
      negative: false,
      stacked: true,
      zIndex: 3,
      barZIndex: 1,
    },
    co2Saved: {
      filled: true,
      negative: false,
      stacked: false,
      zIndex: 2,
      barZIndex: 2,
    },
    co2Produced: {
      filled: true,
      negative: false,
      stacked: false,
      zIndex: 3,
      barZIndex: 1,
    },
    netConsumed: {
      filled: true,
      negative: false,
      stacked: false,
      opacity: 0.9,
      zIndex: 4,
    },
  } as { [key: string]: KeyOption });

export const getPolarChartKeyOptions = () =>
  ({
    solarFeedIn: {
      filled: true,
      negative: true,
      stacked: true,
      zIndex: 0,
    },
    gridConsumed: {
      filled: true,
      negative: false,
      stacked: true,
      opacity: 0.5,
      zIndex: 2,
      barZIndex: 2,
    },

    solarConsumed: {
      filled: true,
      negative: false,
      stacked: true,
      zIndex: 3,
      barZIndex: 1,
    },
    netConsumed: {
      filled: false,
      negative: false,
      stacked: true,
      zIndex: 1,
      barZIndex: 3,
    },
  } as { [key: string]: KeyOption });

export const getSystemsKeyOptions = (systems: any) => {
  const output: { [key: string]: KeyOption } = {};
  systems.forEach((system: { systemId: number }) => {
    output[`system${system.systemId}`] = {
      negative: false,
      filled: false,
      stacked: false,
      secondaryDataKey: "energyGenerated",
      forceLine: true,
      zIndex: 1,
    };
  });
  return output;
};
