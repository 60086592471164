import React from "react";
// temp until a loadingicon for other themes
import { Fade } from "@material-ui/core";
import {
  LoadingScreenContainer,
  LoadingScreenImage,
} from "./LoadingScreen.styles";

const LoadingScreen = ({ themeName = "energyfootprint" }) => {
  return (
    <LoadingScreenContainer>
      <Fade in timeout={{ enter: 1500 }}>
        <LoadingScreenImage
          src={`theme-images/${themeName}/loading.${
            themeName === "solarschools" ? "svg" : "gif"
          }`}
        />
      </Fade>
    </LoadingScreenContainer>
  );
};

export default LoadingScreen;
