import React, { FunctionComponent } from "react";
import ErrorScreen from "../../_shared/ErrorScreen";
import { useTheme } from "../../../hooks/Context Hooks/theming/useTheme";
import { getPieOptions } from "./getPieOptions";
import ReactEcharts from "echarts-for-react";
import {
  PieContainer,
  chartStyles,
  GraphWrapper,
  BarComparisonWrapper,
} from "./Pie.styles";
import { useResourceName } from "../../../hooks/Context Hooks/config/useResourceName";
import BarComparison from "./BarComparison/BarComparison";
import { areSameUnitType } from "../../../utilities/DataUtil/old/areSameUnitType";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";

export const Pie: FunctionComponent<{}> = ({}) => {
  const { timeSpan, dataKey } = useSlideContext();
  const data = useTimeSpanData(timeSpan);
  const theme = useTheme();
  const resourceName = useResourceName();
  const options = getPieOptions(data, dataKey, theme, resourceName);
  const validTypes = areSameUnitType([dataKey[0], dataKey[1]]);

  return data ? (
    <PieContainer>
      <GraphWrapper validTypes={validTypes}>
        <ReactEcharts
          style={chartStyles}
          option={options as any}
          notMerge={true}
          lazyUpdate={true}
        />
      </GraphWrapper>
      <BarComparisonWrapper>
        <BarComparison />
      </BarComparisonWrapper>
    </PieContainer>
  ) : (
    <ErrorScreen code={500} />
  );
};

export default Pie;
