export const dataKeyNameMappings = {
  apparentPower: "Apparent Power",
  co2Produced: "CO₂e Emitted",
  co2Saved: "CO₂e Avoided",
  costs: "Costs",
  current: "Current",
  expectedBurndown: "Estimated Burndown",
  expectedGeneration: "Estimated Generation",
  gridConsumed: "Grid Consumed",
  gridConsumedValue: "Costs",
  guaranteeBurndown: "Targeted Burndown",
  guaranteeGeneration: "Targeted Savings",
  maxNetConsumedAvgPerPerson: "Highest Consumption",
  minNetConsumedAvgPerPerson: "Lowest Consumption",
  netConsumed: "Net Consumed",
  powerFactor: "Power Factor",
  reactiveEnergy: "Reactive Energy",
  reactiveEnergyJoules: "Reactive Energy",
  reactivePower: "Reactive Power",
  realEnergy: "Real Energy",
  realEnergyJoules: "Real Energy",
  realPower: "Real Power",
  savings: "Savings",
  selfConsumed: "Solar Consumed",
  solarConsumed: "Solar Consumed",
  solarFeedIn: "Solar Exported",
  solarGenerated: "Solar Generated",
  solarGeneratedValue: "Savings",
  solarReturned: "Solar Exported",
  voltage: "Voltage",
} as { [key: string]: string };

/**
 * Returns human readable names of given data keys
 * @param dataKeys
 */
export const getDataKeyName = (dataKeys: string[] | string) => {
  if (!Array.isArray(dataKeys)) {
    dataKeys = [dataKeys];
  }

  return dataKeys.map((dataKey: string) => {
    if (dataKeyNameMappings?.[dataKey]) {
      return dataKeyNameMappings[dataKey];
    } else {
      // Default to CamdataKey Case -> Title Case
      const result = dataKey.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
  });
};
