import { UnitList } from "../unit-converter.types";

export const unitList = ["g", "kg", "t", "Mt", "Gt"] as const;

export const unitsOfMeasure: UnitList<(typeof unitList)[number]> = [
  {
    name: "grams",
    order: 1,
    unit: "g",
  },
  {
    name: "kilograms",
    order: 2,
    unit: "kg",
  },
  {
    name: "tonne",
    order: 3,
    unit: "t",
  },
  {
    name: "megatonne",
    order: 4,
    unit: "Mt",
  },
  {
    name: "gigatonne",
    order: 5,
    unit: "Gt",
  },
];
