import { autoFormatValue } from "../../../../utilities/DataUtil/convertUnit";
import { Theme, lighten } from "@material-ui/core";
import { settings } from "../EnergyFlow.consts";
import React, { Fragment } from "react";
import {
  ElementValueLabel,
  ElementCircle,
  ElementLabel,
} from "../EnergyFlow.styled";
import { EnergyFlowDatum, ElementConfig, Position } from "../EnergyFlow.types";

type Props = {
  data: EnergyFlowDatum;
  elementPos: Array<Position>;
  elementConfigs: Array<ElementConfig>;
  theme: Theme;
};

export const EnergyElements = ({
  data,
  elementPos,
  elementConfigs,
  theme,
}: Props) => {
  const defaultColor = "#a6a6a6";
  return (
    <Fragment>
      {elementPos.map((position, index) => {
        const elConfig = elementConfigs[index];
        const color = theme.palette?.dataKeys?.[elConfig?.dataKey];
        const value = data?.[elConfig.dataKey];
        return (
          <Fragment key={index}>
            <filter id={`shadow${index}`}>
              <feDropShadow
                dx="0"
                dy="0"
                stdDeviation="2"
                floodColor={color || defaultColor}
              />
            </filter>
            <ElementCircle
              cx={position.x}
              cy={position.y}
              // fill="#FFF"
              fill={lighten(color || defaultColor, 0.9)}
              style={
                value
                  ? {
                      filter: `url(#shadow${index})`,
                    }
                  : {}
              }
              stroke={color || defaultColor}
            />
            <use
              href={elementConfigs[index].icon}
              x={position.x - settings.elementWidth / 5}
              y={position.y - settings.elementWidth / 3}
              fill={color || defaultColor}
              width={settings.elementWidth / 2.5}
              height={settings.elementWidth / 2.5}
            />
            <ElementValueLabel
              x={position.x}
              y={position.y + settings.elementWidth / 4}
              fill={color || defaultColor}
            >
              {autoFormatValue(value, elConfig.dataKey)}
            </ElementValueLabel>
            <ElementLabel x={position.labelX} y={position.labelY}>
              {elementConfigs[index].name}
            </ElementLabel>
          </Fragment>
        );
      })}
    </Fragment>
  );
};
