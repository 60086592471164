import React from "react";
import ErrorScreen from "../../_shared/ErrorScreen";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";
import { TimeSpan } from "../../../types/dataKeys";
import Graph from "../Charts/Graph/Graph";
import { getSystemsKeyOptions } from "../Charts/Graph/getKeyOptions";

export const SystemGraph = () => {
  const { timeSpan } = useSlideContext();
  const data = useTimeSpanData(timeSpan as TimeSpan, "SystemEnergy");

  return data ? (
    <Graph
      data={data.systemsData}
      dataKeys={data.systems.map(
        (system: { systemId: number }) => `system${system.systemId}`
      )}
      graphId={"systems-graph"}
      keyOptions={getSystemsKeyOptions(data?.systems)}
      hideScroll
      systems={data?.systems}
      height="100%"
    />
  ) : (
    <ErrorScreen code={500} />
  );
};

export default SystemGraph;
