import { UnitShorthand } from "../../../types/unitShorthand";

export const convertToUnitFromLowest = (value: number, unit: UnitShorthand) => {
  switch (unit) {
    case "Wh":
      return value;
    case "g":
      return value;
    case "kWh":
      return value / 1e3;
    case "kg":
      return value / 1e3;
    case "MWh":
      return value / 1e6;
    case "t":
      return value / 1e6;
    case "GWh":
      return value / 1e9;
    case "kt":
      return value / 1e9;
    default:
      return NaN;
  }
};
