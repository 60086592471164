import {
  LeaderboardWrapperContainer,
  LeaderboardWrapperTitle,
} from "./LeaderboardWrapper.styled";
import React from "react";

type Props = {
  title: string;
  children?: JSX.Element | Array<JSX.Element>;
};

export const LeaderboardWrapper = ({ title, children }: Props) => {
  return (
    <LeaderboardWrapperContainer>
      <LeaderboardWrapperTitle>{title}</LeaderboardWrapperTitle>
      {children}
    </LeaderboardWrapperContainer>
  );
};
