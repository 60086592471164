import { UnitList } from "../unit-converter.types";

export const unitList = ["l", "kl", "ML", "GL", "TL"] as const;

export const unitsOfMeasure: UnitList<(typeof unitList)[number]> = [
  {
    name: "litres",
    order: 1,
    unit: "l",
  },
  {
    name: "kilolitres",
    order: 2,
    unit: "kl",
  },
  {
    name: "megalitres",
    order: 3,
    unit: "ML",
  },
  {
    name: "gigalitres",
    order: 4,
    unit: "GL",
  },
  {
    name: "teralitres",
    order: 5,
    unit: "TL",
  },
];
