import React from "react";
import TitleBox from "../../_shared/TitleBox/TitleBox";
import { autoFormatMassValue } from "@solarschools/component-lib/src/utilities/MassUnits";
import ImageStatBox from "../../_shared/ImageStatBox/ImageStatBox";
import { autoFormatEnergyValue } from "@solarschools/component-lib/src/utilities/EnergyUnits";
import { format } from "date-fns";
import { FactBox } from "../../_shared/FactBox";
import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";

const CO2StatImages = () => {
  const { timeSpan } = useSlideContext();
  const data = useTimeSpanData(timeSpan);
  const co2Saved = autoFormatMassValue(data.totals.co2Saved);
  const solarGenerated = autoFormatEnergyValue(data.totals.solarGenerated);

  return (
    <div className="d-flex justify-content-around font-weight-bold 5 vertical-align">
      <ImageStatBox
        imagePath="miscellaneous/CO2Saved.png"
        statTitle="CO₂e AVOIDED"
        stat={co2Saved}
      />
      <ImageStatBox
        imagePath="miscellaneous/SolarGenerated.png"
        statTitle="SOLAR ENERGY GENERATED"
        stat={solarGenerated}
      />
    </div>
  );
};

export default CO2StatImages;
