import { DataType } from "../../types/dataKeys";
import getDataType from "./getDataType";
import {
  getUnitDivisorFromMagnitude,
  getUnitIndexFromMagnitude,
  getMagnitude,
} from "./fromMagnitude";
import {
  energyApparentUnits,
  energyReactiveUnits,
  powerApparentUnits,
  powerReactiveUnits,
  energyJouleUnits,
  energyPeakUnits,
  currentUnits,
  voltageUnits,
  energyUnits,
  powerUnits,
  waterUnits,
  unitTypes,
  massUnits,
  UnitType,
} from "./unitConstants";

export const toFixedIfNeeded = (value: number, amount?: number) =>
  value && value.toFixed(amount || 2).replace(/[.,]00$/, "");

export const getDivisor = (value: number, unitType: UnitType) => {
  const magnitude = getMagnitude(value);
  switch (unitType) {
    case "money":
      return 1;
    case "water":
      return (1 / 2.185) * getUnitDivisorFromMagnitude(magnitude);
    case "percentage":
      return 0.01;
    default:
      return getUnitDivisorFromMagnitude(magnitude);
  }
};

export const getUnit = (
  value: number,
  unitType: UnitType,
  forcePower?: boolean
) => {
  const magnitude = getMagnitude(value);
  const unitIndex = getUnitIndexFromMagnitude(magnitude);

  switch (unitType) {
    case "energy":
      return forcePower ? powerUnits[unitIndex] : energyUnits[unitIndex];
    case "energy peak":
      return energyPeakUnits[unitIndex];
    case "power":
      return powerUnits[unitIndex];
    case "energy joules":
      return energyJouleUnits[unitIndex];
    case "voltage":
      return voltageUnits[unitIndex];
    case "current":
      return currentUnits[unitIndex];
    case "mass":
      return massUnits[unitIndex];
    case "water":
      return waterUnits[unitIndex];
    case "energy reactive":
      return energyReactiveUnits[unitIndex];
    case "power reactive":
      return powerReactiveUnits[unitIndex];
    case "power apparent":
      return powerApparentUnits[unitIndex];
    case "energy apparent":
      return energyApparentUnits[unitIndex];
    case "money":
      return "$";
    case "percentage":
      return "%";
    default:
      return "N/A";
  }
};

/**
 *  Returns value and unit (plus other information) in the format of:
 *  {
 *    value: new value,
 *    unit: unit,
 *    noSpacing: should unit have no spacing when formatted,
 *    front: should unit be at front when formatted
 *  }
 * @param value Given intial value to convert
 * @param unitType Type of unit
 * @param forcePower Whether to format kWh as kW
 */
export const getValueUnitDivisor = (
  value: number,
  unitType: UnitType,
  forcePower?: boolean
) => {
  // Initial checks
  if (!unitTypes.includes(unitType) || value === null || value === undefined) {
    return { value: null, unit: "N/A", divisor: 1 };
  } else if (unitType === "blank") {
    return {
      value: toFixedIfNeeded(value, 4),
      unit: "",
      divisor: 1,
      noSpacing: true,
    };
  }

  const unit = getUnit(value, unitType, forcePower);
  const divisor = getDivisor(value, unitType);
  const _value = toFixedIfNeeded(value / divisor);
  const noSpacing = unitType === "percentage" || unitType === "money";
  const front = unitType === "money";

  return {
    value: _value,
    unit: unit,
    divisor: divisor,
    noSpacing: noSpacing,
    front: front,
  };
};

export const autoFormatValue = (
  value: number,
  dataKey: DataType,
  isWatt?: boolean
) => {
  const { value: newValue, unit, noSpacing, front } = getValueUnitDivisor(
    value,
    getDataType(dataKey),
    isWatt
  );

  return `${front ? unit : newValue ?? ""}${noSpacing ? "" : " "}${
    front ? newValue ?? "" : unit
  }`;
};
