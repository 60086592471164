import { Typography } from "@material-ui/core";
import styled from "../../../../providers/ThemeProvider";

export const PanelInfoBoxContainer = styled.div(({ theme }) => ({
  width: "45%",
  color: theme.palette.text.primary
}));

export const PanelTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 900
}));

export const PanelData = styled(Typography)(({ theme }) => ({}));
