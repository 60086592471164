import {
  buildSeries,
  getGraphUnitDivisor,
} from "../../../../utilities/Charts/functions";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import { getDataKeyName } from "utilities/DataKeys/getDataKeyName";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { useTheme } from "@material-ui/core";
import { GraphProps } from "./Graph.types";
import { formatData } from "./Graph.utils";
import { Chart } from "./Graph.styled";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from "react";

import {
  styleValueAxis,
  styleDateAxis,
  styleLegend,
  styleZoomButtonBG,
  styleImage,
} from "../../../../utilities/Charts/styles";
import { getDataKeyName } from "../../../../utilities/DataUtil/getDataKeyName";
import { splitDataKeysByUnitType } from "../../../../utilities/DataUtil/splitDataKeys";
import { getUnitTypeFromDataKey } from "../../../../utilities/DataUtil/getUnitType";

am4core.useTheme(am4themes_animated);

/**
 * Big ugly amChart generic graph
 * @param data Data feed
 * @param dataKeys Primary axis data keys
 * @param keyOptions Used to defined the rules and properties for the dataKeys (see getKeyOptions.ts for type definition)
 * @param secondaryDataKeys Optional - Secondary axis data keys (goes to the right of the graph)
 * @param totals Optional - Data totals, used for calculating best unit and other minor use cases
 * @param graphId Optional - Must be unique if there are multiple graph components on the same render
 * @param hideScroll Optional - Hides the chart scroll when true
 * @param hideLegend Optional - Hides the chart legend when true
 * @param isBar Optional - Force to bar
 * @param height Optional - Forces the graph to be the given height
 * @param systems Optional - Required to properly format system data, specific to the getSystems endpoint (see SystemGraph.tsx)
 * @param wontUpdate Optional - The graph won't rerender when true, used to only show certain types of dates (e.g. days only)
 * @param forcePower Optional - Forces the titles and energy conversions to power
 */
export const Graph: FunctionComponent<GraphProps> = ({
  data,
  totals,
  graphId,
  dataKeys,
  secondaryDataKeys,
  hideScroll,
  hideLegend,
  height = 500,
  isBar = false,
  keyOptions,
  systems, // used for system names on the system graph
  wontUpdate, // function given the day difference, which will determine whether the graph rerenders,
  forcePower,
}) => {
  const theme = useTheme();
  const chart = useRef<am4charts.XYChart | null>(null);
  const autoSeriesColorIndex = useRef(0);
  const totalRef = useRef(totals);

  useEffect(() => {
    chart.current = am4core.create(`chart${graphId}`, am4charts.XYChart);
    return () => chart?.current?.dispose();
  }, [graphId]);

  const getKeyName = useCallback(
    (dataKey: string) => {
      if (systems) {
        const [, id] = dataKey.split("system");
        const system = systems.find(
          (sys: any) => parseInt(sys.systemId) === parseInt(id)
        );
        return system.systemName;
      } else {
        return getDataKeyName(dataKey)[0];
      }
    },
    [systems]
  );

  // Process
  useEffect(() => {
    autoSeriesColorIndex.current = 0; // reset default colors index

    if (chart?.current && !wontUpdate) {
      chart.current.dispose();
      chart.current = am4core.create(`chart${graphId}`, am4charts.XYChart);

      const {
        primary: primaryDataKeys,
        secondary: secondaryDataKeys,
      } = splitDataKeysByUnitType(dataKeys);

      if (data) {
        const {
          unit,
          divisor,
          secondaryUnit,
          secondaryDivisor,
        } = getGraphUnitDivisor(
          data,
          primaryDataKeys,
          secondaryDataKeys,
          totals,
          forcePower
        );

        // format the data
        const { values, totals: calculatedTotals } = formatData(
          data,
          keyOptions,
          primaryDataKeys,
          secondaryDataKeys,
          divisor,
          secondaryDivisor,
          isBar,
          !!secondaryDataKeys.length || // no negative values if secondary axis because it makes the alignment buggy
            (primaryDataKeys.length === 1 && // show exported as positive if its the only datakey
              !!primaryDataKeys[0].match(/^(solarFeedIn|solarReturned)$/))
        );

        // Override for endpoints that dont have a totals object
        if (!totalRef.current) {
          totalRef.current = calculatedTotals;
        }

        // Initial styles
        chart.current.paddingTop = values[0]?.weatherIcon ? 40 : 20; // add padding if theres weather icons
        chart.current.data = data && values;
        chart.current.numberFormatter.numberFormat = "#.##s";
        chart.current.paddingRight = 50;
        styleZoomButtonBG(chart.current.zoomOutButton.background, theme);

        // Axes =====
        let dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
        styleDateAxis(theme, dateAxis);

        let valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = `[bold]${
          forcePower ? "Power" : "Energy"
        } (${unit})[/]`;

        // Weather icons
        var iconAxis = chart.current.xAxes.push(new am4charts.CategoryAxis());
        iconAxis.dataFields.category = "weatherIcon";
        iconAxis.renderer.grid.template.strokeOpacity = 0;
        iconAxis.renderer.opposite = true;
        iconAxis.renderer.minGridDistance = 50;
        iconAxis.renderer.labels.template.disabled = true;
        if (iconAxis?.tooltip) {
          iconAxis.tooltip.disabled = true;
        }
        if (valueAxis?.tooltip) {
          valueAxis.tooltip.disabled = true;
        }
        var image = new am4core.Image();
        styleImage(image);
        iconAxis.dataItems.template.bullet = image;
        image.adapter.add("href", (_, target) => {
          const dataItem = target.dataItem;
          if (dataItem && (dataItem as any)?.category) {
            const weatherString = (dataItem as any).category.replace(
              / \(.*/,
              ""
            );
            return `/icons/Weather/${weatherString}.svg`;
          }
        });

        // Scroll =====
        let scrollbarX: am4charts.XYChartScrollbar;
        if (!hideScroll) {
          scrollbarX = new am4charts.XYChartScrollbar();
          chart.current.scrollbarX = scrollbarX;
        }

        // Series =====
        styleValueAxis(theme, valueAxis);
        const unitType = getUnitTypeFromDataKey(primaryDataKeys[0]);
        valueAxis.title.text =
          unitType !== "blank"
            ? `[bold]${unitType.charAt(0).toUpperCase() +
                unitType.slice(1)} (${unit})[/]`
            : "";
        styleValueAxis(theme, valueAxis);
        primaryDataKeys.forEach((dataKey: string) => {
          buildSeries(
            dataKey,
            unit,
            valueAxis,
            chart.current,
            autoSeriesColorIndex,
            keyOptions,
            theme,
            isBar,
            getKeyName,
            scrollbarX,
            hideScroll,
            calculatedTotals,
            !!systems
          );
        });

        // If theres a secondary axis
        if (secondaryDataKeys) {
          let secondaryValueAxis = chart.current.yAxes.push(
            new am4charts.ValueAxis()
          );
          const unitType = getUnitTypeFromDataKey(secondaryDataKeys[0]);
          secondaryValueAxis.title.text =
            unitType !== "blank"
              ? `[bold]${unitType.charAt(0).toUpperCase() +
                  unitType.slice(1)} (${secondaryUnit})[/]`
              : "";
          styleValueAxis(theme, secondaryValueAxis);
          secondaryValueAxis.renderer.opposite = true;
          secondaryValueAxis.renderer.grid.template.opacity = 0.4;
          // valueAxis.syncWithAxis = secondaryValueAxis;
          // secondaryValueAxis.syncWithAxis = valueAxis;
          secondaryValueAxis.min = 0;
          // secondaryValueAxis.strictMinMax = true;

          secondaryDataKeys.forEach((dataKey: string) => {
            buildSeries(
              dataKey,
              secondaryUnit,
              secondaryValueAxis,
              chart.current,
              autoSeriesColorIndex,
              keyOptions,
              theme,
              isBar,
              getKeyName,
              scrollbarX,
              hideScroll,
              calculatedTotals,
              !!systems
            );
          });
        }

        // Legends
        if (!hideLegend) {
          chart.current.legend = new am4charts.Legend();
          chart.current.legend.labels.template.truncate = false;
          styleLegend(chart.current.legend);
        }
      }
    }
  }, [
    data,
    getKeyName,
    graphId,
    secondaryDataKeys,
    keyOptions,
    wontUpdate,
    hideScroll,
    hideLegend,
    totals,
    theme,
    dataKeys,
    systems,
    forcePower,
  ]);

  return <Chart height={height} id={`chart${graphId}`} />;
};

export default Graph;
