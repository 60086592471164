import { UnitList } from "../unit-converter.types";

export const unitList = ["wh", "kWh", "MWh", "GWh", "TWh"] as const;

export const unitsOfMeasure: UnitList<(typeof unitList)[number]> = [
  {
    name: "watt hours",
    order: 1,
    unit: "wh",
  },
  {
    name: "kilowatt hours",
    order: 2,
    unit: "kWh",
  },
  {
    name: "megawatt hours",
    order: 3,
    unit: "MWh",
  },
  {
    name: "gigawatt hours",
    order: 4,
    unit: "GWh",
  },
  {
    name: "terawatt hours",
    order: 5,
    unit: "TWh",
  },
];
