import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_amchartsdark from "@amcharts/amcharts4/themes/moonrisekingdom";
import { StackedXYChartProps } from "./StackedXYChart.types";
import * as am4charts from "@amcharts/amcharts4/charts";
import React, { useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_amchartsdark);

export function StackedXYChart(props: StackedXYChartProps) {
  const chartRef = useRef<am4charts.XYChart | null>(null);
  const chartId = `stackedxychart-${props.id}`;

  useLayoutEffect(() => {
    const chart = am4core.create(chartId, am4charts.XYChart);

    const data = props.seriesData.map((datum) => {
      return { [props.categoryKey]: datum.time, ...datum.valueEntries };
    });

    chart.data = data;

    const categoryAxis = chart.xAxes.push(new am4charts.DateAxis());

    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.labels.template.location = 0.5;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Consumption (KWh)";
    valueAxis.title.fontWeight = "bold";
    valueAxis.min = 0;

    const seriesValueKeys = Object.keys(data[0]).filter(
      (value) => value !== "time"
    );

    seriesValueKeys.forEach((valueKey) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.name = valueKey;
      series.fillOpacity = 1;
      series.dataFields.valueY = valueKey;
      series.dataFields.dateX = "time";
      series.sequencedInterpolation = true;
      series.stacked = true;
    });

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, []);

  return <div id={chartId} style={{ width: "100%", height: "100%" }} />;
}

export default StackedXYChart;
