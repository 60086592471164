import React, { FunctionComponent } from "react";
import { TitleBoxContainer, StyledTitle } from "./TitleBox.styles";
import { Typography } from "@material-ui/core";
import { TitleBoxProps } from "./TitleBox.types";
import { parseSubscript2ToJSX } from "../../../../utilities/Parsing/ParseSubscript2ToJSX";
import { parseDateStrings } from "./TitleBox.utils";

export const TitleBox: FunctionComponent<TitleBoxProps> = ({
  title,
  subTitle,
}) => (
  <TitleBoxContainer>
    <StyledTitle>{String(title).toUpperCase()}</StyledTitle>
    <Typography variant="h5">
      {parseSubscript2ToJSX(parseDateStrings(subTitle as string))}
    </Typography>
  </TitleBoxContainer>
);

export default TitleBox;
