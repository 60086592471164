import React from "react";
import { ErrorCode } from "./ErrorScreen.types";

export const getErrorContent = (code: ErrorCode) => {
  switch (code) {
    case 500:
      return (
        <div className="h3">
          An unexpected server error has occurred.
          <br />
          <br />
          Please sit tight while we work on it.
        </div>
      );
    case 401:
      return (
        <div className="h3">
          You don't seem to be authorized to view this resource.
          <br />
          <br />
          If this is incorrect, please contact us at +61 7 3726 1034.
        </div>
      );
    case 404:
      return (
        <div className="h3">
          There doesn't seem to be a Display Board for this site.
          <br />
          <br />
          You can enable and customize one in the Engine Room (
          <a href="https://engineroom.solarschools.net">
            https://engineroom.solarschools.net
          </a>
          )
        </div>
      );

    default:
      return (
        <div className="h3">
          An unexpected error has occurred.
          <br />
          <br />
          Please sit tight while we work on it.
        </div>
      );
  }
};
