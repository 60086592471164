import LoadingScreen from "./components/_shared/LoadingScreen/LoadingScreen";
import ErrorScreen from "./components/_shared/ErrorScreen/ErrorScreen";
import { NoosaStaticContent } from "./temp/NoosaStaticContent";
import { getNecessarySlideDataVars } from "./slideConstants";
import React, { useState, useEffect, Fragment } from "react";
import { AuthenticatedClient } from "./api/ApiAuth/ApiAuth";
import { ThemeProvider } from "./providers/ThemeProvider";
import { DisplayBoardApi } from "@solarschools/api-sdk-typescript";
import { fetchNecessaryData } from "./Context.utils";
import {
  DISCOVERY_CAMPS_IDS,
  mapDiscoveryCampsConfigsToConfig,
} from "./temp/DiscoveryCamps";

export const DisplayBoardContext = React.createContext();

export const DisplayBoardProvider = ({ children }) => {
  const refreshDelay = 300000; // 5 Minutes (for data polling)
  const refreshPageDelay = 3600000; // 1 hour
  const urlParams = new URLSearchParams(window.location.search);
  const hostname = window.location.hostname;
  const isStaging =
    hostname.includes(".staging.") || hostname.includes(".dev.");
  const themeIndex = isStaging ? 2 : 1;
  const themeName = window.location.hostname.split(".")[themeIndex];
  const type = urlParams.get("type") || "sites";
  const key = urlParams.get("key");
  const boardKey = urlParams.get("board") || undefined;
  const id = urlParams.get("id");
  const [staticContent, setStaticContent] = useState(false);
  const [resourceData, setResourceData] = useState(false);
  const [configJSON, setConfigJSON] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const initConfig = async (id) => {
    if (id) {
      try {
        const dbApi = new DisplayBoardApi(AuthenticatedClient(key));
        const res = await dbApi.get(type || "sites", id);
        const config = res.data.config;
        // temp till aws solution
        const hasStaticSlide = config.slides.filter(
          (slide) => slide.id === "static"
        ).length;
        hasStaticSlide && setStaticContent(NoosaStaticContent());

        // TEMPORARY OVERIDE TO FORCE SLIDES INTO CONFIG
        if (
          DISCOVERY_CAMPS_IDS.includes(config.siteId || config.organisationId)
        ) {
          setConfigJSON(mapDiscoveryCampsConfigsToConfig(config));
        } else {
          setConfigJSON(config);
        }
        //setConfigJSON(config);
      } catch (err) {
        console.log(err);
        setError(true);
      }
    }
  };

  const pollRefresh = async (initialized) => {
    if (id) {
      try {
        if (!initialized) {
          setLoading(true);
        }

        const slideDataVars = getNecessarySlideDataVars(
          configJSON.slides,
          type
        );

        const data = await fetchNecessaryData(
          id,
          type,
          key,
          slideDataVars,
          configJSON.slides
        );

        setResourceData(data);

        if (!initialized) {
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setError(true);
      }
    }
  };

  useEffect(() => {
    // fetch config if doesnt exist
    if (!configJSON) {
      initConfig(id);
    } else {
      // fetch data and refresh every interval
      pollRefresh(false);
      const timer = setInterval(async () => {
        await pollRefresh(true);
      }, refreshDelay);

      // refresh page
      const refreshTimer = setTimeout(() => {
        window.location.reload(true);
      }, refreshPageDelay);
      return () => {
        clearInterval(timer);
        clearTimeout(refreshTimer);
      };
    }
  }, [configJSON]);

  if (!id || !key) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center vh-100 overflow-hidden">
        <h1 className="text-5xl block">
          Please Supply an ID, Type (sites/schemas/organisations) and an API Key
        </h1>
        <h4>/?id=PUT_ID_HERE&type=PUT_TYPE_HERE&key=PUT_KEY_HERE</h4>
      </div>
    );
  }

  return (
    <DisplayBoardContext.Provider
      value={{
        config: configJSON,
        staticContent,
        data: resourceData,
        resourceName:
          resourceData?.energyDetails?.displayName ||
          resourceData?.energyDetails?.resourceName,
        id,
        type,
        key,
        themeName: configJSON?.theme?.replace(/-/, "") || themeName,
        hasWeather: type === "sites",
      }}
    >
      <ThemeProvider>
        {!error ? (
          !loading && configJSON && resourceData ? (
            <Fragment>{children}</Fragment>
          ) : (
            <LoadingScreen themeName={themeName} />
          )
        ) : (
          <ErrorScreen themeName={themeName} code={error?.status} />
        )}
      </ThemeProvider>
    </DisplayBoardContext.Provider>
  );
};
