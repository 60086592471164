import styled from "../../../providers/ThemeProvider";

export const WeatherBoxContainer = styled.div({
  width: "65%",
  display: "flex",
  justifyContent: "center",
});

export const TemperatureContainer = styled.div({
  width: "50%",
  display: "flex",
  alignItems: "center",
});

export const Temperature = styled.h2(({ theme }) => ({
  margin: 0,
  textAlign: "right",
  height: "auto",
  width: "100%",
  fontSize: 64,
  color: theme.palette?.sideBarText?.main || theme.palette.common.white,
}));

export const WeatherIconContainer = styled.div({
  width: "50%",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
});

export const WeatherIcon = styled.img({
  width: "100%",
  maxWidth: "100%",
  height: "auto",
});
