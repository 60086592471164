import React, { FunctionComponent, useContext, createContext } from "react";
import {
  AltLogoImg,
  AltLogosContainer,
  Slide,
  SlideWrapperContainer,
} from "./SlideWrapper.styles";
import { DataTypes, TimeSpan } from "../../../types/dataKeys";
import { SlideWrapperProps } from "./SlideWrapper.types";
import TitleBox from "./TitleBox/TitleBox";
import { DisplayBoardContext } from "../../../Context";
import {
  getDataTypeFromID,
  getTimeSpanFromID,
  getDataTypeFromShorthand,
  sortDataKeys,
} from "../../../utilities/Parsing/dataKeys";
import { AverageSitesConsumption } from "../../Slides/AverageSitesConsumption/AverageSitesConsumption";
import { DISCOVERY_CAMPS_IDS } from "../../../temp/DiscoveryCamps";

export const SlideContext = createContext({
  dataKey: ["solarGenerated"] as DataTypes,
  timeSpan: "daily" as TimeSpan,
});
export const SlideWrapper: FunctionComponent<SlideWrapperProps> = ({
  subTitle,
  fullWidth,
  slide,
  children,
}) => {
  const { resourceName, id } = useContext(DisplayBoardContext);

  const value = slide?.id && {
    dataKey: sortDataKeys(
      getDataTypeFromShorthand(getDataTypeFromID(slide.id) ?? "")
    ),
    timeSpan: getTimeSpanFromID(slide.id),
  };

  return (
    <SlideContext.Provider value={value as any}>
      <SlideWrapperContainer>
        <TitleBox
          title={resourceName}
          subTitle={
            subTitle || (slide?.title ? slide.title : "No title specified")
          }
        />
        <Slide fullWidth={fullWidth ? 1 : 0}>
          {Array.isArray(children)
            ? children.map((component) => component)
            : children}
        </Slide>

        {DISCOVERY_CAMPS_IDS.includes(parseInt(id, 10)) && (
          <AltLogosContainer>
            <AltLogoImg
              src={`logos/zeropositive-default.png`}
              alt="Zero Positive Logo"
            />
            <AltLogoImg
              src={`logos/island-energy.svg`}
              alt="Island Energy Logo"
            />
          </AltLogosContainer>
        )}
      </SlideWrapperContainer>
    </SlideContext.Provider>
  );
};

export default SlideWrapper;
