import { Typography } from "@material-ui/core";
import styled from "../../../../providers/ThemeProvider";

export const LeaderboardSiteContainer = styled.div<{ first?: boolean }>(
  ({ theme, first }) => ({
    height: `calc(10% - ${theme.spacing(2.35)}px)`,
    backgroundColor: theme.palette.common.white,
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1.5),
    border: "1px solid lightgray",
    margin: theme.spacing(1.5),
    position: "relative",
    alignItems: "center",
    display: "flex",
    width: `calc(100% - ${theme.spacing(1.5)})`,

    ...(first && {
      color: theme.palette.getContrastText(theme.palette.primary.dark),
      backgroundColor: theme.palette.primary.dark,
      boxShadow: theme.shadows[2],
      border: "none",
    }),
  })
);

export const LeaderboardEnergyStat = styled(Typography).attrs({
  variant: "caption",
})(({ theme }) => ({
  // marginLeft: theme.spacing(1),
  position: "absolute",
  right: 65,
  marginTop: 5,
}));
