import { UnitList } from "../unit-converter.types";

export const unitList = ["w", "kW", "MW", "GW", "TW"] as const;

export const unitsOfMeasure: UnitList<(typeof unitList)[number]> = [
  {
    name: "watts",
    order: 1,
    unit: "w",
  },
  {
    name: "kilowatts",
    order: 2,
    unit: "kW",
  },
  {
    name: "megawatts",
    order: 3,
    unit: "MW",
  },
  {
    name: "gigawatts",
    order: 4,
    unit: "GW",
  },
  {
    name: "terawatts",
    order: 5,
    unit: "TW",
  },
];
