import { Unit, UnitType, unitTypes } from "./unitConstants";
import { getUnitConstantsFromUnitType } from "./getUnitConstantsFromUnitType";

/**
 * Mapping of dataKeys to data types
 */
export const unitTypeMappings = {
  apparentPower: "power apparent",
  averageCloudCover: "percentage",
  co2Produced: "mass",
  co2Saved: "mass",
  convertToPower: "power convert",
  cost: "money",
  current: "current",
  energyExported: "energy",
  energyImported: "energy",
  energyGenerated: "energy",
  expectedBurndown: "energy",
  gridConsumed: "energy",
  gridConsumedValue: "money",
  gridFeedIn: "energy",
  gridFeedInValue: "money",
  guaranteeBurndown: "energy",
  maxNetConsumedAvgPerPerson: "energy per person",
  minNetConsumedAvgPerPerson: "energy per person",
  netConsumed: "energy",
  netConsumedValue: "money",
  panelQuantity: "panels",
  peakCapacity: "energy peak",
  peakSolarCapacity: "energy peak",
  performance: "percentage",
  powerFactor: "blank",
  reactiveEnergy: "energy reactive",
  reactiveEnergyJoules: "energy joules",
  reactivePower: "power reactive",
  realEnergy: "energy",
  realEnergyJoules: "energy joules",
  realPower: "power",
  selfConsumed: "energy",
  selfConsumedBurndown: "energy",
  selfConsumedPercent: "percentage",
  solarConsumed: "energy",
  solarConsumedValue: "money",
  solarFeedIn: "energy",
  solarFeedInValue: "money",
  solarGenerated: "energy",
  solarGeneratedValue: "money",
  solarReturned: "energy",
  solarReturnedValue: "money",
  voltage: "voltage",
  waterSaved: "water",
} as { [key: string]: UnitType };

export const getUnitTypeFromDataKey = (dataKey?: string): UnitType =>
  dataKey ? unitTypeMappings?.[dataKey] || "blank" : "blank";

/**
 * Gets the unit type from the unit
 * (pretty expensive - try not to use it iteratively)
 * @param unit
 */
export const getUnitTypeFromUnit = (unit: Unit): UnitType => {
  let res;
  unitTypes.forEach((unitType: UnitType) => {
    if (
      (getUnitConstantsFromUnitType(unitType) as any)?.find(
        (u: Unit) => u === unit
      )
    ) {
      res = unitType;
    }
  });
  return res ? res : "blank";
};
