import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { DisplayBoardContext } from "../../Context";
import SideBarContainer from "../SideBar";
import Content from "../Content/Content";
import { getComponentList } from "../../utilities/InputJSON/InputJSON";

// theme loader DO NOT CHANGE THIS
// todo phase out
const theme = window.location.hostname.split(".")[1];
require(`../../styles/themes/${theme}/App.scss`);

const App = () => {
  const { config, type, resourceName, staticContent } = useContext(
    DisplayBoardContext
  );

  let componentList = getComponentList(
    resourceName,
    type,
    config,
    staticContent
  );

  return (
    <>
      <Helmet>
        <title>{`${`${resourceName} ` || ""}Display Board`}</title>
      </Helmet>
      <div className="d-flex flex-row align-items-stretch justify-content-start vh-100 overflow-hidden">
        <SideBarContainer />
        <Content componentList={componentList} />
      </div>
    </>
  );
};
export default App;
