import styled from "../../../providers/ThemeProvider";
import { settings } from "./EnergyFlow.consts";

export const EnergyFlowWrapper = styled.div({
  width: "100%",
  height: "100%",
  minWidth: "100%",
  minHeight: "100%",
  display: "flex",
  justifyContent: "center",
});

export const EnergyFlowContainer = styled.svg.attrs({
  xmlns: "http://www.w3.org/2000/svg",
  version: "1.1",
})({});

export const FlowDot = styled.circle.attrs({
  r: settings.dotWidth / 4,
})({});

export const OuterFlowDot = styled.circle.attrs({
  r: settings.dotWidth / 2,
  fill: "transparent",
  strokeWidth: 1,
})({});

export const FlowLink = styled.path.attrs({
  fill: "transparent",
  opacity: 0.75,
  strokeWidth: 1,
})({});

export const ElementLabel = styled.text.attrs(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
}))({
  fontSize: settings.elementLabelFontSize,
});

export const ElementValueLabel = styled.text.attrs({
  textAnchor: "middle",
})(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
}));

export const ElementCircle = styled.circle.attrs({
  r: settings.elementWidth / 2,
  strokeWidth: 1,
})({});

export const SubElementCircle = styled.circle.attrs({
  r: settings.subElementWidth / 2,
  strokeWidth: 1,
})({});

export const LoadingContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const EnergyDropdownContainer = styled.div({
  width: "33%",
  position: "absolute",
  right: "2rem",
  height: "2rem",
});
