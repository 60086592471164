import styled from "styled-components";
import { Typography } from "@material-ui/core";

// TODO add theme stuff

export const FactBoxContainer = styled.div({
  width: "100%",
  height: "100%"
});

export const ContentContainer = styled.div({
  backgroundColor: "#F2F2F2",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  borderRadius: "1rem",
  height: "100%",
  padding: "1rem",
  width: "100%",

  "& img": {
    width: "8rem"
  }
});

export const Content = styled(Typography).attrs({ variant: "h6" })(
  ({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: "center",
    width: "70%",
    lineHeight: "2rem"
  })
);
