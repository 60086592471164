import React, { useEffect } from "react";
import { useTheme, lighten } from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { parse, differenceInCalendarDays } from "date-fns";
import { EnergyHeatmapProps } from "./EnergyHeatmap.types";
import { ProfileGraphDatum } from "../../../../types/EnergyProfile";
import { getValueUnitDivisor } from "../../../../utilities/DataUtil/convertUnit";
import { Chart } from "../Graph/Graph.styled";
import { getDataKeyName } from "../../../../utilities/DataUtil/getDataKeyName";

export const EnergyHeatmap: React.FC<EnergyHeatmapProps> = ({
  data,
  dataKey,
  height = 500,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (dataKey) {
      // Day diff
      const startDate =
        data?.startTime &&
        parse(data.startTime.substr(0, 10), "yyyy-MM-dd", new Date());
      const endDate =
        data?.endTime &&
        parse(data.endTime.substr(0, 10), "yyyy-MM-dd", new Date());
      const dayDiff = differenceInCalendarDays(
        endDate || new Date(),
        startDate || new Date()
      );

      const aggregation = data?.aggregation;
      const willAggregate = aggregation === "5 minute" && dayDiff < 7;

      // Unit & Divisor
      const { unit, divisor } = getValueUnitDivisor(
        ((data?.totals?.[dataKey] || 1) / (data?.data?.length / 2)) *
          (willAggregate ? 6 : 1),
        "energy"
      );

      // Formatted Data
      let values = (data?.data).map((datum: any) => ({
        index: 10,
        [dataKey]:
          datum[dataKey] !== null && datum[dataKey] !== 0
            ? datum[dataKey] / divisor
            : -1,
        time: datum.time,
      }));

      // Aggregate to 30 minutes
      if (willAggregate) {
        const factor = 6; // 30 / 5
        values = values?.reduce((result: any, datum: any, index: any) => {
          if ((index + 1) % factor === 0 && index > 0) {
            const newDatum = datum;
            for (let i = 1; i < factor; i++) {
              const priorDatum = values?.[index - i]?.[dataKey];
              if (priorDatum) {
                newDatum[dataKey] += values[index - i][dataKey];
              }
            }
            result.push(newDatum);
            return result;
          }
          return result;
        }, []);
      }

      // Chart
      const chart = am4core.create("heatmapdiv", am4charts.XYChart);
      const zoomButtonBG = chart.zoomOutButton.background;
      zoomButtonBG.fill = am4core.color(theme.palette.secondary.main);
      if (zoomButtonBG.states.getKey("hover")?.properties?.fill) {
        (zoomButtonBG.states.getKey(
          "hover"
        ) as any).properties.fill = am4core.color(theme.palette.secondary.dark);
      }
      if (zoomButtonBG.states.getKey("down")?.properties?.fill) {
        (zoomButtonBG.states.getKey(
          "down"
        ) as any).properties.fill = am4core.color(theme.palette.secondary.main);
      }
      chart.hiddenState.properties.opacity = 0;
      chart.numberFormatter.numberFormat = "#.##s";
      chart.maskBullets = false;
      chart.data = values;
      chart.paddingRight = 25;
      chart.paddingLeft = 25;
      chart.dateFormatter.dateFormat = "h:mm a";
      chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";
      // chart.cursor = new am4charts.XYCursor();
      // chart.cursor.lineY.disabled = true;

      // X Axis
      const xAxis = chart.xAxes.push(new am4charts.DateAxis());
      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.cellStartLocation = 0;
      xAxis.renderer.cellEndLocation = 1;
      xAxis.renderer.grid.template.location = 0;
      xAxis.title.paddingBottom = 15;
      xAxis.title.text = "[bold]Time[/]";
      xAxis.tooltipDateFormat = `${dayDiff > 1 ? "EEE, MMM dd" : ""} ${
        dayDiff < 7 ? "h:mm a" : ""
      }`;
      xAxis.dateFormats.setKey("hour", "h a");
      xAxis.periodChangeDateFormats.setKey("hour", "h a");
      xAxis.dateFormats.setKey("minute", "h:mm a");
      xAxis.periodChangeDateFormats.setKey("minute", "h:mm a");
      xAxis.fill = am4core.color(
        theme?.palette?.colors?.grey?.dark || "#808080"
      );
      xAxis.title.fill = am4core.color(
        theme?.palette?.colors?.grey?.dark || "#808080"
      );
      xAxis.renderer.labels.template.fill = am4core.color(
        theme?.palette?.colors?.grey?.dark || "#808080"
      );
      // chart.cursor.xAxis = xAxis;
      // chart.cursor.fullWidthLineX = true;
      // chart.cursor.lineX.strokeWidth = 0;
      // chart.cursor.lineX.fill = am4core.color(
      //   theme?.palette?.common?.black || "black"
      // );
      // chart.cursor.lineX.fillOpacity = 0.05;

      // Y Axis
      const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.max = 10;
      yAxis.min = 0;
      yAxis.extraMin = 0;
      yAxis.extraMax = 0;
      yAxis.strictMinMax = true;
      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.__disabled = true;
      if (yAxis.tooltip) {
        yAxis.tooltip.disabled = true;
      }

      // Series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "index";
      series.dataFields.dateX = "time";
      series.dataFields.value = `${dataKey}`;
      series.defaultState.transitionDuration = 2000;
      series.sequencedInterpolation = true;
      if (series?.tooltip) {
        series.tooltip.disabled = true;
      }

      // Column Template
      const columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 0;
      columnTemplate.tooltipText = "{value}";
      columnTemplate.height = am4core.percent(100);
      columnTemplate.width = am4core.percent(100);

      // Rules
      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        min: am4core.color(
          lighten(theme?.palette?.dataKeys?.[dataKey] || "#808080", 0.9)
        ),
        max: am4core.color(theme?.palette?.dataKeys?.[dataKey] || "#808080"),
      });

      // Legend
      const heatLegend = chart.bottomAxesContainer.createChild(
        am4charts.HeatLegend
      );
      heatLegend.width = am4core.percent(100);
      heatLegend.minValue = 0;
      heatLegend.paddingBottom = 15;
      heatLegend.series = series;
      heatLegend.markerCount = 15;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 16;
      heatLegend.valueAxis.renderer.labels.template.fill = am4core.color(
        "#808080"
      );

      // Bottom Label
      const label = chart.chartContainer.createChild(am4core.Label);
      label.align = "center";
      label.text = `[bold]${getDataKeyName(dataKey)} (${unit})[/]`;
      label.fill = am4core.color(
        theme?.palette?.colors?.grey?.dark || "#808080"
      );

      // // Events
      // series.columns.template.events.on("over", function(event) {
      //   handleHover(event.target);
      // });
      // series.columns.template.events.on("hit", function(event) {
      //   handleHover(event.target);
      // });
      // series.columns.template.events.on("out", function(event) {
      //   heatLegend.valueAxis.hideTooltip();
      // });

      // // Hover handling
      // const handleHover = (column: any) => {
      //   if (!isNaN(column.dataItem.value)) {
      //     heatLegend.valueAxis.showTooltipAt(column.dataItem.value);
      //   } else {
      //     heatLegend.valueAxis.hideTooltip();
      //   }
      // };
      return () => chart.dispose();
    }
  });

  return <Chart id="heatmapdiv" height={height} />;
};
