import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";
import ErrorScreen from "../../_shared/ErrorScreen";
import { TimeSpan } from "../../../types/dataKeys";
import Graph from "../Charts/Graph/Graph";
import React, { useRef } from "react";
import {
  getLineXYChartKeyOptions,
  getBarXYChartOptions,
} from "../Charts/Graph/getKeyOptions";

export const GenericGraph = ({
  seriesType,
  suffix,
}: {
  seriesType: string;
  suffix?: string;
}) => {
  const { dataKey, timeSpan } = useSlideContext();

  const data = useTimeSpanData(timeSpan as TimeSpan, suffix);
  const keyOptions = useRef(
    seriesType === "bar" ? getBarXYChartOptions() : getLineXYChartKeyOptions()
  );

  return data ? (
    <Graph
      dataKeys={dataKey}
      graphId={"systems-graph2"}
      keyOptions={keyOptions.current}
      data={data?.data}
      isBar={seriesType === "bar"}
      totals={data?.totals}
      height="100%"
      hideScroll
    />
  ) : (
    <ErrorScreen code={500} />
  );
};

export default GenericGraph;
