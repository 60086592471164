import React, { FunctionComponent, SVGAttributes } from "react";

export const TrophyIcon: FunctionComponent<SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        d="M18 6v6.021.809c1.784-.752 2-3.531 2-4.83V6H18zM6 12.021V6H4v2c0 1.299.216 4.078 2 4.83V12.021z"
      />
      <path d="M21,4h-3V3c0-0.553-0.447-1-1-1H7C6.447,2,6,2.447,6,3v1H3C2.447,4,2,4.447,2,5v3c0,4.31,1.799,6.91,4.819,7.012 c0.88,1.509,2.396,2.597,4.181,2.898V20H9v2h6v-2h-2v-2.09c1.784-0.302,3.301-1.39,4.181-2.898C20.201,14.91,22,12.31,22,8V5 C22,4.447,21.553,4,21,4z M4,8V6h2v6.021v0.809C4.216,12.078,4,9.299,4,8z M18,12.83v-0.809V6h2v2C20,9.299,19.784,12.078,18,12.83 z" />
    </svg>
  );
};
