import { getGraphColors } from "../../components/Slides/Charts/Graph/Graph.utils";
import { KeyOptions } from "../../components/Slides/Charts/Graph/getKeyOptions";
import { autoFormatValue, getValueUnitDivisor } from "../DataUtil/convertUnit";
import * as am4charts from "@amcharts/amcharts4/charts";
import { XYChart } from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { MutableRefObject, Dispatch } from "react";
import { Theme } from "@material-ui/core";
import { getUnitTypeFromDataKey } from "../DataUtil/getUnitType";

export const buildSeries = (
  dataKey: string,
  unit: string,
  yAxis: am4charts.ValueAxis,
  chart: XYChart | null,
  autoSeriesColorIndex: MutableRefObject<number>,
  keyOptions: KeyOptions,
  theme: Theme,
  isBar: boolean,
  getKeyName: Function,
  scrollbarX: am4charts.XYChartScrollbar,
  hideScroll?: boolean,
  totals?: any,
  showTotalsInName?: boolean
) => {
  if (chart) {
    const series = chart.series.push(
      isBar && !keyOptions[dataKey].forceLine
        ? new am4charts.ColumnSeries()
        : new am4charts.LineSeries()
    );

    series.dataFields.dateX = "time";
    series.dataFields.categoryX = "weatherIcon";
    series.dataFields.valueY = dataKey;
    series.name =
      showTotalsInName && totals?.[dataKey]
        ? `${getKeyName(dataKey)} (${autoFormatValue(
            totals[dataKey],
            "solarGenerated"
          )})`
        : getKeyName(dataKey);
    series.showOnInit = true;
    series.yAxis = yAxis;
    series.strokeWidth = 1;
    series.strokeDasharray = keyOptions[dataKey].dashedStroke ? "3,3" : "";
    series.fillOpacity = keyOptions[dataKey].opacity || 0.75;
    if (theme.palette.dataKeys?.[dataKey]) {
      series.fill = am4core.color(theme.palette.dataKeys[dataKey]);
      series.stroke = am4core.color(theme.palette.dataKeys[dataKey]);
    } else {
      autoSeriesColorIndex.current++;
      const colors = getGraphColors();
      series.fill = am4core.color(colors[autoSeriesColorIndex.current % 9]);
      series.stroke = am4core.color(colors[autoSeriesColorIndex.current % 9]);
    }
    if (!keyOptions[dataKey].filled) {
      series.strokeWidth = 1;
      series.fillOpacity = 0;
    } else {
    }

    series.tooltipHTML = `${getKeyName(
      dataKey
    )}: <strong>{valueY.value} ${unit}</strong>`;
    series?.tooltip?.label?.adapter.add("htmlOutput", (text, target, key) => {
      if (target.dataItem) {
        if (target.dataItem.values.valueY.value === 0) {
          return "";
        }
      }
      return text;
    });

    // if column charts
    if (isBar && !keyOptions[dataKey].forceLine) {
      series.stacked = keyOptions[dataKey].stacked; // note for stack, the order of datakeys must have the stacked values together at the bottom
      series.zIndex = keyOptions[dataKey].zIndex || 1;
    } else if (keyOptions[dataKey].forceLine || !isBar) {
      series.zIndex = keyOptions[dataKey].zIndex || 4;
      series.strokeWidth = 1;
      series.strokeOpacity = 1;
    }
    if (!hideScroll) {
      scrollbarX.series.push(series);
    }
  }
};

export const getGraphUnitDivisor = (
  data: any[],
  dataKeys: string[],
  secondaryDataKeys?: string[],
  totals?: any,
  forcePower?: boolean
) => {
  let pivotSum = 0;
  let typeCount = 0;
  let secondaryPivotSum = 0;
  let secondaryTypeCount = 0;
  if (totals) {
    dataKeys.forEach((dataKey: string) => {
      if (totals[dataKey]) {
        pivotSum += totals[dataKey];
        typeCount++;
      }
    });
    secondaryDataKeys?.forEach((secondaryDataKey) => {
      if (totals[secondaryDataKey]) {
        secondaryPivotSum += totals[secondaryDataKey];
        secondaryTypeCount++;
      }
    });
  }

  // for different object notation
  const length = data.length || Object.keys(data).length;

  const pivot = typeCount
    ? pivotSum / length / (typeCount * 2) // dumb heuristic but it owrks
    : forcePower
    ? 1000
    : data?.[Math.floor(length / 2)]?.[dataKeys[length - 1]] || 1; // Default to middle of the dataset if there is no totals

  const secondaryPivot =
    secondaryDataKeys?.length && secondaryTypeCount
      ? secondaryPivotSum / length / (secondaryTypeCount * 2)
      : data?.[Math.floor(length / 2)]?.[
          secondaryDataKeys?.[length - 1 || 0] || 0
        ] || 1; // Default to middle of the dataset if there is no totals
  const { unit, divisor } = getValueUnitDivisor(
    pivot,
    getUnitTypeFromDataKey(dataKeys[0]),
    forcePower
  );
  const {
    unit: secondaryUnit,
    divisor: secondaryDivisor,
  } = getValueUnitDivisor(
    secondaryPivotSum,
    getUnitTypeFromDataKey(secondaryDataKeys?.[0])
  );

  return { unit, divisor, secondaryUnit, secondaryDivisor };
};
