import { IUnitOfMeasure } from "./HighestUnitConverter.types";
import * as math from "mathjs";

export const convert = ({ value, type }: IUnitOfMeasure) => {
  const unitsOfMeasure = {
    energy: ["Wh", "kWh", "MWh", "GWh", "TWh"],
    mass: ["g", "kg", "tonne", "kilotonne", "megatonne", "gigatonne"]
  };

  const defaultUnit = unitsOfMeasure[type][0];

  if (value === null) {
    return "N/A";
  }

  if (value === 0) {
    return `0 ${defaultUnit}`;
  }

  const units = unitsOfMeasure[type].map(unitOfMeasure => ({
    unit: unitOfMeasure,
    value: math
      .unit(value, defaultUnit)
      .to(unitOfMeasure)
      .toNumber(unitOfMeasure)
  }));

  const selectedUnit = units.filter(
    cUnit => cUnit.value > 1 && cUnit.value < 1000
  )[0] || { unit: defaultUnit, value: 0 };

  const { value: unitValue, unit } = selectedUnit;
  const fixedAmount = unit !== defaultUnit ? 2 : 0;

  return `${unitValue.toFixed(fixedAmount)} ${unit}`;
};
