import { ContentContainer, Content } from "../../FactBox.styles";
import CoalImg from "../../images/SS_CO2_Coal.png";
import { SolarGeneratedProps } from "../../FactBox.types";
import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { convert } from "../../../../../utilities/HighestUnitConverter";
import { getTimeString } from "../../FactBox.utilities";

export const CoalSlide: FunctionComponent<SolarGeneratedProps> = ({
  startTime,
  solarGenerated,
  timeSpan
}) => {
  const formula = (total: number, dividedBy: number) =>
    +(total / dividedBy).toFixed(2);

  const result = solarGenerated && formula(solarGenerated / 1000, 8.141);

  return startTime && result ? (
    <ContentContainer>
      <img src={CoalImg} alt="Solar Energy Coal Comparison" />
      <Content>
        {getTimeString(timeSpan, startTime)}, you have generated
        <Box fontWeight={900} component="span">
          {" "}
          {convert({ type: "energy", value: solarGenerated })}
        </Box>{" "}
        of solar energy. <br />
        That's equivalent to the heat energy created from burning
        <Box fontWeight={900} component="span">
          {" "}
          {result > 1000 ? (result / 1000).toFixed(2) : result}
        </Box>{" "}
        {result > 1000 ? "tonnes" : "kilograms"} of coal!
      </Content>
    </ContentContainer>
  ) : null;
};
