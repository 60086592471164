import React from "react";
import styled from "../../../providers/ThemeProvider";

// TODO - will be rewritten
// Constructs a div that displays a formatted title and subtitle when given props
// titlePart1 = bold part of the title, titlePart2 = the rest
const TitleBox = (props) => {
  // ... replace ₂ with <sub>2</sub>
  const subTitle = props.subTitle.includes("₂") ? (
    <span>
      {props.subTitle.substr(0, props.subTitle.indexOf("₂"))}
      <sub>2</sub>
      {props.subTitle.substr(
        props.subTitle.indexOf("₂") + 1,
        props.subTitle.length
      )}
    </span>
  ) : (
    props.subTitle
  );

  const TempContainer = styled.div(({ theme }) => ({
    padding: theme.spacing(4, 4, 4, 4),
  }));

  return (
    <div style={{ height: "15%" }}>
      <TempContainer className="noresize">
        <div className="h1">
          <b style={{ marginRight: "5px" }}>
            {String(props.titlePart1).toUpperCase()}
          </b>
          {/* {props?.titlePart2.toUpperCase()} */}
        </div>
        <div className="h2">{subTitle}</div>
      </TempContainer>
    </div>
  );
};

export default TitleBox;
