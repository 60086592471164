import styled from "../../../providers/ThemeProvider";
import { Typography } from "@material-ui/core";

export const GenericInfoContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  width: "80%",
});

export const GenericInfoText = styled(Typography).attrs({ variant: "h6" })(
  ({ theme }) => ({
    color: theme.palette.common.white,
  })
);

export const GenericInfoContent = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
});

export const PanelInfoIcon = styled.img.attrs({
  alt: "Panel Information Icon",
  src: "metric-icons/Solar Generation.svg",
})({
  opacity: 1,
  height: "65px",
  width: "65px",
  alignSelf: "center",
  marginRight: "1rem",
});

export const Co2InfoIcon = styled.img.attrs({
  alt: "Generic Information Icon",
  src: "metric-icons/CO2 Saved.svg",
})({
  opacity: 1,
  height: "65px",
  width: "65px",
  alignSelf: "center",
  marginRight: "1rem",
});
