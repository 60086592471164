import styled from "../../../../providers/ThemeProvider/ThemeProvider.core";

export const EventInfoContent = styled.div({
  background: "white",
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 2fr",
  padding: "1rem",
  width: "100%",
});

export const DataTerm = styled.dt({
  margin: 0,
  fontWeight: "bold",
});

export const DataDefinition = styled.dd({
  margin: 0,
});

export const EventGroupLogo = styled.img({
  width: "100%",
  height: "auto",
  borderRadius: "8px",
});
