import styled from "../../../../providers/ThemeProvider";
import { Typography } from "@material-ui/core";
import {
  BarParams,
  BarTextParams,
  LegendColourParams,
} from "./BarComparison.types";

export const BarComparisonStoriesContainer = styled.div({
  width: "100px",
  height: "400px",
});

export const BarComparisonContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
});

export const BarComparisonTitle = styled(Typography).attrs({
  variant: "caption",
})({
  textAlign: "center",
  fontWeight: 900,
});

export const BarsContainer = styled.div(({ theme }) => ({
  padding: theme.spacing(1, 0, 0, 0),
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  marginBottom: "1rem",
}));

export const Bar = styled.div<BarParams>(
  ({ theme, dataKey, barHeight, valid }) => ({
    opacity: valid ? 1 : 0.5,
    backgroundColor: theme.palette.dataKeys[dataKey as string],
    height: barHeight,
    color: theme.palette.text.secondary,
  })
);

export const BarText = styled(Typography).attrs({
  variant: "h6",
})<BarTextParams>(({ theme, valid, textcolor }) => ({
  color: "#fff",
  // textcolor === "rgba(0, 0, 0, 0.87)"
  //   ? theme.palette.text.primary
  //   : textcolor,
  display: valid ? "block" : "none",
  fontWeight: 900,
  zIndex: theme.zIndex.mobileStepper,
  width: "100%",
  padding: theme.spacing(1, 2, 1, 2),
  textAlign: "center",
}));

export const LegendContainer = styled.div({
  display: "flex",
  flexDirection: "column",
});

export const LegendElement = styled.div(({ theme }) => ({
  paddingTop: theme.spacing(2),
  display: "flex",
  height: "50%",
}));

export const LegendColour = styled.div<LegendColourParams>(
  ({ theme, dataKey, valid }) => ({
    opacity: valid ? 1 : 0.5,
    backgroundColor: theme.palette.dataKeys[dataKey],
    width: "30%",
    height: "100%",
  })
);

export const LegendText = styled(Typography).attrs({
  variant: "caption",
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: 900,
  paddingLeft: theme.spacing(1),
}));
