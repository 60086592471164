import React from "react";
import { createGlobalStyle } from "styled-components";
import { FunctionComponent } from "react";
import { Theme } from "@material-ui/core";

export const ThemedGlobalStyles: FunctionComponent<{ theme: Theme }> = ({
  theme,
}) => {
  const GlobalStyles = createGlobalStyle`
    @import url('MartelSans.ttf');
    html,
    body {
        color: ${theme.palette.text.primary};
        min-width: 500px;
        height: 100%;
        font-family: 'Martel Sans', sans-serif !important;
        overflow: hidden;
    }`;

  return <GlobalStyles />;
};
