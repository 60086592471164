import React from "react";

const BurjKhalifa = props => {
  let { className, position, ...otherProps } = props;
  let classNames = ["", className].join(" ");

  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 82 400" xmlSpace="preserve"
      {...otherProps}
      className={classNames}
      style={Object.assign({minWidth: "10px", minHeight: "10px", fill: props.color}, position)}
      >
    <g>
      <path d="M0,400V390H8v-5.51h4.51V380a8.68,8.68,0,0,1,4.76-1.5V333.92a4.72,4.72,0,0,1,3.76-.5V281.85a5.15,5.15,0,0,1,4.26,0V221.28s2-2,4.26-1V145.43a6.33,6.33,0,0,1,3.76-1.25l.5-26,1.5-2.25v-17a1.49,1.49,0,0,1,1.76-1.25V64.33l1.25-3-.25-23.78,1.76-1.25-.25-13.52.5-2.5V0l1.25,27.53.75,2.25-.25,14.77,1,1.75-.25,9.51s.75-.75,1.25,5.76.25,20.28.25,20.28,1,.75,1.5,11a105.75,105.75,0,0,1,0,14.77s1.5-.75,1.5,6.26v21.78s1.76.5,1.76,5.51V173s3.51.75,4,2,0,66.33,0,66.33,3.26.25,3.76,1.75,0,55.82,0,55.82h4v46.06s2.76-.25,3.51.75v31.79h4.51V382h4.51v5.26H82V400Z"/>
    </g>
    </svg>
  );
};

export default BurjKhalifa;
