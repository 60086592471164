import { DisplayBoardContext } from "../../../Context";
import React, { useContext } from "react";
import {
  GenericInfoText,
  GenericInfoContainer,
  PanelInfoIcon,
  GenericInfoContent,
} from "./GenericInfo.styled";
import { autoFormatValue } from "../../../utilities/DataUtil/convertUnit";

const formatWithCommas = (x: number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const PanelInfo = () => {
  const ctx = useContext(DisplayBoardContext);
  const peakCapacity = ctx?.data?.summary?.solar?.peakCapacity || 0;
  const panelQuantity = ctx?.data?.summary?.solar?.panelQuantity || 0;

  return (
    <GenericInfoContainer>
      <PanelInfoIcon />
      <GenericInfoContent>
        <GenericInfoText>
          {formatWithCommas(panelQuantity)} panels
        </GenericInfoText>
        <GenericInfoText>
          {autoFormatValue(peakCapacity, "peakSolarCapacity")}
        </GenericInfoText>
      </GenericInfoContent>
    </GenericInfoContainer>
  );
};
