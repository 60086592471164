import { getGradientColor, rangeColors } from "./PlanetWatch.utils";
import styled from "../../../providers/ThemeProvider";
import React from "react";

const rangeHeight = "2rem";
const bulletFontSize = 15;
const bulletPadding = 3;
const bulletSize = 7;

/*styles*/
const Range = styled.div<{ value: number }>(({ theme, value }) => ({
  position: "absolute",
  left: `${value}%`,
}));

const Bullet = styled.div<{ value: number }>(({ theme, value }) => ({
  top: `calc((-${rangeHeight}/2) - ${bulletSize * 2 + bulletPadding}px)`,
  borderRight: `${bulletSize}px solid transparent`,
  borderLeft: `${bulletSize}px solid transparent`,
  color: getGradientColor(value / 100, true),
  position: "absolute",
  height: 0,
  width: 0,
  borderTop: `${bulletSize * 1.5}px solid ${getGradientColor(
    value / 100,
    true
  )}`,
}));

const BulletText = styled.div({
  top: `-${bulletSize * 2 + bulletFontSize + bulletPadding}px`,
  transform: "translateX(-50%)",
  fontSize: bulletFontSize,
  position: "absolute",
  textAlign: "center",
  fontWeight: "bold",
  width: "10rem",
});

const Container = styled.div(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(4),
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "100%",
}));

const RangeContainer = styled.div(({ theme }) => ({
  backgroundImage: `linear-gradient(to left, ${rangeColors.join(", ")})`,
  boxShadow: theme.shadows[15],
  justifyContent: "center",
  borderRadius: "0.5rem",
  position: "relative",
  alignItems: "center",
  height: rangeHeight,
  color: "#121212",
  display: "flex",
  width: "60%",
}));

export const PlanetWatchRange = ({ value }: { value: number }) => (
  <Container>
    <RangeContainer>
      Consumption Score
      <Range value={value}>
        <Bullet value={value}>
          <BulletText>{`${value > 50 ? "Top" : "Bottom"} ${
            value > 50 ? 100 - value : value
          }%`}</BulletText>
        </Bullet>
      </Range>
    </RangeContainer>
  </Container>
);
