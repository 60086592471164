import { DataType } from "../../types/dataKeys";

const getDataType = (dataKey: DataType) => {
  switch (dataKey) {
    case "batteryStored":
      return "energy";
    case "gridStored":
      return "energy";
    case "solarStored":
      return "energy";
    case "batteryFeedIn":
      return "energy";
    case "energyImported":
      return "energy";
    case "energyExported":
      return "energy";
    case "energyGenerated":
      return "energy";
    case "solarConsumed":
      return "energy";
    case "waterSaved":
      return "water";
    case "gridConsumed":
      return "energy";
    case "netConsumed":
      return "energy";
    case "solarFeedIn":
      return "energy";
    case "solarGenerated":
      return "energy";
    case "peakSolarCapacity":
      return "energy peak";
    case "peakCapacity":
      return "energy peak";
    case "co2Produced":
      return "mass";
    case "co2Saved":
      return "mass";
    case "selfConsumed":
      return "energy";
    case "selfConsumedPercent":
      return "percentage";
    case "performance":
      return "percentage";
    case "cost":
      return "money";
    case "averageCloudCover":
      return "percentage";
    case "voltage":
      return "voltage";
    case "current":
      return "current";
    case "realEnergyJoules":
      return "energy joules";
    case "realEnergy":
      return "energy";
    case "realPower":
      return "power";
    case "reactiveEnergyJoules":
      return "energy joules";
    case "reactiveEnergy":
      return "energy reactive";
    case "reactivePower":
      return "power reactive";
    case "apparentPower":
      return "power apparent";
    case "powerFactor":
      return "blank";
    default:
      return "blank";
  }
};

export default getDataType;
