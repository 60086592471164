import { StatBoxContainerProps } from "./StatBox.types";
import styled from "../../../../providers/ThemeProvider";
import { Typography } from "@material-ui/core";

export const StatBoxContainer = styled.div<StatBoxContainerProps>(
  ({ theme, bgColor }) => ({
    //TODO program pxwidth
    width: "23%",
    alignSelf: "stretch",
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1.5),
    backgroundColor: bgColor,
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    overflow: "hidden"
  })
);

export const StatBoxIcon = styled.img(({ theme }) => ({
  opacity: 1,
  height: "45px",
  width: "45px",
  alignSelf: "center"
}));

export const StatBoxTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  paddingBottom: theme.spacing(1.5)
}));

export const StatBoxContentContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  justifyContent: "space-between"
}));

export const StatBoxStat = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(0.5),
  lineHeight: theme.typography.caption.fontSize
}));
