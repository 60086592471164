import { determineBestUnit } from "@solarschools/component-lib/src/utilities/EnergyUnits";
import * as math from "mathjs";

/**
 * auto formats the energy value based on the best unit
 * @param {*} value the number in wh
 */
export const autoFormatToWatt = (value: number) => {
  const unit = determineBestUnit(value * 12);
  const num = math
    .unit(value, "Wh")
    .to(unit)
    .toJSON() as any;
  return `${(parseFloat(num.value) * 12)
    .toFixed(2)
    .replace(/[.,]00$/, "")
    .toLocaleString()}  ${num.unit.substr(0, num.unit.length - 1)}`;
};
