import React from "react";

const Human = props => {
  let { className, position, ...otherProps } = props;
  let classNames = ["", className].join(" ");

  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 110 400" xmlSpace="preserve"
      {...otherProps}
      className={classNames}
      style={Object.assign({minHeight: "2px", fill: props.color}, position)}
      >
    <g>
      <path d="M20.59,21.49s-3,2.39-2.08,11,12.18,18.8,12.18,18.8l4.75,9.85A30.24,30.24,0,0,1,25,66.55s-5.35,5.07-11.59,6.86S1.57,89.23,1,97.29s-.89,39.1-.89,39.1-.89,30.44,3,46.56,3.86,20.89,3.86,20.89.59,9,2.08,11.94L9.59,225a92,92,0,0,0,3.86,13.43v11.94a19.43,19.43,0,0,0,1.78,6.86S13.75,269.79,16.43,274c0,0-3.56,12.83-2.67,20.29l-1.19,4.18-2.38,13.13s-2.67,9.85-.89,29.55c0,0,1.49,9.55.59,11.94s-3.27,7.16-.3,11-.3,7.46-.3,7.46L.68,387.67S.09,399.31,7.81,399.9s11-1.79,11-1.79,4.16-2.69,4.46-10.45,2.08-6.27,2.08-6.27,4.16-2.69,2.38-12.24c0,0,3.86-2.09,4.75-14.32L34.25,345s.3-11.34.59-24.17,5.94-37.6,5.94-37.6a248.63,248.63,0,0,0,5-27.46C47.32,242.63,50.29,239,50.29,239s.89-5.67,2.38,13.73a30.44,30.44,0,0,1,1.49,7.16c0,2.69-.59,3.88.59,6l-.59,6,.59,9.85s2.67,5.37,1.78,9a12.23,12.23,0,0,0,0,6.27l-.3,50.73a13,13,0,0,0,3.27,7.46c3,3,0,4.18,0,4.18s-2.08,2.69-.89,7.46.3,7.16,6.54,8.36,9.51-.6,19.31,7.16a17.43,17.43,0,0,0,19.31,2.09s6.83-3.58-6.24-12.24-18.42-17-18.42-17-2.08-2.39-.3-4.48,4.46-6.27-1.49-12.24c0,0,3.56-11,3.56-16.41s.59-24.17.59-24.17l-1.78-3,1.49-2.09-1.49-2.69s1.49-2.69,1.49-8.65c0,0-1.19-4.77-.3-6.57S83,266.8,83,266.8s3.27-11.94,4.46-40.59c0,0,.3-17.31.3-20.89l1.19-2.39a47.56,47.56,0,0,1-3.56-14c-.59-7.46-2.08-15.52-2.08-15.52l-1.49-9.85,13.66,31.34s2.08,4.18-.3,11.64-.3,9.55-.3,9.55-.3,10.15,1.78,11.94c0,0-2.67,2.09-.59,3.88s4.75.3,4.75.3,7.13-2.69,7.72-4.48,1.19-9,1.19-9l.3-3.58-4.16-12.24s-3-14.92-3.56-22.68S101.09,151.9,96,138.47a81.27,81.27,0,0,1-5-25.67l4.16-2.09S89.5,93.11,88.61,88,81.78,73.12,74.35,71.92L61.88,66s-3.56-5.07.89-9.25,7.43-8.06,10.4-9,2.38-6.27,0-6.57c0,0,2.38-3.88,0-4.48,0,0,0-4.18-1.49-5.07l1.19-2.69s2.67-1.79-2.67-4.48S63.36,20,63.06,17.61s-5.64-6.86-5.64-6.86l-.3-4.18L53.56,2.69,49.4,1.49,46.43,0,39.89,3.58S20.29,6,20.59,21.49Z"/>
    </g>
    </svg>
  );
};

export default Human;
