import { ContentContainer, Content } from "../../FactBox.styles";
import FridgeImg from "../../images/SS_CO2_Fridge.png";
import { SolarGeneratedProps } from "../../FactBox.types";
import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { convert } from "../../../../../utilities/HighestUnitConverter";
import { getTimeString } from "../../FactBox.utilities";

export const FridgeSlide: FunctionComponent<SolarGeneratedProps> = ({
  startTime,
  solarGenerated,
  timeSpan
}) => {
  const formula = (total: number, dividedBy: number) =>
    +(total / dividedBy).toFixed(2);

  return startTime && solarGenerated ? (
    <ContentContainer>
      <img src={FridgeImg} alt="Solar Energy Frost Free Fridge Comparison" />
      <Content>
        {" "}
        {getTimeString(timeSpan, startTime)}, you have generated
        <Box fontWeight={900} component="span">
          {" "}
          {convert({ type: "energy", value: solarGenerated })}
        </Box>{" "}
        of solar energy. <br />
        That's enough to run
        <Box fontWeight={900} component="span">
          {" "}
          {formula(solarGenerated / 1000, 365)}
        </Box>{" "}
        frost free fridges for one year!
      </Content>
    </ContentContainer>
  ) : null;
};
