import React from "react";
import * as THREE from "three";
import Human from "./comparison-icons/Human";
import TaneMahuta from "./comparison-icons/TaneMahuta";
import StatueOfLiberty from "./comparison-icons/StatueOfLiberty";
import BurjKhalifa from "./comparison-icons/BurjKhalifa";
import { useTheme } from "../../../hooks/Context Hooks/theming/useTheme";

// todo -- a lot abstraction to utiltiies and hooks
// but im lazy - sorry and good luck
const CO2Stack = ({ optimize = true, superOptimize = false, co2, dataKey }) => {
  const { useRef, useEffect, useState } = React;
  const mount = useRef(null);
  const [isAnimating, setAnimating] = useState(true);
  const controls = useRef(null);
  const [SVGHeight, setSVGHeight] = useState(null);
  const [scaleName, setScaleName] = useState(null);
  const theme = useTheme();
  const color = { color: theme?.palette.dataKeys[dataKey] || "#5E5D63" };

  // Heights
  const human = 1.75;
  const taneMahuta = 45.2;
  const statueOfLiberty = 93;
  const burjKhalifa = 830;

  // get ballcount
  const gh = 0.1007;
  let unitScale;
  let volumeScale;

  // if g sphere
  if (co2 < 1e3) {
    unitScale = 10;
    volumeScale = 1;
    // if kg sphere
  } else if (co2 < 1e6) {
    unitScale = 1e4;
    volumeScale = 10;
    // if tonne sphere
  } else if (co2 < 1e9) {
    unitScale = 1e7;
    volumeScale = 100;
    // if kilotonne sphere
  } else if (co2 < 1e12) {
    unitScale = 1e10;
    volumeScale = 1e3;
    // if megatonne sphere
  } else {
    unitScale = 1e13;
    volumeScale = 1e4;
  }
  const ballCount = Math.round(co2 / (gh * unitScale));

  // Get an appropriate rowCount
  let rowCount;
  if (ballCount < 40) {
    rowCount = 3;
  } else if (ballCount < 80) {
    rowCount = 4;
  } else if (ballCount < 400) {
    rowCount = 5;
  } else if (ballCount < 1500) {
    rowCount = 7;
  } else if (ballCount < 8000) {
    rowCount = 15;
  } else {
    rowCount = 20;
  }
  const layerCount = rowCount * rowCount;
  let sphereRadius = volumeScale === 1 ? 0.5 : (layerCount * 10) / ballCount;
  // minimum
  if (sphereRadius > 1.1) {
    sphereRadius = 1.1;
  }
  const sphereDiameter = sphereRadius * 2;

  useEffect(() => {
    if (ballCount) {
      // Setup ===
      const viewAngle = 45;
      const nearClipping = 0.1;
      const farClipping = 1000;
      const scene = new THREE.Scene();
      let width = mount.current.clientWidth;
      let height = mount.current.clientHeight;
      let frameId;

      // Camera ===
      let camera = new THREE.PerspectiveCamera(
        viewAngle,
        width / height,
        nearClipping,
        farClipping
      );
      camera.position.x = -sphereRadius;
      camera.position.y = 9.8 + sphereDiameter;
      let renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      renderer.setSize(width, height);
      document.body.appendChild(renderer.domElement);

      // Balls ===
      const segments = superOptimize ? 8 : 16;
      const sphereGeometry = new THREE.SphereGeometry(
        sphereRadius,
        segments,
        segments
      );
      const sphereMaterial = new THREE.MeshLambertMaterial(color);

      let stack = 0;
      const stackAmount = Math.ceil(ballCount / layerCount);
      let count = 0;
      let offset = 0;
      let zOffset = 25;
      let zVal = 0;
      let isHalfLayer = false;
      /*  in order to calculate the visible height in pixels of a sphere,
        i have to form the stack angled, without rotating the camera, therefore the 
        mess below is actually creating the stack as if it was placed like (variable depending on rowCount)
        this from front to back (h represents hidden spheres when optimizing):
                 x
                x x
               x h x
              x h h x
               x h x
                x x
                 x
    */
      for (let i = 0; i < ballCount; i++) {
        if (i % layerCount === 0) {
          count = 0;
          offset = 0;
          isHalfLayer = false;
          zVal = 0;
          stack++;
        }

        if (offset === rowCount && count === offset) {
          isHalfLayer = true;
        }

        if (count === offset) {
          isHalfLayer ? offset-- : offset++;
          zVal++;
          count = 0;
        }

        // OPTIMIZATION
        // Hide the ball if it isn't a top 2 layer, and is in the inside.
        let hide = false;
        if (
          stack <= stackAmount - 2 &&
          count !== 0 &&
          count !== offset - 1 &&
          optimize
        ) {
          hide = true;
        }
        // SUPER OPTIMIZATION
        // Remove every ball except the front facing and the top 2 layers
        if (superOptimize && isHalfLayer && stack <= stackAmount - 2) {
          hide = true;
        }

        if (!hide) {
          const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
          sphere.position.z = -zOffset - zVal * sphereDiameter;
          sphere.position.x = count * sphereDiameter - offset * sphereRadius;
          sphere.position.y = stack * sphereDiameter;
          scene.add(sphere);
        }
        count++;
      }

      // Calculate the pixel height of the front face of the closest ball
      const vFOV = (camera.fov * Math.PI) / 180; // convert vertical fov to radians
      const h = 2 * Math.tan(vFOV / 2) * zOffset; // visible height
      const pixelHeight = height * (sphereDiameter / h);

      /* 
      (assuming CO2 at 15 degrees and standard pressure)
      density: 1.87kg/m3
    */
      // get balltower height
      const realBallHeight = gh * volumeScale;
      const maxBC = (9 * layerCount) / sphereRadius;
      const bHeight = Math.ceil(maxBC / layerCount) * realBallHeight;
      // get SVG
      if (bHeight < taneMahuta) {
        setSVGHeight((human / realBallHeight) * pixelHeight);
        setScaleName("human"); // cant set the actual component due to stale closure
      } else if (bHeight < statueOfLiberty) {
        setSVGHeight((taneMahuta / realBallHeight) * pixelHeight);
        setScaleName("tane mahuta");
      } else if (bHeight < burjKhalifa) {
        setSVGHeight((statueOfLiberty / realBallHeight) * pixelHeight);
        setScaleName("statue of liberty");
      } else {
        setSVGHeight((burjKhalifa / realBallHeight) * pixelHeight);
        setScaleName("burj khalifa");
      }

      // Light ===
      const light = new THREE.PointLight(0xffffff);
      light.position.x = 1;
      light.position.y = 20;
      light.position.z = 1;
      scene.add(light);
      let lightAngle = 0;

      const renderScene = () => {
        renderer.render(scene, camera);
      };

      const handleResize = () => {
        width = mount.current.clientWidth;
        height = mount.current.clientHeight;
        renderer.setSize(width, height);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderScene();
      };

      const animate = () => {
        if (!superOptimize) {
          lightAngle += 5;
          if (lightAngle > 360) {
            lightAngle = 0;
          }
          light.position.x = 5 * Math.cos((lightAngle * Math.PI) / 180);
          light.position.z = 5 * Math.sin((lightAngle * Math.PI) / 180);
        }

        renderScene();
        frameId = window.requestAnimationFrame(animate);
      };

      const start = () => {
        if (!frameId) {
          frameId = requestAnimationFrame(animate);
        }
      };

      const stop = () => {
        cancelAnimationFrame(frameId);
        frameId = null;
      };

      mount.current.appendChild(renderer.domElement);
      window.addEventListener("resize", handleResize);
      start();

      controls.current = { start, stop };
      // cleanup
      return () => {
        stop();
        window.removeEventListener("resize", handleResize);
        mount.current.removeChild(renderer.domElement);

        for (var i = scene.children.length - 1; i >= 0; i--) {
          scene.remove(scene.children[i]);
        }
        sphereGeometry.dispose();
        sphereMaterial.dispose();
        scene.dispose();
        renderer.dispose();
        renderer.forceContextLoss();
        renderer.context = null;
        renderer.domElement = null;
        renderer = null;
      };
    }
  }, []);

  useEffect(() => {
    if (isAnimating && controls.current) {
      controls.current.start();
    } else if (controls.current) {
      controls.current.stop();
    }
  }, [isAnimating]);

  let component;

  // image aspect ratios for getting label width from height
  const humanAspect = 110 / 400;
  const taneMahutaAspect = 377 / 400;
  const statueOfLibertyAspect = 115 / 400;
  const burjKhalifaAspect = 82 / 400;

  let humanLabelWidth = SVGHeight * humanAspect;
  let humanSVGHeight;
  let taneMahutaLabelWidth = SVGHeight * taneMahutaAspect;
  // let taneMahutaSVGHeight;
  let statueOfLibertyLabelWidth = SVGHeight * statueOfLibertyAspect;
  let statueOfLibertySVGHeight;
  let burjKhalifaLabelWidth = SVGHeight * burjKhalifaAspect;

  switch (scaleName) {
    case "human":
      component = SVGHeight ? (
        <>
          <div
            className="text-center position-absolute h4"
            style={{
              width: humanLabelWidth,
              bottom: SVGHeight + 10,
              left: "15%",
            }}
          >
            <b>Human</b> (1.75m){" "}
          </div>
          <Human
            position={{ left: "15%", bottom: "1%" }}
            className="position-absolute"
            height={SVGHeight}
            fill="#2e2e2e"
          />
        </>
      ) : null;
      break;
    case "tane mahuta":
      humanSVGHeight = SVGHeight * (human / taneMahuta);
      humanLabelWidth = humanSVGHeight * humanAspect;
      component = SVGHeight ? (
        <>
          <div
            className="text-center position-absolute h4"
            style={{
              width: humanLabelWidth,
              bottom: humanSVGHeight + 10,
              left: "2%",
            }}
          >
            <b>Human</b> (1.75m){" "}
          </div>
          <div
            className="text-center position-absolute h4"
            style={{
              width: taneMahutaLabelWidth,
              bottom: SVGHeight + 10,
              left: "2%",
            }}
          >
            <b>Tane Mahuta</b> (45.2m)
          </div>
          <Human
            position={{ left: "2%", bottom: "1%" }}
            className="position-absolute"
            height={SVGHeight * (human / taneMahuta)}
            fill="#2e2e2e"
          />
          <TaneMahuta
            position={{ left: "2%", bottom: "1%" }}
            className="position-absolute"
            height={SVGHeight}
            fill="#2e2e2e"
          />
        </>
      ) : null;
      break;
    case "statue of liberty":
      // (looks kind of weird with taneMahuta next to it because the scales are similar)
      // let taneMahutaSVGHeight = (SVGHeight * (taneMahuta/statueOfLiberty))
      // taneMahutaLabelWidth = taneMahutaSVGHeight * taneMahutaAspect;
      humanSVGHeight = SVGHeight * (human / statueOfLiberty);
      humanLabelWidth = humanSVGHeight * humanAspect;
      component = SVGHeight ? (
        <>
          {/* <div className="text-center position-absolute h4" style={{width: taneMahutaLabelWidth, bottom: taneMahutaSVGHeight + 10, left: "0%"}}><b>Tane Mahuta</b> (45.2m) </div> */}
          <div
            className="text-center position-absolute h4"
            style={{
              width: humanLabelWidth,
              bottom: humanSVGHeight + 10,
              left: "5%",
            }}
          >
            <b>Human</b> (1.75m){" "}
          </div>
          <div
            className="text-center position-absolute h3"
            style={{
              width: statueOfLibertyLabelWidth,
              bottom: SVGHeight + 10,
              left: "15%",
            }}
          >
            <b>Statue of Liberty</b> (83m)
          </div>
          {/* {(taneMahuta/statueOfLiberty) * SVGHeight > 1 ? <TaneMahuta position={{left: "0%", bottom: "1%"}} className="position-absolute" height={SVGHeight * (taneMahuta/statueOfLiberty)} fill="#2e2e2e"/> : null} */}
          {humanSVGHeight > 1 ? (
            <Human
              position={{ left: "5%", bottom: "1%" }}
              className="position-absolute"
              height={SVGHeight * (human / taneMahuta)}
              fill="#2e2e2e"
            />
          ) : null}
          <StatueOfLiberty
            position={{ left: "15%", bottom: "1%" }}
            className="position-absolute"
            height={SVGHeight}
            fill="#2e2e2e"
          />
        </>
      ) : null;
      break;
    case "burj khalifa":
      statueOfLibertySVGHeight = SVGHeight * (statueOfLiberty / burjKhalifa);
      statueOfLibertyLabelWidth =
        statueOfLibertySVGHeight * statueOfLibertyAspect;
      component = SVGHeight ? (
        <>
          <div
            className="text-center position-absolute h4"
            style={{
              width: statueOfLibertyLabelWidth,
              bottom: statueOfLibertySVGHeight + 10,
              left: "5%",
            }}
          >
            <b>Statue of Liberty</b> (83m)
          </div>
          <div
            className="text-center position-absolute h4"
            style={{
              width: burjKhalifaLabelWidth,
              bottom: SVGHeight + 10,
              left: "15%",
            }}
          >
            <b>Burj Khalifa</b> (830m)
          </div>
          {(statueOfLiberty / burjKhalifa) * SVGHeight > 1 ? (
            <StatueOfLiberty
              position={{ left: "5%", bottom: "1%" }}
              className="position-absolute"
              height={SVGHeight * (statueOfLiberty / burjKhalifa)}
              fill="#2e2e2e"
            />
          ) : null}
          <BurjKhalifa
            position={{ left: "15%", bottom: "1%" }}
            className="position-absolute"
            height={SVGHeight}
            fill="#2e2e2e"
          />
        </>
      ) : null;
      break;
    default:
      component = null;
      break;
  }

  return (
    <>
      <div className="w-50">{component}</div>
      <div
        className="w-50 h-100 "
        ref={mount}
        onClick={() => setAnimating(!isAnimating)}
      />
    </>
  );
};

export default CO2Stack;
