import { ContentContainer, Content } from "../../FactBox.styles";
import DinoImg from "../../images/SS_CO2_TRex.png";
import { CO2SavedProps } from "../../FactBox.types";
import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { convert } from "../../../../../utilities/HighestUnitConverter";
import { getTimeString } from "../../FactBox.utilities";

export const DinoSlide: FunctionComponent<CO2SavedProps> = ({
  startTime,
  co2Saved,
  timeSpan
}) => {
  const formula = (total: number, dividedBy: number) =>
    +(total / dividedBy).toFixed(2);

  return startTime && co2Saved ? (
    <ContentContainer>
      <img src={DinoImg} alt="CO2 TRex Comparison" />
      <Content>
        {getTimeString(timeSpan, startTime)}, you have avoided
        <Box fontWeight={900} component="span">
          {" "}
          {convert({ type: "mass", value: co2Saved })}
        </Box>{" "}
        of{" "}
        <Box fontWeight={900} component="span">
          CO<sub>2</sub>
        </Box>{" "}
        from being released into the atmosphere. <br />
        That's equivalent in mass to
        <Box fontWeight={900} component="span">
          {" "}
          {formula(co2Saved / 1000, 8160)}
        </Box>{" "}
        Tyrannosaurus rexes!
      </Content>
    </ContentContainer>
  ) : null;
};
