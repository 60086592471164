import styled from "../../../providers/ThemeProvider";

export const WidgetWrapper = styled.section({
  borderRadius: "8px",
  boxShadow: "10px 10px 15px 0px rgba(0,0,0,0.2)",
  overflow: "hidden",
  height: "100%",
});

export const WidgetHeader = styled.header({
  background: "#64748B",
  color: "white",
  font: "bold",
  padding: "8px 16px 8px 16px",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
});

export const WidgetHeading = styled.h3({
  margin: 0,
  fontWeight: "bold",
  fontSize: "1rem",
});

export const WidgetContent = styled.div({
  background: "white",
  width: "100%",
  height: "100%",
  padding: "1rem",
  paddingBottom: "3rem",
  display: "grid",
  gridTemplateRows: "1fr auto",
});

export const WidgetImage = styled.img({
  width: "100%",
  height: "auto",
});

export const Underlay = styled.div({
  position: "absolute",
  zIndex: 50,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(0,0,0,0.2)",
});

export const Overlay = styled.div({
  background: "white",
  padding: "1rem",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "auto",
});
