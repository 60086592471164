import styled from "../../../providers/ThemeProvider";
import { GraphWrapperParams } from "./Pie.types";

export const PieContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "flex"
});

export const GraphWrapper = styled.div<GraphWrapperParams>(
  ({ validTypes }) => ({
    flex: 1,
    opacity: validTypes ? 1 : 0.5
  })
);

export const BarComparisonWrapper = styled.div({
  alignSelf: "flex-end",
  height: "80%",
  width: "15%"
});

export const PieStoriesContainer = styled.div({
  width: "800px",
  height: "600px",
  backgroundColor: "white"
});

export const chartStyles = {
  width: "100%",
  height: "100%"
};

export const TempContainer = styled.div(({ theme }) => ({
  padding: theme.spacing(4, 4, 4, 4)
}));
