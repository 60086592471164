import styled from "../../../providers/ThemeProvider";

export const SlideWrapperContainer = styled.div(({ theme }) => ({
  position: "relative",
  height: "100%",
  width: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  padding: theme.spacing(4, 5.5, 4, 5.5),
}));

export const Slide = styled.div<{ fullWidth?: number }>(({ fullWidth }) => ({
  height: "100%",
  width: "100%",
  ...(fullWidth && {
    position: "absolute",
    left: 0,
    top: 0,
  }),
}));

export const AltLogosContainer = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  gap: "3rem",
});

export const AltLogoImg = styled.img({
  height: "4rem",
  width: "auto",
  margin: "2rem 0 0 0",
});
