import React, { useEffect, useRef } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { parse } from "date-fns";

type Props = {
  data: Array<{
    time: string;
    gridConsumed: number;
  }>;
  lastWeekData: Array<{
    time: string;
    gridConsumed: number;
  }>;
};

const formatData = (data: Props["data"], lastWeekData: Props["lastWeekData"]) =>
  lastWeekData && data
    ? lastWeekData.map(
        ({ time, gridConsumed: lastWeekGridConsumed }, index) => ({
          time: parse(time, "yyyy-MM-dd HH:mm:ss", new Date()),
          gridConsumed: data?.[index]?.gridConsumed || null,
          lastWeekGridConsumed,
        })
      )
    : data &&
      data.map(({ time, gridConsumed }) => ({
        time: parse(time, "yyyy-MM-dd HH:mm:ss", new Date()),
        gridConsumed,
      }));

export const SimpleLineGraph = ({ data, lastWeekData }: Props) => {
  const chart = useRef<am4charts.XYChart | null>(null);

  useEffect(() => {
    chart.current = am4core.create("sidebar-line", am4charts.XYChart);
    return () => chart?.current?.dispose();
  }, []);

  useEffect(() => {
    if (chart.current) {
      const formattedData = formatData(data, lastWeekData);
      chart.current.data = formattedData;

      // clear if exists
      if (chart.current.xAxes.length) {
        chart.current.xAxes.clear();
      }
      if (chart.current.yAxes.length) {
        chart.current.yAxes.clear();
      }
      if (chart.current.series.length) {
        chart.current.series.clear();
      }

      // basics
      const dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
      dateAxis.groupData = true;
      dateAxis.groupCount = 100;
      const valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
      const series = chart.current.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "gridConsumed";
      series.stroke = am4core.color("#FFF");
      series.name = "Today's Consumption";
      series.dataFields.dateX = "time";
      series.smoothing = "monotoneX";
      series.fill = series.stroke;
      series.strokeWidth = 1;
      series.zIndex = 1;

      // this day last week
      if (lastWeekData.length) {
        const lastWeekSeries = chart.current.series.push(
          new am4charts.LineSeries()
        );
        lastWeekSeries.dataFields.valueY = "lastWeekGridConsumed";
        lastWeekSeries.stroke = am4core.color("#bababa");
        lastWeekSeries.name = "This Day Last Week's Consumption";
        lastWeekSeries.dataFields.dateX = "time";
        lastWeekSeries.smoothing = "monotoneX";
        lastWeekSeries.fill = lastWeekSeries.stroke;
        lastWeekSeries.strokeWidth = 1;
        lastWeekSeries.zIndex = 0;
      }

      // disable most peripherals
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.renderer.baseGrid.disabled = false;
      dateAxis.renderer.baseGrid.stroke = am4core.color("#FFF");
      dateAxis.renderer.baseGrid.strokeOpacity = 1;
      dateAxis.renderer.disabled = true;
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.renderer.baseGrid.stroke = am4core.color("#FFF");
      valueAxis.renderer.baseGrid.strokeOpacity = 1;
      //   valueAxis.renderer.baseGrid.disabled = true;
      valueAxis.renderer.disabled = true;
      chart.current.zoomOutButton.disabled = true;

      // legend
      if (!chart.current.legend) {
        chart.current.legend = new am4charts.Legend();
        chart.current.legend.labels.template.text = "[{color}]{name}";
        chart.current.legend.labels.template.truncate = false;
        chart.current.legend.labels.template.fontSize = 12;
      }
    }
  }, [data]);

  return (
    <div id="sidebar-line" style={{ height: "300px", marginTop: "10rem" }} />
  );
};
