import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import "./index.css";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import { DisplayBoardProvider } from "./Context";

ReactDOM.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Display Board</title>
    </Helmet>
    <DisplayBoardProvider>
      <App />
    </DisplayBoardProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
