import styled from "../../../providers/ThemeProvider";

export const SubMeterAnalysisContainer = styled.div({
  display: "grid",
  gridTemplateRows: "1fr auto",
  gridTemplateColumns: "repeat(8, minmax(0, 1fr))",
  gap: "1rem",
  height: "100%",
});

export const StackedXYChartContainer = styled.div({
  gridColumn: "span 5 / span 5",
});

export const PieChartContainer = styled.div({
  gridColumn: "span 3 / span 3",
});

export const LegendContainer = styled.div({
  background: "yellow",
  gridColumn: "span 8 / span 8",
  padding: "2rem 0",
});
