import { settings } from "./EnergyFlow.consts";
import {
  EnergyFlowPositions,
  ElementOrientation,
  ElementConfig,
  Position,
  LinkSlot,
  Path,
  SubElementUtilOutput,
} from "./EnergyFlow.types";
import { format, roundToNearestMinutes, subMinutes } from "date-fns";
import { DataType } from "../../../types/dataKeys";
import { SiteMeter, VirtualMeter } from "../../../types/Meters";

/**
 * Determines the positions of the elements given the screen width and height
 * @param width
 * @param height
 */
export const getElementPositions = (
  width: number,
  height: number
): EnergyFlowPositions => {
  const ewOffset = settings.elementWidth / 2; // element width offset
  const labelOffset = settings.elementLabelFontSize; // for label room and consistent spacing
  const topPadding = settings.topPadding;
  const labelPadding = 10;
  const offsetMiddleY = height / 2; // middle Y position with offset correction
  const offsetMiddleX = width / 2; // middle X position with offset correction

  return [
    {
      x: ewOffset + labelOffset + labelPadding,
      y: offsetMiddleY,
      labelX: ewOffset + labelOffset + labelPadding,
      labelY: offsetMiddleY - ewOffset - labelPadding,
    },
    {
      x: offsetMiddleX,
      y: ewOffset + labelOffset + labelPadding + topPadding,
      labelX: offsetMiddleX,
      labelY: labelOffset + topPadding,
    },
    {
      x: width - ewOffset - labelOffset - labelPadding,
      y: offsetMiddleY,
      labelX: width - ewOffset - labelOffset - labelPadding,
      labelY: offsetMiddleY - ewOffset - labelPadding,
    },
    {
      x: offsetMiddleX,
      y: height - ewOffset - labelOffset - labelPadding,
      labelX: offsetMiddleX,
      labelY: height + labelPadding - labelOffset,
    },
  ].slice(0, settings.elementConfigs.length);
};

/**
 * Calculates the SVG path between elements
 * @param startPosition
 * @param endPosition
 * @param isCurved
 */
const calculatePath = (
  startPosition: Position,
  endPosition: Position,
  isCurved: boolean,
  fromOrientation: ElementOrientation,
  toOrientation: ElementOrientation,
  fromOffset: number,
  toOffset: number
) => {
  const isHori = fromOrientation === "horizontal";
  const isVert = fromOrientation === "vertical";
  let sx = startPosition.x;
  let sy = startPosition.y;
  let ex = endPosition.x;
  let ey = endPosition.y;
  sy += isHori ? fromOffset : 0;
  sx += isVert ? fromOffset : 0;
  ey += isVert ? toOffset : 0;
  ex += isHori ? toOffset : 0;

  const horizontalDiff = ex - sx;
  const verticalDiff = ey - sy;
  const lineRatio = 0.7; // line ratio to curve
  const hll = lineRatio * horizontalDiff; // horizontal line length
  const vll = lineRatio * verticalDiff; // vertical line length

  // start line
  const lx1 = isHori ? sx + hll : sx;
  const ly1 = isVert ? sy + vll : sy;

  // end curve
  const cex = isHori ? ex : ex - hll;
  const cey = isVert ? ey : ey - vll;

  if (isCurved) {
    /*  M startX startY C controlX1 controlY1 controlX2 controlY2 endX endY
        AKA (for this layout):
        M x1 y1 C x2 y1 x2 y1 x2 y2
    */
    return `M ${sx} ${sy} L ${lx1} ${ly1} C ${ex} ${sy} ${ex} ${sy} ${cex} ${cey} M ${cex} ${cey} L ${ex} ${ey}`;
  } else {
    return `M ${sx} ${sy} L ${ex} ${ey}`;
  }
};

/**
 * Returns a flattened array of all paths for a given config and set of positions
 * @param elementConfigs
 * @param elementPositions
 */
export const getLinkPaths = (
  elementConfigs: Array<ElementConfig>,
  elementPositions: Array<Position>,
  data: any
): Array<Path> => {
  return elementConfigs
    .map((elementConfig, index) => {
      const elementPosition = elementPositions[index];
      const paths = elementConfig.linksTo.map(
        ({ index: linkIndex }, toLinkIndex) => {
          if (elementConfigs?.[linkIndex]) {
            const linkPosition = elementPositions[linkIndex];
            const linkConfig = elementConfig.linksTo[toLinkIndex];
            const isCurved = !!linkConfig.curved;
            const reversed = !!linkConfig.reversed;
            const fromOrientation = elementConfig.orientation;
            const toOrientation = elementConfigs[linkIndex].orientation;
            const fromOffset = getLinkOffset(linkConfig.fromSlot);
            const toOffset = getLinkOffset(linkConfig.toSlot);

            return {
              reversed,
              value: data?.[linkConfig.dataKey],
              dataKey: linkConfig.dataKey,
              fromIndex: reversed ? linkIndex : index,
              toIndex: reversed ? index : linkIndex,
              path: calculatePath(
                elementPosition,
                linkPosition,
                isCurved,
                fromOrientation,
                toOrientation,
                fromOffset,
                toOffset
              ),
            };
          } else {
            // error state
            return {
              reversed: false,
              dataKey: "blank",
              value: 0,
              fromIndex: -1,
              toIndex: -1,
              path: "",
            };
          }
        }
      );

      return paths;
    })
    .flat();
};

/**
 * Returns an array of JSX paths for all links between elements
 * @param width
 * @param height */

/**
 * Returns the animateMotion styles for a flow dot
 * @param reversed
 */
export const getAnimateAtrributes = (duration: number, reversed: boolean) => ({
  dur: `${duration}s`,
  repeatCount: "indefinite",
  ...(reversed && {
    calcMode: "linear",
    keyPoints: "1;0",
    keyTimes: "0;1",
  }),
});

/**
 * Returns the required offset for a link when given the link slot
 * @param linkIndex
 */
export const getLinkOffset = (linkSlot: LinkSlot) => {
  const osl = settings.linkOffsetLength;
  return [-osl, 0, osl][linkSlot];
};

/**
 * Returns a human readable difference in speed for the flow dots
 * Used mainly because outliers with something like 1/1000 of the highest value are too slow
 * @param relativeDifference
 */
export const getDurationFromRelativeDifference = (
  relativeDifference: number
) => {
  if (isNaN(relativeDifference)) {
    return 0;
  }

  const fdd = settings.fastestDotDuration;

  if (relativeDifference < 20) {
    return relativeDifference * fdd;
  } else if (relativeDifference < 100) {
    return 30 * fdd;
  } else if (relativeDifference < 500) {
    return 40 * fdd;
  } else {
    return 50 * fdd;
  }
};

export const getSubElementPathsAndPositions = (
  startPosition: Position,
  count: number
): SubElementUtilOutput => {
  let pathOutput: Array<string> = [];
  let positionOutput: Array<Position> = [];
  const branchPadding = settings.subElementBranchPadding; // padding between branches
  const bl = settings.subElementBranchLength; // branch length
  const mx = startPosition.x; // middle X
  const sy = startPosition.y + settings.elementWidth / 4; // branch y start
  const lx = mx - branchPadding; // leftX
  const rx = mx + branchPadding; // rightX
  const el = settings.subElementWidth / 3.3; // element width
  let iter = 0;

  const drawLeftPath = (by: number) => {
    return `M ${mx} ${sy} L ${mx} ${by} M ${mx} ${by} C ${mx} ${by +
      bl / 2} ${lx} ${by + bl / 2} ${lx} ${by + bl}`;
  };

  const drawRightPath = (by: number) => {
    return `M ${mx} ${sy} L ${mx} ${by} M ${mx} ${by} C ${mx} ${by +
      bl / 2} ${rx} ${by + bl / 2} ${rx} ${by + bl}`;
  };

  const drawMiddlePath = (by: number) => {
    return `M ${mx} ${sy} L ${mx} ${by + bl}`;
  };

  const drawSplitVariant = () => {
    Array(count)
      .fill(0)
      .forEach((_, i) => {
        const by = iter * bl + sy; // branch start Y
        const py = by + bl + el; // element y
        // left -> right (if not the end of the count)
        // left
        if ((i + 1) % 2 === 1) {
          pathOutput.push(drawLeftPath(by));
          positionOutput.push({ x: lx, y: py });
        }
        // right
        else if ((i + 1) % 2 === 0) {
          pathOutput.push(drawRightPath(by));
          positionOutput.push({ x: rx, y: py });
          // draw a middle continuation if not at the end of the count
          if (!(i + 1 === count)) {
            // pathOutput.push(drawMiddlePath(by));
            iter++;
          }
        }
      });
    return {
      paths: pathOutput,
      positions: positionOutput,
    };
  };

  const drawMiddleVariant = () => {
    Array(count)
      .fill(0)
      .forEach((_, i) => {
        const by = iter * bl + sy; // branch start Y
        const py = by + bl + el; // element y

        // middle first if last, otherwise left -> right -> middle
        if ((i + 1) % 2 === 1) {
          if (i + 1 === count) {
            pathOutput.push(drawMiddlePath(by));
            positionOutput.push({ x: mx, y: py });
          } else {
            pathOutput.push(drawLeftPath(by));
            positionOutput.push({ x: lx, y: py });
          }
        }
        // draw right then draw a middle and increment if the next one isnt last
        else if ((i + 1) % 2 === 0) {
          pathOutput.push(drawRightPath(by));
          positionOutput.push({ x: rx, y: py });
          if (!(i + 2 === count)) {
            // pathOutput.push(drawMiddlePath(by));
            iter++;
          }
        }
      });

    return {
      paths: pathOutput,
      positions: positionOutput,
    };
  };

  // exists
  if (count > 0) {
    // middle variant
    if (count % 2 === 1) {
      return drawMiddleVariant();
      // split variant
    } else {
      return drawSplitVariant();
    }
  } else {
    return null;
  }
};

export const getCurrentData = (
  showCurrent: boolean,
  dataKey: DataType,
  data?: any
) =>
  showCurrent
    ? data?.data?.[
        data?.data.findIndex((val: any) => val?.[dataKey] === null) - 1
      ] ||
      data?.data?.[
        data?.data?.findIndex(
          (val: any) =>
            val.time ===
            format(
              roundToNearestMinutes(subMinutes(new Date(), 15), {
                nearestTo: 5,
              }),
              "yyyy-MM-dd HH:mm:ss"
            )
        ) - 1
      ] ||
      data?.data?.[0]
    : data?.totals;

export const isPhysicalMeterAndNotVirtual = (
  props: SiteMeter | VirtualMeter
): props is SiteMeter => {
  return !(props as VirtualMeter).parents;
};
