import { DataType } from "../../../types/dataKeys";
import { PieChartOption } from "./ChildPie/ChildPie.types";

export const getOptions = (dataKey: DataType): PieChartOption => {
  if (dataKey === "solarGenerated") {
    return {
      name: "Solar Generation",
      primaryKey: ["solarGenerated"],
      secondaryKey: ["solarGeneratedValue"],
      hasTimeKey: true,
      chart: "pie",
    };
  } else {
    return {
      name: "Grid Consumption",
      primaryKey: ["gridConsumed"],
      secondaryKey: ["gridConsumedValue"],
      hasTimeKey: true,
      chart: "pie",
    };
  }
};
