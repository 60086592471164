import { TimeSpan } from "../../../types/dataKeys";
import { parseISO } from "date-fns";
import { getYear } from "date-fns";
export const getTimeString = (timeSpan: TimeSpan, startTime: string) => {
  const startingYear = getYear(parseISO(startTime));
  let timeString = `Since ${startingYear}`; // default lifetime
  switch (timeSpan) {
    case "daily":
      timeString = "Today";
      break;
    case "weekly":
      timeString = "This week";
      break;
    case "monthly":
      timeString = "This month";
      break;
  }
  return timeString;
};
