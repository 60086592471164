import { DisplayBoardConfig } from "../..";
import {
  DISCOVERY_CAMPS_SITE_IDS,
  DISCOVERY_CAMPS_ORG_IDS,
  discoveryCampsSiteConfigs,
  discoveryCampsOrgConfigs,
} from "./DiscoveryCamps.const";

export function mapDiscoveryCampsConfigsToConfig(config: DisplayBoardConfig) {
  if (config?.siteId && DISCOVERY_CAMPS_SITE_IDS.includes(config.siteId)) {
    return {
      ...config,
      backgroundImage: "ydc-environmental",
      logo: "",
      installerLogo: "discoveryCamps",
      slides: [...discoveryCampsSiteConfigs, ...config.slides],
    };
  }

  if (
    config?.organisationId &&
    DISCOVERY_CAMPS_ORG_IDS.includes(config.organisationId)
  ) {
    return {
      ...config,
      backgroundImage: "ydc-environmental",
      logo: "",
      installerLogo: "discoveryCamps",
      slides: [...discoveryCampsOrgConfigs, ...config.slides],
    };
  }

  return config;
}
