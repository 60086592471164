import { useSlideContext } from "../../Content/SlideWrapper/SlideWrapper.hooks";
import { useTimeSpanData } from "../../../hooks/Context Hooks/data/useData";
import SubMeterBreakdownChart from "./SubMeterBreakdownChart";
import { Box } from "@material-ui/core";
import React from "react";

export const SubMeterBreakdown = () => {
  const { timeSpan } = useSlideContext();
  const data = useTimeSpanData(timeSpan, "SubMeterEnergy");

  return (
    <Box width="100%" height="100%">
      <SubMeterBreakdownChart
        isLine={timeSpan === "daily"}
        graphId="submeter-graph"
        height="100%"
        data={data}
        hideScroll
      />
    </Box>
  );
};
