import React, { FunctionComponent, useContext } from "react";
import PanelInfoBox from "./PanelInfoBox/PanelInfoBox";
import {
  PVInfoContainer,
  PVInfoContent,
  PVImage,
  InfoColumn,
  InfoRow,
} from "./PVInfo.styles";
import ErrorScreen from "../../_shared/ErrorScreen";
import { getUnitAndValue } from "../../../utilities/DataUtil/getUnitAndValue";
import { DisplayBoardContext } from "../../../Context";
import { Box } from "@material-ui/core";

export const PVInfo: FunctionComponent<{}> = () => {
  const {
    data: { pvInfo },
  } = useContext(DisplayBoardContext);

  if (!pvInfo) {
    // TODO SlideErrorScreen
    return <ErrorScreen />;
  }
  const d = pvInfo[0];

  const [peakCapacity, peakCapacityUnit] = getUnitAndValue(
    parseInt(d?.peakCapacity || 0),
    "energy",
    true
  );
  const [panelCapacity, panelCapacityUnit] = getUnitAndValue(
    parseInt(d?.panelCapacity || 0),
    "energy",
    true
  );

  const info = [
    {
      name: "RATED SYSTEM SIZE",
      data: `${peakCapacity} ${peakCapacityUnit}`,
    },
    { name: "PANEL'S MODEL", data: d?.panelType },
    { name: "INVERTER", data: d?.inverter },
    {
      name: "PANEL CAPACITY",
      data: `${panelCapacity} ${panelCapacityUnit}`,
    },
    { name: "NUMBER OF PANELS", data: d?.panelQuantity },
    {
      name: "INSTALLATION DATE",
      data: d?.installationDate,
    },
  ];

  return (
    <Box display="flex" height="90%" width="100%" alignItems="center">
      <PVInfoContainer>
        <PVInfoContent>
          <PVImage src="flowgraph-images/Solar.png" alt="Solar Panel" />
          <InfoColumn>
            <InfoRow>
              <PanelInfoBox title={info[0].name} info={info[0]?.data} />
              <PanelInfoBox title={info[1].name} info={info[1]?.data} />
            </InfoRow>
            <InfoRow>
              <PanelInfoBox title={info[2].name} info={info[2]?.data} />
              <PanelInfoBox title={info[3].name} info={info[3]?.data} />
            </InfoRow>
            <InfoRow>
              <PanelInfoBox title={info[4].name} info={info[4]?.data} />
              <PanelInfoBox title={info[5].name} info={info[5]?.data} />
            </InfoRow>
          </InfoColumn>
        </PVInfoContent>
      </PVInfoContainer>
    </Box>
  );
};

export default PVInfo;
