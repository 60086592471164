export const getPieColors = () => [
  "#6E0E0A",
  "#D74E09",
  "#F2BB05",
  "#72B01D",
  "#3F7D20",
  "#119DA4",
  "#00B4DD",
  "#124E78",
  "#454955",
  "#262528",
];
