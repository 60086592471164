import styled from "../../../providers/ThemeProvider";

export const LoadingScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LoadingScreenStoriesContainer = styled.div({
  height: "450px",
  width: "800px"
});

export const LoadingScreenImage = styled.img({
  width: "200px",
  height: "200px"
});
