import styled from "../../../providers/ThemeProvider";

export const DateTimeBoxContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

export const TextContainer = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.palette?.sideBarText?.main || theme.palette.common.white,
}));
