import React, { useEffect, useState } from "react";
import { format, isAfter, parseISO } from "date-fns";
import weatherIcons from "./Icons";
import {
  WeatherBoxContainer,
  TemperatureContainer,
  WeatherIconContainer,
  WeatherIcon,
  Temperature,
} from "./WeatherBox.styles";

const WeatherBox = ({ data }) => {
  const [weather, setWeather] = useState(false);
  const [time, setTime] = useState(false);

  // console.log(data);

  const getLast5Min = () => {
    const today = new Date(
      Math.round(new Date() / (1000 * 60 * 5)) * (1000 * 60 * 5)
    );
    return format(today, "yyyy-MM-dd HH:mm:ss");
  };

  const getCurrentWeather = (data, time) => {
    return data.dailyEnergy.data.find((w) => w.time === time) || false;
  };

  const renderWeather = () => {
    const icon = weather.weatherIcon.replace(/-/g, "_");
    return weatherIcons[icon] || weatherIcons.cloudy;
  };

  // TODO fix datetime parsing issue
  useEffect(() => {
    const OneMinute = 60000;
    const refreshTimeInterval = setInterval(() => {
      const currentTime = getLast5Min();
      if (isAfter(parseISO(currentTime), time)) {
        setTime(parseISO(currentTime));
      }
    }, OneMinute);

    return () => clearInterval(refreshTimeInterval);
  });

  useEffect(() => {
    const currentTime = time || getLast5Min();
    const currentWeather = getCurrentWeather(data, currentTime);
    setWeather(currentWeather);
  }, [time]);

  if (weather) {
    return (
      <WeatherBoxContainer>
        <TemperatureContainer>
          <Temperature>{Math.round(weather.temperature)}&#176;</Temperature>
        </TemperatureContainer>
        {weather.weatherIcon && (
          <WeatherIconContainer>
            <WeatherIcon src={renderWeather()} alt={weather.weatherSummary} />
          </WeatherIconContainer>
        )}
      </WeatherBoxContainer>
    );
  } else {
    return <p>No Data</p>;
  }
};

export default WeatherBox;
