import React, { useContext } from "react";
import { DisplayBoardContext } from "../../../Context";
import { LeaderboardSite } from "./LeaderboardSite/LeaderboardSite";
import { LeaderboardWrapper } from "./LeaderboardWrapper/LeaderboardWrapper";
import { SiteLeaderboardsContainer } from "./SiteLeaderboards.styled";
import { SiteRank } from "./SiteLeaderboards.types";

export const SiteLeaderboards = () => {
  const {
    data: { siteLeaderboards },
  } = useContext(DisplayBoardContext);

  const consumptionLeaderboard = siteLeaderboards?.consumption as Array<
    SiteRank
  >;
  const consumptionPerPersonLeaderboard = siteLeaderboards?.consumptionByPopulation as Array<
    SiteRank
  >;

  return (
    <SiteLeaderboardsContainer>
      <LeaderboardWrapper title="Lowest Consumption">
        {consumptionLeaderboard.map((site, index) => (
          <LeaderboardSite site={site} index={index} />
        ))}
      </LeaderboardWrapper>
      <LeaderboardWrapper title="Lowest Consumption Per Student">
        {consumptionPerPersonLeaderboard.map((site, index) => (
          <LeaderboardSite site={site} index={index} />
        ))}
      </LeaderboardWrapper>
    </SiteLeaderboardsContainer>
  );
};
