import { CurrentDateTime } from "./DateTimeBox.types";
import { format } from "date-fns";

export const getCurrentDateTime = () => {
  const today = new Date();
  return {
    time: format(today, "h:mm a"),
    day: format(today, "iiii"),
    date: format(today, "d MMM yyyy"),
  } as CurrentDateTime;
};
