import { DataType, DataTypes } from "../../types/dataKeys";

export const buildID = (
  component: string,
  dataKeys?: string | null,
  timeSpan?: string
) =>
  `${component}-${dataKeys ? dataKeys : ""}${
    dataKeys ? `-${timeSpan}` : timeSpan
  }`;

export const getDataTypeFromID = (slideId: string) => {
  const data = slideId.split("-");
  if (data.length < 3) return "";
  else {
    return data[1].includes(",") ? data[1].split(",") : data[1];
  }
};

export const getComponentFromID = (slideId: string) => {
  const data = slideId.split("-");
  return data[0];
};

export const getTimeSpanFromID = (slideId: string) => {
  const data = slideId.split("-");

  if (data.length < 3) return data[1];
  else return data[2];
};

export const getDataTypeFromShorthand = (dataKey: any) => {
  if (!Array.isArray(dataKey)) {
    dataKey = [dataKey];
  }
  return dataKey.map((el: string) => {
    switch (el) {
      case "sgen":
        return "solarGenerated";
      case "gcon":
        return "gridConsumed";
      case "csav":
        return "co2Saved";
      case "cemi":
        return "co2Produced";
      case "scon":
        return "solarConsumed";
      case "ncon":
        return "netConsumed";
      case "sexp":
        return "solarFeedIn";
      case "pcap":
        return "peakCapacity";
      default:
        return "blank";
    }
  });
};

/**
 * Supports amCharts stacked algorithm which requires stacked keys to be on the bottom, plus looks better
 * @param dataKeys
 */
export const sortDataKeys = (dataKeys: DataType) => {
  const dataKeyWeights: { [key: string]: number } = {
    co2Saved: 0,
    co2Produced: 1,
    solarGenerated: 2,
    netConsumed: 3,
    solarFeedIn: 4,
    gridConsumed: 5,
    solarConsumed: 6,
  };

  if (Array.isArray(dataKeys)) {
    return dataKeys.sort((a, b) =>
      a && b && (dataKeyWeights?.[a] || 3) >= (dataKeyWeights?.[b] || 3)
        ? -1
        : 1
    );
  } else {
    return dataKeys;
  }
};

export const getDataTypeNameFromShorthand = (dataKey: string[] | string) => {
  if (!Array.isArray(dataKey)) {
    dataKey = [dataKey];
  }
  return dataKey.map((el) => {
    switch (el) {
      case "sgen":
        return "Solar Generated";
      case "gcon":
        return "Grid Consumed";
      case "csav":
        return "CO₂ Saved";
      case "cemi":
        return "CO₂ Emitted";
      case "scon":
        return "Solar Consumed";
      case "ncon":
        return "Net Consumed";
      case "sexp":
        return "Solar Exported";
      case "pcap":
        return "Solar Capacity";
    }
  });
};

export const getDataTypeName = (dataKey: string[] | string) => {
  if (!Array.isArray(dataKey)) {
    dataKey = [dataKey];
  }
  return dataKey.map((el) => {
    switch (el) {
      case "solarGenerated":
        return "Solar Generated";
      case "gridConsumed":
        return "Grid Consumed";
      case "co2Saved":
        return "CO₂ Saved";
      case "co2Produced":
        return "CO₂ Emitted";
      case "solarConsumed":
        return "Solar Consumed";
      case "netConsumed":
        return "Net Consumed";
      case "solarFeedIn":
        return "Solar Exported";
      default:
        return "N/A";
    }
  });
};

export const getTimeSpanFromShorthand = (time: string) =>
  time.charAt(0).toUpperCase() + time.substr(1).toLowerCase();

export const getSlideNameFromKey = (key: string) => {
  switch (key) {
    case "heatmap":
      return "Heatmap";
    case "line":
      return "Line Graph";
    case "bar":
      return "Bar Graph";
    case "piecomp":
      return "Pie Chart";
    case "balls":
      return "CO₂ Sphere Comparison";
    case "statimg":
      return "Statistic Images";
    case "flowgraph":
      return "Energy Flowgraph";
    case "pvinfo":
      return "PV System Information";
  }
};
