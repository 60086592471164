import React from "react";
import { autoFormatMassValue } from "@solarschools/component-lib/src/utilities/MassUnits";
import { useTheme } from "../../../hooks/Context Hooks/theming/useTheme";

const CO2StatBox = ({ co2, dataKey }) => {
  const theme = useTheme();
  const color = theme?.palette.dataKeys[dataKey] || "#5E5D63";
  const border = `2px solid ${color}`;

  /* 
      (assuming CO2 at 15 degrees and standard pressure)
      density: 1.87kg/m3
    */
  const co2Volume = co2 / 1000 / 1.87;
  const AvgSoccerBallVolume = 0.005575;
  const soccerBallCount = co2Volume / AvgSoccerBallVolume;
  const AvgOlympicPoolVolume = 2500;
  const olympicPoolCount = co2Volume / AvgOlympicPoolVolume;

  let referenceString = "soccer balls";
  let referenceCount = soccerBallCount;
  if (olympicPoolCount > 1) {
    referenceCount = olympicPoolCount;
    referenceString = "olympic swimming pools";
  }

  return (
    <div className="d-flex align-items-end justify-content-center w-100 h-100">
      <div
        className="p-3 w-100 d-flex h-auto align-items-center justify-content-center h3 rounded-massive"
        style={{ border: border, color: color }}
      >
        <div>
          <span className="font-weight-bold">
            {autoFormatMassValue(co2 ?? 0)}s
          </span>{" "}
          of CO<sub>2</sub>E has been{" "}
          {dataKey === "co2Produced" ? "emitted" : "avoided"}, that's{" "}
          <span className="font-weight-bold">
            enough to fill{" "}
            {Math.round(referenceCount)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {" " + referenceString + "."}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CO2StatBox;
