import { TimeSpan } from "../../../types/dataKeys";
import { energyConverter } from "../../../utilities/unit-converter";
import { PieChartProps } from "./PieChart";
import { StackedXYChartProps } from "./StackedXYChart";
import {
  SubMeterBreakdown,
  SubMeterSourceCategory,
} from "./SubMetersAnalysis.types";

export function mapSubMeterTotalsToPieChartSeries(
  data: SubMeterBreakdown,
  category: SubMeterSourceCategory,
  timeSpan: TimeSpan
): PieChartProps {
  const seriesData = Object.entries(data.totals.nodes).map(
    ([nodeId, totals]) => {
      return {
        category: data.nodes[nodeId].name,
        value: totals[category],
      };
    }
  );
  return {
    categoryKey: "Sub Meter",
    id: `submetersanalysis-${timeSpan}-${category}`,
    seriesData,
    valueKey: category,
  };
}

export function mapSubMeterDataToStackedXYChart(
  data: SubMeterBreakdown,
  category: SubMeterSourceCategory,
  timeSpan: TimeSpan
): StackedXYChartProps {
  const seriesData = data.data.map((datum) => {
    const valueEntries = Object.fromEntries(
      Object.entries(datum.breakdown).map(([nodeId, totals]) => {
        const convertedVal = energyConverter.convert(
          {
            unit: "wh",
            value: totals[category],
          },
          "kWh"
        );

        return [data.nodes[nodeId].name, convertedVal.value];
        //return [nodeId, convertedVal.value];
      })
    );

    return {
      time: new Date(datum.time.replace(" ", "T")),
      valueEntries,
    };
  });

  return {
    categoryKey: "time",
    id: `submetersanalysis-${timeSpan}-${category}`,
    seriesData,
  };
}
