export const unitTypes = [
  "energy",
  "energy peak",
  "energy joules",
  "energy per person",
  "voltage",
  "power",
  "current",
  "blank",
  "energy reactive",
  "energy apparent",
  "power reactive",
  "power apparent",
  "power convert",
  "mass",
  "water",
  "money",
  "percentage",
  "panels",
] as const;

export type UnitType = typeof unitTypes[number];

export type UnitConstant =
  | typeof energyUnits
  | typeof energyPeakUnits
  | typeof powerUnits
  | typeof energyJouleUnits
  | typeof voltageUnits
  | typeof currentUnits
  | typeof massUnits
  | typeof waterUnits
  | typeof powerReactiveUnits
  | typeof energyReactiveUnits
  | typeof energyApparentUnits
  | typeof powerApparentUnits
  | typeof energyPerPersonUnits;

export type Unit =
  | typeof energyUnits[number]
  | typeof energyPeakUnits[number]
  | typeof powerUnits[number]
  | typeof energyJouleUnits[number]
  | typeof voltageUnits[number]
  | typeof currentUnits[number]
  | typeof massUnits[number]
  | typeof waterUnits[number]
  | typeof powerReactiveUnits[number]
  | typeof energyReactiveUnits[number]
  | typeof energyApparentUnits[number]
  | typeof powerApparentUnits[number]
  | typeof energyPerPersonUnits[number]
  | "$"
  | "%"
  | "panels"
  | "N/A";

export const energyUnits = ["Wh", "kWh", "MWh", "GWh", "TWh"] as const;

export const energyPeakUnits = ["Wp", "kWp", "MWp", "GWp", "TWp"] as const;

export const powerUnits = ["W", "kW", "MW", "GW", "TW"] as const;

export const energyJouleUnits = ["J", "kJ", "MJ", "GJ", "TJ"] as const;

export const voltageUnits = ["V", "kV", "MV", "GV", "TV"] as const;

export const currentUnits = ["A", "kA", "MA", "GA", "TA"] as const;

export const massUnits = ["g", "kg", "t", "kt", "Mt"] as const;

export const waterUnits = ["mL", "L", "kL", "ML", "GL"] as const;

export const powerReactiveUnits = [
  "VAR",
  "kVAR",
  "MVAR",
  "GVAR",
  "TVAR",
] as const;

export const energyReactiveUnits = [
  "VARh",
  "kVARh",
  "MVARh",
  "GVARh",
  "TVARh",
] as const;

export const energyApparentUnits = [
  "VAh",
  "kVAh",
  "MVAh",
  "GVAh",
  "TVAh",
] as const;

export const powerApparentUnits = ["VA", "kVA", "MVA", "GVA", "TVA"] as const;

export const energyPerPersonUnits = [
  "Wh per person",
  "kWh per person",
  "MWh per person",
  "GWh per person",
  "TWh per person",
] as const;
